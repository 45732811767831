import { useCallback } from "react";

import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { formatDate, fulfillmentMap, getColorStyle } from "../../utils";

import type { User } from "../../types";

type Props = {
  orders: User["orders"];
};

export function OrderHistory({ orders }: Props) {
  return (
    <VStack style={{ textAlign: "left", paddingBottom: "0.5rem", gap: "1rem" }}>
      <LatoText
        text="Order history"
        color="black"
        weight="medium"
        size="normalMedium"
        customStyle={{ textAlign: "left" }}
      />
      {!orders || orders.length === 0 ? (
        <LatoText
          text="You haven’t placed any order yet."
          color="black"
          weight="normal"
          size="normal"
          customStyle={{ textAlign: "left" }}
        />
      ) : (
        <Orders orders={orders} />
      )}
    </VStack>
  );
}

function Orders({ orders }: Props) {
  const { isMobile, isPad } = useIsMobile();

  return (
    <VStack
      style={{
        width: "100%",
        borderBottom:
          isMobile || isPad
            ? "unset"
            : `1px dashed ${getColorStyle("activeBorder")}`,
        gap: isMobile || isPad ? "2rem" : "0",
      }}
    >
      {!(isMobile || isPad) && <OrderHistoryHeader />}
      {orders.map(
        ({
          name,
          statusUrl,
          processedAt,
          financialStatus,
          fulfillmentStatus,
          totalPrice,
        }) =>
          isMobile || isPad ? (
            <VStack key={name} style={{ width: "100%", gap: "0.5rem" }}>
              <HStack
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <LatoText
                  text={`Order ${name}`}
                  color="blue"
                  weight="medium"
                  size="normal"
                  onClick={() => window.open(statusUrl, "_blank")}
                />
                <LatoText
                  text={formatDate(processedAt)}
                  color="black"
                  weight="normal"
                  size="normalSmall"
                />
              </HStack>
              <VStack
                style={{
                  width: "100%",
                  borderRadius: "4px",
                  border: `1px solid ${getColorStyle("border")}`,
                  overflow: "hidden",
                }}
              >
                <OrderHistoryHeader />
                <HStack style={{ width: "100%" }}>
                  <ItemText text={financialStatus} />
                  <ItemText text={fulfillmentMap(fulfillmentStatus)} />
                  <ItemText text={`AED ${totalPrice}`} textAlign="right" />
                </HStack>
              </VStack>
            </VStack>
          ) : (
            <HStack style={{ width: "100%" }} key={name}>
              <ItemText text={name} link={statusUrl} />
              <ItemText text={formatDate(processedAt)} />
              <ItemText text={financialStatus} />
              <ItemText text={fulfillmentMap(fulfillmentStatus)} />
              <ItemText text={`AED ${totalPrice}`} textAlign="right" />
            </HStack>
          )
      )}
    </VStack>
  );
}

function OrderHistoryHeader() {
  const { isMobile, isPad } = useIsMobile();

  return (
    <HStack
      style={{ width: "100%", background: getColorStyle("activeBorder") }}
    >
      {!isMobile ||
        (isPad && (
          <>
            <ItemText text="ORDER" />
            <ItemText text="DATE" />
          </>
        ))}
      <ItemText
        text={isMobile || isPad ? "Payment Status" : "PAYMENT STATUS"}
      />
      <ItemText
        text={isMobile || isPad ? "Fulfillment Status" : "FULFILLMENT STATUS"}
      />
      <ItemText text="TOTAL" textAlign="right" />
    </HStack>
  );
}

function ItemText({
  text,
  textAlign = "left",
  link,
}: {
  text: string;
  textAlign?: "left" | "right";
  link?: string;
}) {
  const { isMobile, isPad } = useIsMobile();

  const handleOnClick = useCallback(() => {
    if (link) {
      window.open(link, "_blank");
    }
  }, [link]);

  return (
    <LatoText
      text={text}
      color={link ? "blue" : "black"}
      weight="normal"
      size={isMobile || isPad ? "small" : "normalSmall"}
      customStyle={{
        width: isMobile || isPad ? "calc(100% / 3)" : "20%",
        textAlign,
        padding: isMobile || isPad ? "0.5rem" : "0.5rem 1rem",
        cursor: link ? "pointer" : "unset",
        textDecorationLine: link ? "underline" : "unset",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      onClick={handleOnClick}
    />
  );
}
