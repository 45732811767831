import { useCallback } from "react";

import { getColorStyle } from "../utils";

import { PaytoneOneText } from "./Text";

import type { ColorType, FontSizeType, FontWeightType } from "../types";
import type { MouseEvent, CSSProperties } from "react";

type Props = {
  name: string;
  onClick: (event: MouseEvent<HTMLDivElement>) => void;
  isDisabled: boolean;
  isCustomDisabled?: boolean;
  fontSize?: FontSizeType;
  fontWeight?: FontWeightType;
  color?: ColorType;
  backgroundColor?: ColorType;
  customStyle?: CSSProperties;
};

const style: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "0.75rem",
    textAlign: "center",
    borderRadius: "2rem",
    boxSizing: "border-box",
  },
};

export function Button({
  name,
  onClick,
  isDisabled,
  isCustomDisabled,
  fontSize,
  fontWeight,
  color,
  backgroundColor,
  customStyle,
}: Props) {
  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (isDisabled) return;
      onClick(event);
    },
    [isDisabled, onClick]
  );

  return (
    <div
      style={{
        ...style.wrapper,
        ...customStyle,
        cursor: isDisabled || isCustomDisabled ? "" : "pointer",
        background: isCustomDisabled
          ? getColorStyle(backgroundColor || "yellow")
          : isDisabled
          ? getColorStyle("disabled")
          : getColorStyle(backgroundColor || "yellow"),
      }}
      onClick={handleOnClick}
    >
      <PaytoneOneText
        text={name}
        color={isDisabled ? "white" : color || "black"}
        weight={fontWeight || "normal"}
        size={fontSize || "normal"}
      />
    </div>
  );
}
