import { useCallback, useState } from "react";

import { Button } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";

import type { CSSProperties } from "react";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "0 1rem 3rem 1rem",
    margin: "auto",
    gap: "3rem",
  },
  row: {
    gap: "3rem",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "3rem 1rem",
    margin: "auto",
  },
  row: {},
};

type Props = {
  images: string[];
};

export function ImagesBanner({ images }: Props) {
  const { isMobile, isPad } = useIsMobile();
  const [show, setShow] = useState<boolean>(false);

  const handleOnClick = useCallback(() => {
    setShow(true);
  }, []);

  return (
    <VStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
    >
      <HStack style={isMobile || isPad ? mobileStyle.row : desktopStyle.row}>
        <HStack
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridRowGap: "inherit",
          }}
        >
          {images.slice(1, 7).map((image) => (
            <HStack style={{ flex: "0.4" }}>
              <img src={image} alt="" style={{ width: "100%" }} />
            </HStack>
          ))}
        </HStack>
      </HStack>
      {show ? (
        <HStack
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridRowGap: "inherit",
          }}
        >
          {images.slice(7).map((image) => (
            <HStack style={{ flex: "0.6" }}>
              <img src={image} alt="" style={{ width: "100%" }} />
            </HStack>
          ))}
        </HStack>
      ) : images.length > 7 ? (
        <HStack style={{ width: "12.5rem", margin: "auto" }}>
          <Button
            name="show more"
            onClick={handleOnClick}
            isDisabled={false}
            color="white"
            backgroundColor="blue"
          />
        </HStack>
      ) : null}
    </VStack>
  );
}
