import { SEO } from "./SEO";

export function HomeSEO() {
  return (
    <SEO
      title="Comfortable Shoes with Style for Your Kids - Lucky Kids"
      description="Lucky Shoes brings comfortable, affordable and fashionable shoes to your children. You are in luck with Lucky Kids. Check our new arrivals!"
    />
  );
}
