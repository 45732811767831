import { createContext } from "react";

export type SingleBlogContextType = {
  title: string;
  publishedDate: string;
  shortDescription: string;
  src: string;
  id: string;
  slug: string;
};

export const BlogsContext = createContext<SingleBlogContextType[] | null>(null);

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const BlogContext = createContext<any>(null);
