import type {
  ProductDetailType,
  TagType,
  ProductTypeType,
  AgeGroup,
  ProductType,
} from "../types";

const COLOR_TAG_ENUM: TagType[] = ["black", "red", "yellow", "green"];

const AGE_GROUP: AgeGroup[] = ["babies", "toddlers", "kids", "teens"];
const PRODUCT_TYPE: ProductTypeType[] = [
  "boots",
  "sportsShoes",
  "sandals",
  "slippers",
  "sneakers",
  "basketballShoes",
  "schoolShoes",
  "others",
];

export function getColorTag(tags: ProductDetailType["tags"]) {
  return tags.filter((tag) => COLOR_TAG_ENUM.includes(tag));
}

export function getAgeGroup(tags: ProductDetailType["tags"]) {
  return tags.find((tag) => AGE_GROUP.includes(tag as AgeGroup)) as AgeGroup;
}

export function getProductType(tags: ProductDetailType["tags"]) {
  return tags.find((tag) =>
    PRODUCT_TYPE.includes(tag as ProductTypeType)
  ) as ProductTypeType;
}

export function getLuckyChoiceTag(
  tag: string,
  luckyChoiceBabies: ProductType | undefined,
  luckyChoiceToddlers: ProductType | undefined,
  luckyChoiceKids: ProductType | undefined,
  luckyChoiceTeens: ProductType | undefined
) {
  switch (tag) {
    case "babies":
      return luckyChoiceBabies;
    case "toddlers":
      return luckyChoiceToddlers;
    case "kids":
      return luckyChoiceKids;
    case "teens":
      return luckyChoiceTeens;
  }
}
