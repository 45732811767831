import Cookies from "js-cookie";
import LogRocket from "logrocket";
import { useContext, useState, useMemo, useEffect } from "react";

import { signInBySession } from "../api/session";
import { UserContext } from "../context";

import type { User } from "../types";
import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function UserProvider({ children }: Props) {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const session = Cookies.get("session");
    if (!session) return;
    void signInBySession({ session, setUser });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      LogRocket.identify(user.id, {
        name: user.displayName,
        email: user.email,
      });
    }
  }, [user]);

  const context = useMemo(() => ({ user, setUser }), [user, setUser]);
  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) throw new Error("UserContext is null");
  return context;
}
