import { useMemo } from "react";

import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { useCartContext } from "../../providers";
import { getColorStyle } from "../../utils";

type Props = {
  onClick: () => void;
};

export function CartCounter({ onClick }: Props) {
  const { isMobile, isPad } = useIsMobile();
  const { lines } = useCartContext();

  const number = useMemo(() => lines.length, [lines]);

  if (!number) return null;

  return (
    <LatoText
      text={number + ""}
      color="white"
      weight="bold"
      size="normalSmall"
      onClick={onClick}
      customStyle={{
        background: getColorStyle("red"),
        borderRadius: "50%",
        position: "absolute",
        width: "1rem",
        height: "1rem",
        top: isMobile || isPad ? "3rem" : "0.5rem",
        right: isMobile || isPad ? "1rem" : "0.5rem",
        cursor: "pointer",
      }}
    />
  );
}
