import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { LatoText } from "../../components";
import { HEADER_HEIGHT } from "../../constants";
import { useConstantsContext } from "../../providers";
import { getColorStyle } from "../../utils";
import { HStack } from "../HStack";

import type { ColorType } from "../../types";

import { VStack } from "../VStack";

import type { CSSProperties } from "react";

type Props = {
  ageGroup: string;
  onClick: () => void;
};

const style: Record<string, CSSProperties> = {
  wrapper: {
    position: "absolute",
    top: HEADER_HEIGHT.desktop,
    left: 0,
    width: "100vw",
    zIndex: 2,
    background: getColorStyle("eggShell"),
  },
  innerWrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "1rem 2rem",
    justifyContent: "space-between",
    margin: "0 auto",
  },
};
export function ToolTip({ ageGroup, onClick }: Props) {
  const { trends, productType } = useConstantsContext();

  return (
    <HStack style={style.wrapper} key={ageGroup}>
      <HStack style={style.innerWrapper}>
        <VStack>
          <LatoText
            text="Sale & Trending"
            color="placeholder"
            weight="bold"
            size="medium"
            customStyle={{ textAlign: "left", marginBottom: "1.5rem" }}
          />
          {Object.keys(trends).map((key) => {
            const text = trends[key];
            const color =
              text === "New Arrivals"
                ? "blue"
                : text === "Sale"
                ? "red"
                : "black";
            return (
              <Item
                key={key}
                text={trends[key]}
                color={color}
                ageGroup={ageGroup}
                onClick={onClick}
              />
            );
          })}
        </VStack>
        <VStack>
          <LatoText
            text="Types"
            color="placeholder"
            weight="bold"
            size="medium"
            customStyle={{ textAlign: "left", marginBottom: "1.5rem" }}
          />
          <HStack style={{ flexWrap: "wrap" }}>
            {Object.keys(productType).map((key) => (
              <Item
                key={key}
                text={productType[key]}
                flex="50%"
                ageGroup={ageGroup}
                onClick={onClick}
              />
            ))}
          </HStack>
        </VStack>
        {ageGroup === "Babies" && (
          <img src="/images/home_page/header-tooltip-hero-babies.svg" alt="" />
        )}
        {ageGroup === "Toddlers" && (
          <img
            src="/images/home_page/header-tooltip-hero-toddlers.svg"
            alt=""
          />
        )}
        {ageGroup === "Teens" && (
          <img src="/images/home_page/header-tooltip-hero-teens.svg" alt="" />
        )}
        {ageGroup === "Kids" && (
          <img src="/images/home_page/header-tooltip-hero-kids.svg" alt="" />
        )}
      </HStack>
    </HStack>
  );
}

type ItemProps = {
  text: string;
  ageGroup: string;
  onClick: () => void;
  color?: ColorType;
  flex?: string;
};
function Item({ text, ageGroup, color = "black", flex, onClick }: ItemProps) {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => {
    navigate(
      `/shoes/${ageGroup.toLowerCase()}${
        text === "All" ? "" : "/" + text.toLowerCase()
      }`
    );
    onClick();
  }, [ageGroup, navigate, onClick, text]);

  return (
    <LatoText
      text={text}
      color={color}
      weight="bold"
      size="normal"
      customStyle={{
        textAlign: "left",
        marginBottom: "1rem",
        cursor: "pointer",
        flex,
      }}
      onClick={handleOnClick}
    />
  );
}
