import { useCallback } from "react";

import { useIsMobile } from "../hooks";
import { VStack } from "../layouts/VStack";
import { useModalContext } from "../providers/ModalProvider";
import { getColorStyle } from "../utils";

import type { ColorType } from "../types";
import type { CSSProperties, ReactNode } from "react";

const mobileStyle: Record<string, CSSProperties> = {
  mainFrame: {
    width: "90vw",
    position: "fixed",
    top: "3rem",
    marginLeft: "5%",
    padding: "1rem 1rem 2rem 1rem ",
    gap: "1rem",
    background: getColorStyle("cream"),
  },
  closeIcon: {
    position: "absolute",
    width: "20px",
    right: "1rem",
    top: "0.5rem",
    cursor: "pointer",
  },
};

const desktopStyle: Record<string, CSSProperties> = {
  mainFrame: {
    position: "fixed",
    margin: "auto",
    left: "0",
    right: "0",
    padding: "1rem 2rem 2rem 2rem",
    gap: "1rem",
    background: getColorStyle("cream"),
  },
  closeIcon: {
    position: "absolute",
    width: "20px",
    top: "0.5rem",
    cursor: "pointer",
  },
};

type Props = {
  children: ReactNode;
  desktopWidth?: string;
  desktopTop?: string;
  background?: ColorType;
  customStyle?: CSSProperties;
  isFullSize?: boolean;
};

export function Modal({
  children,
  desktopWidth,
  desktopTop,
  background,
  customStyle,
  isFullSize,
}: Props) {
  const { isMobile, isPad } = useIsMobile();
  const { modalIsOpen, setModalIsOpen, setPopuped } = useModalContext();

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setPopuped(true);
  }, [setModalIsOpen, setPopuped]);

  return (
    <>
      {modalIsOpen && (
        <VStack
          style={{
            ...customStyle,
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: isMobile || isPad ? "2.25rem" : "3.5rem",
            background: background
              ? getColorStyle(background)
              : "rgba(0,0,0,0.5)",
            zIndex: "999",
          }}
        >
          <VStack
            style={
              isMobile || isPad
                ? {
                    ...mobileStyle.mainFrame,
                    padding: isFullSize ? "0" : mobileStyle.mainFrame.padding,
                  }
                : {
                    ...desktopStyle.mainFrame,
                    padding: isFullSize ? "0" : desktopStyle.mainFrame.padding,

                    width: desktopWidth || "40rem",
                    top: desktopTop || "30%",
                  }
            }
          >
            <div
              onClick={closeModal}
              style={
                isMobile
                  ? mobileStyle.closeIcon
                  : {
                      right: "1rem",
                      ...desktopStyle.closeIcon,
                    }
              }
            >
              <img src="/icons/home_page/close-icon.svg" />
            </div>
            {children}
          </VStack>
        </VStack>
      )}
    </>
  );
}
