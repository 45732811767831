import { useContext, useState } from "react";

import { CartToggleContext } from "../context";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};
export function CartToggleProvider({ children }: Props) {
  const [toggleCart, setToggleCart] = useState<boolean>(false);

  return (
    <CartToggleContext.Provider value={{ toggleCart, setToggleCart }}>
      {children}
    </CartToggleContext.Provider>
  );
}

export function useCartToggleContext() {
  const context = useContext(CartToggleContext);

  if (!context) throw new Error("CartToggleContext is null");
  return context;
}
