import Cookies from "js-cookie";
import LogRocket from "logrocket";
import { useCallback, useMemo, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import { signIn } from "../../api/session";
import ErrorSign from "../../assets/icons/common/error-sign.svg";
import OkSign from "../../assets/icons/common/ok-sign.svg";
import { TextInput, Button, PaytoneOneText, LatoText } from "../../components";

import type { ChangeEvent, CSSProperties } from "react";

import { useIsLoggedIn, useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { useLoadingContext } from "../../providers";
import { useUserContext } from "../../providers";
import { validateEmail, validatePassword } from "../../utils";

type FormState = {
  email: {
    value: string;
    error: string | undefined;
  };
  password: {
    value: string;
    error: string | undefined;
  };
};

const validator: Record<
  keyof FormState,
  (value: string) => string | undefined
> = {
  email: validateEmail,
  password: validatePassword,
};

const loginLargeFrameStyle: CSSProperties = {
  width: "100%",
  padding: "5rem 0rem 0rem",
};

const loginLargeFrameMobileStyle: CSSProperties = {
  width: "100%",
  padding: "0rem 0rem 2rem",
};

const loginFrameStyle: CSSProperties = {
  margin: "auto",
  padding: "2rem",
  width: "35rem",
  gap: "4rem",
};

const loginFrameMobileStyle: CSSProperties = {
  margin: "auto",
  padding: "3rem 1rem",
  width: "100%",
  gap: "2rem",
};

const loginMainFrameStyle: CSSProperties = {
  gap: "1rem",
};

export function Login() {
  const { setUser } = useUserContext();
  const { reset } = useParams();
  const navigate = useNavigate();
  const { isMobile, isPad } = useIsMobile();
  const { setLoading } = useLoadingContext();
  const isLoggedIn = useIsLoggedIn();

  const [form, setForm] = useState<FormState>({
    email: {
      value: "",
      error: validator.email(""),
    },
    password: {
      value: "",
      error: validator.password(""),
    },
  });

  const isDisabled = useMemo(
    () => Boolean(form.email.error || form.password.error),
    [form.email.error, form.password.error]
  );

  const handleInputOnChange = useCallback(
    (key: keyof FormState) =>
      function (e: ChangeEvent<HTMLInputElement>) {
        const errorMessage = validator[key](e.target.value);
        setForm({
          ...form,
          [key]: {
            value: e.target.value,
            error: errorMessage,
          },
        });
      },
    [form]
  );

  const handleButtonClick = useCallback(async () => {
    setLoading(true);
    const response = await signIn({
      email: form.email.value,
      password: form.password.value,
    });
    if (typeof response === "string") {
      // FIXME: handle error
      navigate("/login/fail");
    } else {
      const { token, ...user } = response;

      LogRocket.identify(user.id, {
        name: user.displayName,
        email: user.email,
      });

      setUser(user);
      // desc: set cookie expiration date to 30 days
      Cookies.set("session", token, { expires: 30 });
      navigate("/");
    }
    setLoading(false);
  }, [form.email.value, form.password.value, navigate, setLoading, setUser]);

  if (isLoggedIn) navigate("/");

  return (
    <VStack
      style={
        isMobile || isPad ? loginLargeFrameMobileStyle : loginLargeFrameStyle
      }
    >
      <VStack
        style={isMobile || isPad ? loginFrameMobileStyle : loginFrameStyle}
      >
        <PaytoneOneText
          text="Login"
          color="black"
          weight="normal"
          size={isMobile || isPad ? "medium" : "large"}
        />
        <VStack style={loginMainFrameStyle}>
          {reset === "reset" && (
            <HStack
              style={{
                gap: "0.5rem",
                justifyContent: "center",
              }}
            >
              <img src={OkSign} alt="" />
              <LatoText
                text={
                  "We've sent you an email with a link to update your password."
                }
                color="black"
                weight="normal"
                size="normal"
                customStyle={{ textAlign: "left" }}
              />
            </HStack>
          )}
          {reset === "fail" && (
            <HStack
              style={{
                gap: "0.5rem",
                justifyContent: "center",
              }}
            >
              <img src={ErrorSign} alt="" />
              <LatoText
                text="Wrong email or password."
                color="black"
                weight="normal"
                size="normal"
                customStyle={{ textAlign: "left" }}
              />
            </HStack>
          )}

          <TextInput
            type="text"
            label="EMAIL"
            placeholder="johndoe@gmail.com"
            isRequired={true}
            value={form.email.value}
            errorMessage={form.email.error}
            onChange={handleInputOnChange("email")}
          />
          <VStack>
            <TextInput
              type="password"
              label="PASSWORD"
              placeholder="At least 1 letter and 1 number, no symbols, 8-16 length"
              isRequired={true}
              value={form.password.value}
              errorMessage={form.password.error}
              onChange={handleInputOnChange("password")}
            />
            <Link to="/reset">
              <LatoText
                text="Forgot your password?"
                color="blue"
                weight="medium"
                size="small"
                customStyle={{ textAlign: "left", cursor: "pointer" }}
              />
            </Link>
          </VStack>
        </VStack>
        <VStack style={{ gap: "1rem", alignItems: "center" }}>
          <Button
            name="sign in"
            onClick={handleButtonClick}
            isDisabled={isDisabled}
          />
          <Link to="/signup">
            <HStack style={{ cursor: "pointer" }}>
              <LatoText
                text={"create an account"}
                color={"black"}
                weight={"bold"}
                size={"normalMedium"}
              />
            </HStack>
          </Link>
        </VStack>
      </VStack>
    </VStack>
  );
}
