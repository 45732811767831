import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { Drawer, VStack } from "../../layouts";

export function ReturnExchange() {
  const { isMobile, isPad } = useIsMobile();

  return (
    <Drawer
      headerText="Return & Exchange"
      headerSize={isMobile || isPad ? "normal" : "normalMedium"}
      headerWeight={"bold"}
      maxHeight="800px"
      isBottomLine={true}
    >
      <VStack style={{ padding: "0 0.5rem 1rem", gap: "1rem" }}>
        <VStack style={{ gap: "0.5rem" }}>
          <LatoText
            text="Return policy:"
            color="black"
            weight="medium"
            size={isMobile || isPad ? "small" : "normal"}
            customStyle={{ textAlign: "left" }}
          />
          <LatoText
            text="Return is valid for 7 days with shoes and shoe box intact (not worn, not broken).
All products must be returned unworn and unused, with all related accessories (designer tags, shoe laces and brand merchandise, etc. if any), included within the original box and packaging and in a condition enabling us to sell the product as new. The product will be taken back with a Restocking Fee of a minimum of either 15 AED or 15% of the order pricing, with the higher amount deducted from the refund as applicable while making return or exchange."
            color="black"
            weight="normal"
            size={isMobile || isPad ? "small" : "normal"}
            customStyle={{
              textAlign: "left",
              lineHeight: isMobile || isPad ? "unset" : "25.6px",
            }}
          />
        </VStack>
        <VStack style={{ gap: "0.5rem" }}>
          <LatoText
            text="Exchange policy:"
            color="black"
            weight="medium"
            size={isMobile || isPad ? "small" : "normal"}
            customStyle={{ textAlign: "left" }}
          />
          <LatoText
            text="Exchange is valid for 15 days but can only be done in our stores that are located in Abu Dhabi & Dubai. Please send an email to us with all the details of the exchange and we will prepare the product in the store for you and notify you when the product is ready to be exchanged. When in the store, the customer has to present the email from us with exchange details, the online receipt of the purchase and valid photographic identification of any of the followings: Driver’s license, Original passport or your Emirates ID Card."
            color="black"
            weight="normal"
            size={isMobile || isPad ? "small" : "normal"}
            customStyle={{
              textAlign: "left",
              lineHeight: isMobile || isPad ? "unset" : "25.6px",
            }}
          />
        </VStack>
      </VStack>
    </Drawer>
  );
}
