import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as BestSellersBannerMobile } from "../../assets/images/product/best-sellers-banner-mobile.svg";
import { ReactComponent as LuckyChoice1 } from "../../assets/images/product/lucky-choice-background-1.svg";
import { ReactComponent as LuckyChoice2 } from "../../assets/images/product/lucky-choice-background-2.svg";
import { ReactComponent as NewArrivalsBannerMobile } from "../../assets/images/product/new-arrivals-banner-mobile.svg";
import { ReactComponent as SaleBannerMobile } from "../../assets/images/product/sales-banner-mobile-eid.svg";
import { LatoText } from "../../components";

import type { CSSProperties } from "react";

import { useIsMobile } from "../../hooks";
import { HStack, VStack, TagGroup, Price } from "../../layouts";
import { useFilterContext } from "../../providers";
import { getColorStyle, getLuckyChoiceTag, getProductId } from "../../utils";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    background: getColorStyle("yellow"),
    padding: "3rem 1rem",
  },
  innerWrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "0 2rem",
    justifyContent: "space-between",
    margin: "auto",
  },
  img: {
    width: "32%",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    background: getColorStyle("yellow"),
  },
  innerWrapper: {
    padding: "1rem 1.5rem",
    justifyContent: "center",
    gap: "1rem",
    alignItems: "center",
  },
  img: {
    width: "50%",
  },
};

type Props = {
  tag: string;
};

export function Banner({ tag }: Props) {
  const { isMobile, isPad } = useIsMobile();
  const isNewArrivals = useMemo(() => tag === "new-arrivals", [tag]);
  const isSale = useMemo(() => tag === "sale", [tag]);
  const isBestSellers = useMemo(() => tag === "best-sellers", [tag]);

  if (isNewArrivals) {
    return (
      <HStack style={{ width: "100%", margin: "auto" }}>
        {isMobile || isPad ? (
          <NewArrivalsBannerMobile />
        ) : (
          <img
            src="/images/products/new-arrivals-banner.png"
            alt=""
            style={{ width: "100%" }}
          />
        )}
      </HStack>
    );
  }

  if (isSale) {
    return (
      <HStack style={{ width: "100%" }}>
        {isMobile || isPad ? (
          <SaleBannerMobile />
        ) : (
          <img
            src="/images/products/sales-banner.png"
            alt=""
            style={{ width: "100%" }}
          />
        )}
      </HStack>
    );
  }

  if (isBestSellers) {
    return (
      <HStack style={{ width: "100%", margin: "auto" }}>
        {isMobile || isPad ? (
          <BestSellersBannerMobile />
        ) : (
          <img
            src="/images/products/best-sellers-banner.png"
            alt=""
            style={{ width: "100%" }}
          />
        )}
      </HStack>
    );
  }

  return (
    <VStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
    >
      <HStack
        style={
          isMobile || isPad
            ? mobileStyle.innerWrapper
            : desktopStyle.innerWrapper
        }
      >
        <img
          src={
            isMobile || isPad
              ? `/images/products/banner-description-${tag}-mobile.svg`
              : `/images/products/banner-description-${tag}.svg`
          }
          alt=""
          style={isMobile || isPad ? mobileStyle.img : desktopStyle.img}
        />
        <LuckyChoice tag={tag} />
      </HStack>
    </VStack>
  );
}

function LuckyChoice({ tag }: Props) {
  const {
    luckyChoiceBabies,
    luckyChoiceToddlers,
    luckyChoiceKids,
    luckyChoiceTeens,
  } = useFilterContext();
  const { isMobile, isPad } = useIsMobile();
  const luckyChoice = getLuckyChoiceTag(
    tag,
    luckyChoiceBabies,
    luckyChoiceToddlers,
    luckyChoiceKids,
    luckyChoiceTeens
  );
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    if (luckyChoice) {
      navigate(
        `/product/${luckyChoice.title.replaceAll(" ", "-")}/${getProductId(
          luckyChoice.id
        )}`
      );
    }
  }, [navigate, luckyChoice]);

  if (!luckyChoice) return null;

  return (
    <VStack
      style={{
        width: isMobile || isPad ? "100%" : "40%",
        padding: isMobile || isPad ? "1rem" : "1rem 3rem",
        background: getColorStyle("white"),
        borderRadius: "1rem",
        gap: isMobile || isPad ? "1.5rem" : "2rem",
        cursor: "pointer",
      }}
      onClick={handleOnClick}
    >
      <VStack style={{ width: "100%", height: "50%", position: "relative" }}>
        <LuckyChoice1
          style={{
            position: "absolute",
            top: "30%",
            right: isMobile || isPad ? "0" : "20%",
            height: "80%",
          }}
        />
        <LuckyChoice2
          style={{
            position: "absolute",
            top: "20%",
            left: "5%",
            height: "100%",
          }}
        />
        <TagGroup tags={luckyChoice.tags} />
        <HStack style={{ height: "100%", justifyContent: "center" }}>
          <img
            src={luckyChoice.image}
            alt=""
            style={{ height: "8rem", cursor: "pointer" }}
          />
        </HStack>
      </VStack>
      <VStack
        style={{
          width: "100%",
          height: "45%",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        <LatoText
          text={luckyChoice.title}
          color="black"
          weight={isMobile || isPad ? "medium" : "bold"}
          size={isMobile || isPad ? "normal" : "normalMedium"}
          customStyle={{ cursor: "pointer", textAlign: "center" }}
        />
        <Price
          currentPrice={luckyChoice.price}
          compareAtPrice={luckyChoice.compareAtPrice}
          currencyCode="AED"
          priceSize={isMobile || isPad ? "normalSmall" : "normalMedium"}
          currentPriceWeight="medium"
          compareAtPriceWeight="medium"
          alignCenter={true}
        />
      </VStack>
    </VStack>
  );
}
