import { useIsMobile } from "../hooks";
import { getColorStyle } from "../utils";

import type { TagType } from "../types";
import type { CSSProperties } from "react";

const desktopCommonStyle: CSSProperties = {
  fontFamily: "Paytone One",
  fontSize: "0.75rem",
  padding: "0.375rem 0.75rem",
  borderRadius: "1.5rem",
  display: "flex",
};

const mobileCommonStyle: CSSProperties = {
  fontFamily: "Paytone One",
  fontSize: "0.5rem",
  padding: "0.25rem 0.5rem",
  borderRadius: "1.5rem",
  display: "flex",
};

export function SaleTag() {
  const { isMobile } = useIsMobile();
  return (
    <div
      style={
        isMobile
          ? {
              ...mobileCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("red"),
            }
          : {
              ...desktopCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("red"),
            }
      }
    >
      SALE
    </div>
  );
}

export function LuckyChoiceTag() {
  const { isMobile } = useIsMobile();
  return (
    <div
      style={
        isMobile
          ? {
              ...mobileCommonStyle,
              color: getColorStyle("black"),
              background: getColorStyle("yellow"),
            }
          : {
              ...desktopCommonStyle,
              color: getColorStyle("black"),
              background: getColorStyle("yellow"),
            }
      }
    >
      LUCKY CHOICE
    </div>
  );
}

export function NewArrivals() {
  const { isMobile } = useIsMobile();
  return (
    <div
      style={
        isMobile
          ? {
              ...mobileCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("blue"),
            }
          : {
              ...desktopCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("blue"),
            }
      }
    >
      NEW ARRIVAL
    </div>
  );
}

export function KidsKingTag() {
  const { isMobile } = useIsMobile();
  return (
    <div
      style={
        isMobile
          ? {
              ...mobileCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("blue"),
            }
          : {
              ...desktopCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("blue"),
            }
      }
    >
      <img
        src="/icons/common/kids-king-icon.svg"
        style={
          isMobile
            ? {
                width: "0.34rem",
                margin: "auto 0.25rem auto 0",
              }
            : {
                width: "0.5rem",
                margin: "auto 0.25rem auto 0",
              }
        }
        alt=""
      />
      KIDS KING
    </div>
  );
}

export function ColorTag({ tag }: { tag: TagType }) {
  const { isMobile } = useIsMobile();
  return (
    <div
      style={
        isMobile
          ? {
              ...mobileCommonStyle,
              color: getColorStyle("black"),
              background: getColorStyle("yellow"),
            }
          : {
              ...desktopCommonStyle,
              color: getColorStyle("black"),
              background: getColorStyle("yellow"),
            }
      }
    >
      {tag}
    </div>
  );
}

export function DefaultTag() {
  const { isMobile } = useIsMobile();
  return (
    <div
      style={
        isMobile
          ? {
              ...mobileCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("white"),
            }
          : {
              ...desktopCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("white"),
            }
      }
    >
      Placeholder
    </div>
  );
}

export function VerifiedTag() {
  const { isMobile } = useIsMobile();
  return (
    <div
      style={
        isMobile
          ? {
              ...mobileCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("blue"),
              width: "7rem",
              justifyContent: "center",
            }
          : {
              ...desktopCommonStyle,
              color: getColorStyle("white"),
              background: getColorStyle("blue"),
              width: "7rem",
              justifyContent: "center",
            }
      }
    >
      verified purchase
    </div>
  );
}
