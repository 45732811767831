import { createContext } from "react";

export type MobileViewContextType = {
  isMobile: boolean;
  isPad: boolean;
};

export const MobileViewContext = createContext<MobileViewContextType | null>(
  null
);
