import { useEffect, useState } from "react";

import { getProducts } from "../../api";
import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, ProductCard, VStack } from "../../layouts";
import { getColorStyle } from "../../utils";

import type { AgeGroup, ProductTypeType } from "../../types";
import type { ProductType } from "../../types";
import type { CSSProperties, Dispatch, SetStateAction } from "react";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "3rem 1rem",
    margin: "auto",
    gap: "1.5rem",
    background: getColorStyle("eggShell"),
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "3rem 1.5rem",
    alignItems: "center",
    gap: "1.5rem",
    background: getColorStyle("eggShell"),
  },
};

type Props = {
  ageGroup: AgeGroup;
  productType: ProductTypeType;
  productsId: string[];
};

async function getSuggestProducts({
  ageGroup,
  productType,
  productsId,
  callback,
}: Props & { callback: Dispatch<SetStateAction<ProductType[]>> }) {
  const { products } = await getProducts(`${ageGroup} AND ${productType}`);

  const newProducts = products.filter(
    (product) => !productsId.includes(product.id)
  );

  callback(newProducts.slice(0, 4));
}

export function SuggestProducts({ ageGroup, productType, productsId }: Props) {
  const { isMobile, isPad } = useIsMobile();
  const [products, setProducts] = useState<ProductType[]>([]);

  useEffect(() => {
    void getSuggestProducts({
      ageGroup,
      productType,
      productsId,
      callback: setProducts,
    });
  }, [ageGroup, productType, productsId]);

  if (products.length === 0) return null;

  return (
    <VStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
    >
      <LatoText
        text="YOU MIGHT ALSO LIKE"
        color="black"
        weight="bold"
        size="large"
      />
      <HStack
        style={{
          width: "100%",
          margin: "auto",
          gap: isMobile || isPad ? "1rem" : "2rem",
          flexWrap: "wrap",
        }}
      >
        {products.map((product, index) => {
          if (isPad && index === products.length - 1) return null;
          return (
            <HStack
              style={{
                width: isMobile ? "40%" : isPad ? "30%" : "20%",
                flexGrow: 1,
              }}
              key={product.id}
            >
              <ProductCard
                productId={[product.id]}
                images={[product.image]}
                tags={[product.tags]}
                prices={[product.price]}
                compareAtPrices={[product.compareAtPrice]}
                title={product.title}
                key={product.id}
              />
            </HStack>
          );
        })}
      </HStack>
    </VStack>
  );
}
