// TODO: remove bespoke related constants
export const BESPOKE_COOKIE_NAME = "bespoke";
export const BESPOKE_TOKEN = "3e9eb3e3-78e6-42eb-a6b1-0cd1fae04318";
export const BESPOKE_URL = "/bespoke/5ae87ae8-c95e-48fe-9a18-f9c321c3440f";

export const AGE_GROUP_MENU_MAP: Record<string, string> = {
  babies: "Babies (0-1 year old)",
  toddlers: "Toddlers (1-4 year old)",
  kids: "Kids (5-12 year old)",
  teens: "Teens (13-18 year old)",
};

export const HEADER_HEIGHT = {
  desktop: "10.5rem",
  mobile: "6.25rem",
};
