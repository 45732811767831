import type { ColorTagType, SortItem, FilterType, SortReview } from "../types";

export const REVIEW_SORT: SortReview[] = [
  { text: "Most Helpful" },
  { text: "Most Recent" },
  { text: "Highest Rate" },
  { text: "Lowest Rate" },
];

export const SORT: SortItem[] = [
  { text: "Newest" },
  { text: "Price: Low to High" },
  { text: "Price: High to Low" },
];

export const FILTER: FilterType = {
  "SALE & TRENDING": {
    height: "5rem",
    options: [
      { text: "New Arrivals", tag: "newArrivals", color: "blue" },
      { text: "Sale", tag: "sale", color: "red" },
    ],
  },
  "AGE GROUP": {
    height: "9rem",
    options: [
      { text: "Babies", tag: "babies" },
      { text: "Toddlers", tag: "toddlers" },
      { text: "Kids", tag: "kids" },
      { text: "Teens", tag: "teens" },
    ],
  },
  TYPE: {
    height: "15.5rem",
    options: [
      { text: "Boots", tag: "boots" },
      { text: "Sports Shoes", tag: "sportsShoes" },
      { text: "Sandals", tag: "sandals" },
      { text: "Slippers", tag: "slippers" },
      { text: "Sneakers", tag: "sneakers" },
      { text: "Basketball Shoes", tag: "basketballShoes" },
      { text: "School Shoes", tag: "schoolShoes" },
    ],
  },
  GENDER: {
    height: "7rem",
    options: [
      { text: "Boys", tag: "boys" },
      { text: "Girls", tag: "girls" },
      { text: "Unisex", tag: "unisex" },
    ],
  },
  BRAND: {
    height: "5rem",
    options: [
      { text: "Lucky Kids", tag: "luckyKids" },
      { text: "Kids King", tag: "kidsKing" },
    ],
  },
  COLOR: {
    height: "12rem",
    options: [
      { text: "Black", tag: "black", color: "black" },
      { text: "Pink", tag: "pink", color: "pink" },
      { text: "Red", tag: "red", color: "red" },
      { text: "Brown", tag: "brown", color: "brown" },
      { text: "Grey", tag: "grey", color: "grey" },
      { text: "Yellow", tag: "yellow", color: "yellow" },
      { text: "Blue", tag: "blue", color: "blue" },
      { text: "Green", tag: "green", color: "green" },
      { text: "Gold", tag: "gold", color: "gold" },
      { text: "Beige", tag: "beige", color: "beige" },
      { text: "Purple", tag: "purple", color: "purple" },
      { text: "Silver", tag: "silver", color: "silver" },
      { text: "White", tag: "white", color: "white" },
    ],
  },
};

export const COLOR_IS_DARK: Record<ColorTagType, boolean> = {
  black: true,
  white: false,
  pink: false,
  red: true,
  brown: true,
  grey: true,
  yellow: false,
  blue: true,
  green: true,
  gold: true,
  beige: false,
  purple: true,
  silver: false,
};
