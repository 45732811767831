export function validateEmail(value: string) {
  const emailFormat =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!value.match(emailFormat)) {
    return "Email address is invalid";
  }
  return undefined;
}

export function validatePassword(value: string) {
  const passwordFormat = /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,16}$/;
  if (!value.match(passwordFormat)) {
    return "Password should have at lease 1 letter and 1 number, with no symbols, 8-16 length.";
  }
  return undefined;
}

export function validateFirstName(value: string) {
  return value ? undefined : "First name is invalid";
}

export function validateLastName(value: string) {
  return value ? undefined : "Last name is invalid";
}
