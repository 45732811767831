import * as React from "react";
import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useIsLoggedIn, useIsMobile } from "../../hooks";
import { useCartToggleContext } from "../../providers/CartToggleProvider";
import { AccountDropdown } from "../Account/AccountDropdown";
import { CartCounter } from "../Cart/CartCounter";
import { CartDropdown } from "../Cart/CartDropdown";

import type { CSSProperties } from "react";

import { HStack } from "../HStack";

type IconProps = {
  src: string;
  option: string;
  customStyle?: CSSProperties;
};

const desktopStyle: Record<string, CSSProperties> = {
  iconGroupWrapper: {
    margin: "1.75rem 0",
  },
  iconWrapper: {
    padding: "1rem",
    position: "relative",
  },
  icon: {
    width: "1.25rem",
    height: "1.25rem",
    cursor: "pointer",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  iconGroupWrapper: {
    margin: "0.375rem 0",
    gap: "0.5rem",
    justifyContent: "space-between",
  },
  iconWrapper: {
    padding: 0,
  },
};

export function IconGroup() {
  const { isMobile, isPad } = useIsMobile();

  return (
    <HStack
      style={
        isMobile || isPad
          ? mobileStyle.iconGroupWrapper
          : desktopStyle.iconGroupWrapper
      }
    >
      <Icon src="/icons/home_page/search-icon.svg" option="search" />
      <Icon src="/icons/home_page/cart-icon.svg" option="cart" />
      {!isMobile ||
        (isPad && (
          <Icon src="/icons/home_page/account-icon.svg" option="account" />
        ))}
    </HStack>
  );
}

function Icon({ src, option }: IconProps) {
  const { isMobile, isPad } = useIsMobile();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  const { toggleCart, setToggleCart } = useCartToggleContext();

  const handleCloseCartDropdown = useCallback(() => {
    setToggleCart(false);
  }, [setToggleCart]);
  const [toggleUser, setToggleUser] = useState(false);

  const handleClick = useCallback(() => {
    switch (option) {
      case "account":
        if (isLoggedIn) {
          navigate("/account");
        } else {
          navigate("/login");
        }
        break;
      case "search":
        navigate("/search");
        break;
      case "cart":
        if (pathname !== "/cart") {
          if (isMobile) {
            navigate("/cart");
          } else {
            setToggleCart(!toggleCart);
          }
        }
        break;
    }
  }, [
    option,
    isLoggedIn,
    pathname,
    navigate,
    isMobile,
    toggleCart,
    setToggleCart,
  ]);

  const handleMouseEnter = useCallback(() => {
    if (option === "account") {
      setToggleUser(true);
    }
  }, [option]);

  const handleMouseLeave = useCallback(() => {
    if (option === "account") {
      setToggleUser(false);
    }
  }, [option]);

  return (
    <HStack
      style={
        isMobile || isPad ? mobileStyle.iconWrapper : desktopStyle.iconWrapper
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={src} style={desktopStyle.icon} alt="" onClick={handleClick} />
      {option === "cart" && <CartCounter onClick={handleClick} />}
      {option === "cart" && toggleCart && !isMobile && (
        <CartDropdown handleClose={handleCloseCartDropdown} />
      )}
      {option === "account" && toggleUser && (
        <AccountDropdown setToggleUser={setToggleUser} />
      )}
    </HStack>
  );
}
