import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { updateCartDiscountCode } from "../../api";
import { ReactComponent as EmptyCartImg } from "../../assets/images/layouts/empty_cart_img.svg";
import { Button, LatoText, PaytoneOneText, TextInput } from "../../components";
import { useCheckout, useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";

import type { CSSProperties, ChangeEvent } from "react";

import {
  useCartContext,
  useCartIdContext,
  useLoadingContext,
} from "../../providers";

import { CartItem } from "./CartItem";

const desktopStyle: Record<string, CSSProperties> = {
  mainWrapper: {
    width: "100vw",
    padding: "3rem 1rem",
  },
  wrapper: {
    width: "100%",
    maxWidth: "1280px",
    margin: "auto",
    gap: "1.5rem",
  },
  headerWrapper: {
    width: "100%",
    justifyContent: "space-between",
    padding: "1.5rem 1rem",
  },
  itemWrapper: {
    gap: "1.5rem",
  },
  buttonWrapper: {
    justifyContent: "right",
  },
  buttonSubWrapper: {
    width: "23.75rem",
    gap: "1.5rem",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  mainWrapper: {
    width: "100%",
  },
  wrapper: {
    width: "100%",
    padding: "1.5rem 1rem 2rem 1rem",
    gap: "1.5rem",
  },
  itemWrapper: {
    gap: "1rem",
    maxHeight: "80vh",
    overflowY: "auto",
  },
  buttonSubWrapper: {
    width: "100%",
    gap: "1.5rem",
  },
};

export function Cart() {
  const { lines, checkoutChargeAmount, subtotalAmount, discountCodes } =
    useCartContext();
  const { setLoading } = useLoadingContext();
  const cartId = useCartIdContext();
  const { isMobile, isPad } = useIsMobile();
  const navigate = useNavigate();
  const checkout = useCheckout();
  const [code, setCode] = useState("");

  const handleKeepShopping = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  }, []);

  const handleApplyCode = useCallback(async () => {
    setLoading(true);
    await updateCartDiscountCode(cartId, [code]);
    window.location.reload();
    setLoading(false);
    setCode("");
  }, [cartId, code, setLoading]);

  return (
    <VStack
      style={
        isMobile || isPad ? mobileStyle.mainWrapper : desktopStyle.mainWrapper
      }
    >
      <VStack
        style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
      >
        <HStack
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <PaytoneOneText
            text="Shopping Cart"
            color="black"
            weight="normal"
            size={isMobile || isPad ? "medium" : "large"}
          />
          <LatoText
            text="continue shopping"
            color="blue"
            weight="bold"
            size={isMobile || isPad ? "normalSmall" : "normalMedium"}
            customStyle={{ cursor: "pointer" }}
            onClick={handleKeepShopping}
          />
        </HStack>
        {!(isMobile || isPad) && (
          <HStack
            style={
              isMobile || isPad
                ? mobileStyle.headerWrapper
                : desktopStyle.headerWrapper
            }
          >
            <LatoText
              text="PRODUCT"
              color="placeholder"
              weight="medium"
              size="normalSmall"
              customStyle={{ width: "36%", textAlign: "left" }}
            />
            <LatoText
              text="QUANTITY"
              color="placeholder"
              weight="medium"
              size="normalSmall"
              customStyle={{ width: "15%", textAlign: "right" }}
            />
            <LatoText
              text="TOTAL"
              color="placeholder"
              weight="medium"
              size="normalSmall"
              customStyle={{ width: "11.2%", textAlign: "right" }}
            />
          </HStack>
        )}
        <VStack
          style={
            isMobile || isPad
              ? mobileStyle.itemWrapper
              : desktopStyle.itemWrapper
          }
        >
          {lines.length === 0 ? (
            <HStack style={{ justifyContent: "center" }}>
              <EmptyCartImg />
            </HStack>
          ) : (
            lines.map((line) => <CartItem line={line} key={line.lineId} />)
          )}
        </VStack>
        <HStack
          style={
            isMobile || isPad
              ? mobileStyle.buttonWrapper
              : desktopStyle.buttonWrapper
          }
        >
          <VStack
            style={
              isMobile || isPad
                ? mobileStyle.buttonSubWrapper
                : desktopStyle.buttonSubWrapper
            }
          >
            <HStack
              style={{
                justifyContent: "space-between",
                paddingBottom: "1rem",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <TextInput
                type="text"
                placeholder="input promo code"
                isRequired={false}
                value={code}
                errorMessage=""
                onChange={handleOnChange}
              />
              <LatoText
                text="apply"
                color="blue"
                weight="bold"
                size="normalMedium"
                customStyle={{ cursor: "pointer" }}
                onClick={handleApplyCode}
              />
            </HStack>
            <VStack style={{ width: "100%", gap: "0.5rem" }}>
              {subtotalAmount !== checkoutChargeAmount && (
                <VStack
                  style={{
                    gap: "0.5rem",
                  }}
                >
                  {discountCodes &&
                    discountCodes["applicable"] &&
                    discountCodes.code && (
                      <HStack style={{ justifyContent: "space-between" }}>
                        <LatoText
                          text="Applied Code"
                          color="black"
                          weight="normal"
                          size="normal"
                        />
                        <LatoText
                          text={discountCodes.code}
                          color="black"
                          weight="normal"
                          size="normal"
                        />
                      </HStack>
                    )}

                  <HStack style={{ justifyContent: "space-between" }}>
                    <LatoText
                      text="Original price"
                      color="black"
                      weight="normal"
                      size="normal"
                    />
                    <LatoText
                      text={"AED " + checkoutChargeAmount}
                      color="black"
                      weight="normal"
                      size="normal"
                    />
                  </HStack>
                  <HStack style={{ justifyContent: "space-between" }}>
                    <LatoText
                      text="Saving"
                      color="red"
                      weight="normal"
                      size="normal"
                    />
                    <LatoText
                      text={
                        "AED " +
                        (Number(checkoutChargeAmount) - Number(subtotalAmount))
                          .toFixed(1)
                          .toString()
                      }
                      color="red"
                      weight="normal"
                      size="normal"
                    />
                  </HStack>
                </VStack>
              )}
              <HStack style={{ justifyContent: "space-between" }}>
                <LatoText
                  text="Total"
                  color="black"
                  weight="bold"
                  size={isMobile || isPad ? "normalMedium" : "mediumLarge"}
                />
                <LatoText
                  text={"AED " + subtotalAmount}
                  color="black"
                  weight="bold"
                  size={isMobile || isPad ? "normalMedium" : "mediumLarge"}
                />
              </HStack>
              <LatoText
                text="Taxes and shipping calculated at checkout"
                color="black"
                weight="normal"
                size="normal"
                customStyle={{
                  width: "100%",
                  textAlign: "right",
                }}
              />
            </VStack>
            <Button
              name="check out"
              onClick={checkout}
              isDisabled={lines.length === 0}
            />
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  );
}
