import { useMemo } from "react";
import TagManager from "react-gtm-module";

import { updateCartByEmail } from "../api";
import {
  useCartContext,
  useCartIdContext,
  useLoadingContext,
  useUserContext,
} from "../providers";

export function useCheckout() {
  const { checkoutUrl } = useCartContext();
  const cartId = useCartIdContext();
  const { setLoading } = useLoadingContext();
  const { user } = useUserContext();

  return useMemo(
    () =>
      async function () {
        if (!checkoutUrl) {
          throw new Error("Cart Error: missing checkout url");
        }
        setLoading(true);

        // GTM tracking
        TagManager.dataLayer({
          dataLayer: { event: "InitiateCheckout" },
        });

        // FB tracking
        fbq("track", "InitiateCheckout");

        if (user?.email) {
          await updateCartByEmail(user.email, cartId);
        }

        // FB tracking
        fbq("track", "InitiateCheckout");

        return window.location.assign(checkoutUrl);
      },
    [cartId, checkoutUrl, setLoading, user]
  );
}
