import { Helmet } from "react-helmet-async";

type Props = {
  title: string;
  description: string;
};

export function SEO({ title, description }: Props) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta
        name="facebook-domain-verification"
        content="nxg9gl4qbo55gpmv6f1zma9tdnqilr"
      />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
    </Helmet>
  );
}
