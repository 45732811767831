import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { PaytoneOneText } from "../../components";
import { useConstantsContext } from "../../providers";
import { HStack } from "../HStack";

import { ToolTip } from "./ToolTip";

import type { CSSProperties } from "react";

type LinkItemProps = {
  text: string;
  hasChild: boolean;
  onClick: () => void;
};

const style: Record<string, CSSProperties> = {
  linkItemWrapper: {
    padding: "1.6rem 2rem",
    alignItems: "center",
  },
  linkItemText: {
    cursor: "pointer",
  },
};

export function LinkGroup() {
  const { ageGroup } = useConstantsContext();
  const navigate = useNavigate();

  const handleAgeGroupClick = useCallback(
    (age: string) =>
      function () {
        navigate(`/shoes/${age}`);
      },
    [navigate]
  );

  const handleBlogClick = useCallback(
    () =>
      function () {
        navigate("/blogs");
      },
    [navigate]
  );

  return (
    <HStack>
      {Object.keys(ageGroup).map((age) => (
        <LinkItem
          text={ageGroup[age]}
          key={ageGroup[age]}
          onClick={handleAgeGroupClick(age)}
          hasChild
        />
      ))}
      <LinkItem
        text="Blog"
        key="blog"
        onClick={handleBlogClick}
        hasChild={false}
      />
    </HStack>
  );
}

function LinkItem({ text, hasChild }: LinkItemProps) {
  const [toggle, setToggle] = useState<boolean>(false);

  const handleMouseEnter = useCallback(() => {
    setToggle(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setToggle(false);
  }, []);

  return (
    <HStack
      style={style.linkItemWrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link to={text === "Blog" ? "/blogs" : `/shoes/${text.toLowerCase()}`}>
        <PaytoneOneText
          text={text}
          color={toggle ? "blue" : "black"}
          size="medium"
          weight="normal"
          customStyle={style.linkItemText}
          onClick={() => {
            setToggle(false);
          }}
        />
      </Link>
      {hasChild && toggle && (
        <ToolTip ageGroup={text} onClick={handleMouseLeave} />
      )}
    </HStack>
  );
}
