import { useIsMobile } from "../../hooks";
import { HStack } from "../../layouts";

// import { ReviewsFilter } from "./ReviewsFilter";
// import { ReviewsSorter } from "./ReviewsSorter";

export function Reviews() {
  const { isMobile } = useIsMobile();

  return (
    <HStack
      style={{
        flex: isMobile ? "1" : "0.25",
        padding: isMobile ? "1rem 0" : "1rem",
        gap: "3rem",
      }}
    >
      {/* <ReviewsFilter stars={5} />
      <ReviewsSorter /> */}
    </HStack>
  );
}
