import { createContext } from "react";

import type { Dispatch, SetStateAction } from "react";

export type CartToggleContextType = {
  toggleCart: boolean;
  setToggleCart: Dispatch<SetStateAction<boolean>>;
};

export const CartToggleContext = createContext<CartToggleContextType | null>(
  null
);
