import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Button, LatoText, PaytoneOneText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { useUserContext } from "../../providers";

import type { CSSProperties } from "react";

import { OrderHistory } from "./OrderHistory";

const accountLargeFrameStyle: CSSProperties = {
  width: "100%",
};

const accountFrameStyle: CSSProperties = {
  width: "100%",
  maxWidth: "1280px",
  padding: "5rem 2rem 2rem",
  margin: "auto",
  gap: "3rem",
};

const accountFrameMobileStyle: CSSProperties = {
  width: "100%",
  padding: "3rem 1rem 3rem 1rem",
  gap: "2rem",
};

const accountMainFrameStyle: CSSProperties = {
  gap: "2rem",
};

const accountDetailFrameStyle: CSSProperties = {
  width: "31rem",
  gap: "1rem",
};

const accountDetailFrameMobileStyle: CSSProperties = {
  gap: "1rem",
};

export function Account() {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { isMobile, isPad } = useIsMobile();

  const handleLogout = () => {
    navigate("/logout");
  };

  const handleEditInfo = () => {
    navigate("/edit");
  };

  if (!user) {
    return null;
  }

  return (
    <VStack style={accountLargeFrameStyle}>
      <VStack
        style={isMobile || isPad ? accountFrameMobileStyle : accountFrameStyle}
      >
        <HStack
          style={
            isMobile || isPad
              ? { alignContent: "center" }
              : { alignContent: "left" }
          }
        >
          <PaytoneOneText
            text="My Account"
            color="black"
            weight="normal"
            size="medium"
            customStyle={{ textAlign: "left" }}
          />
        </HStack>

        <VStack style={accountMainFrameStyle}>
          <OrderHistory orders={user.orders} />
          <VStack
            style={
              isMobile || isPad
                ? accountDetailFrameMobileStyle
                : accountDetailFrameStyle
            }
          >
            <LatoText
              text="Account Information"
              color="black"
              weight="medium"
              size="normalMedium"
              customStyle={{ textAlign: "left" }}
            />
            <LatoText
              text={user.displayName}
              color="black"
              weight="normal"
              size="normal"
              customStyle={{ textAlign: "left" }}
            />
            <LatoText
              text={user.email}
              color="black"
              weight="normal"
              size="normal"
              customStyle={{ textAlign: "left" }}
            />
            <HStack
              style={{
                marginTop: "2rem",
                gap: "1.5rem",
              }}
            >
              <Button
                name="edit information"
                onClick={handleEditInfo}
                isDisabled={false}
              />
              <Button
                name="log out"
                onClick={handleLogout}
                isDisabled={false}
                color="white"
                backgroundColor="blue"
              />
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}
