import { useState, useMemo, useCallback } from "react";

import { HStack, VStack } from "../layouts";
import { getColorStyle, getFontSizeStyle, getFontWeightStyle } from "../utils";

import { LatoText } from "./Text";

import "./common.css";

import type { ChangeEvent, CSSProperties, HTMLInputTypeAttribute } from "react";

type Props = {
  type: HTMLInputTypeAttribute;
  isRequired: boolean;
  value: string;
  errorMessage: string | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label?: string;
  customStyle?: CSSProperties;
};

const style: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
  },
  input: {
    width: "100%",
    padding: "0.5rem 1rem",
    fontFamily: "Lato",
    fontSize: getFontSizeStyle("normal"),
    fontWeight: getFontWeightStyle("normal"),
    borderRadius: "0.5rem",
  },
};

export function TextInput({
  type,
  label,
  onChange,
  value,
  isRequired,
  placeholder,
  errorMessage,
  onFocus,
  onBlur,
  customStyle,
}: Props) {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [showType, setShowType] = useState<string>(type);

  const textColor = useMemo(
    () => (errorMessage && isDirty && !isFocus ? "error" : "black"),
    [errorMessage, isDirty, isFocus]
  );

  const borderColor = useMemo(
    () =>
      isFocus
        ? getColorStyle("activeBorder")
        : errorMessage && isDirty
        ? getColorStyle("error")
        : getColorStyle("border"),
    [errorMessage, isDirty, isFocus]
  );

  const backgroundColor = useMemo(
    () => (isFocus ? getColorStyle("inputFilled") : getColorStyle("white")),
    [isFocus]
  );

  const handleOnFocus = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsFocus(true);
      if (!isDirty) {
        setIsDirty(true);
      }
      if (onFocus) {
        onFocus(event);
      }
    },
    [isDirty, onFocus]
  );

  const handleOnBlur = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsFocus(false);
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const handlePasswordVisible = useCallback(() => {
    if (showType === "password") setShowType("text");
    else setShowType("password");
  }, [showType]);

  return (
    <VStack style={style.wrapper}>
      {label && (
        <LatoText
          text={label + (isRequired ? "*" : "")}
          color={textColor}
          weight="medium"
          size="small"
          customStyle={{ textAlign: "left" }}
        />
      )}
      <VStack style={{ alignItems: "left", gap: "0.2rem" }}>
        <input
          type={showType}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          style={{
            ...style.input,
            ...customStyle,
            border: `1px solid ${borderColor}`,
            background: backgroundColor,
            color: getColorStyle(textColor),
          }}
          className={isDirty && errorMessage && !isFocus ? "error-input" : ""}
        />
        {(label === "PASSWORD" || label === "REPEAT PASSWORD") && (
          <HStack style={{ gap: "0.2rem", alignItems: "center" }}>
            <LatoText
              text="Show Password"
              color="black"
              weight="normal"
              size="small"
            />
            <input type="checkbox" onClick={handlePasswordVisible} />
          </HStack>
        )}
      </VStack>

      {isDirty && !isFocus && errorMessage && (
        <LatoText
          text={errorMessage}
          color="error"
          weight="normal"
          size="small"
          customStyle={{ textAlign: "left" }}
        />
      )}
    </VStack>
  );
}
