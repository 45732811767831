import { LatoText, PaytoneOneText } from "../../components";
import { Modal, VStack } from "../../layouts";

export function TermsAndConditions() {
  const normalStyle = {
    fontFamily: "Lato",
    fontSize: "0.75rem",
  };

  const boldStyle = {
    fontFamily: "Lato",
    fontSize: "0.75rem",
    fontWeight: "bold",
  };

  return (
    <Modal>
      <PaytoneOneText
        text="Lucky Kids"
        color="black"
        weight="medium"
        size="medium"
        customStyle={{ textAlign: "left" }}
      />
      <LatoText
        text="Offer details:"
        color="black"
        weight="medium"
        size="normal"
        customStyle={{ textAlign: "left" }}
      />
      <VStack>
        <div style={{ padding: "0", textAlign: "left" }}>
          <span style={normalStyle}>
            * Get AED 50 off for orders AED 250+, Get AED 150 off for orders AED
            500+, no code needed
          </span>
        </div>
        <div style={{ padding: "0", textAlign: "left" }}>
          <span style={normalStyle}>
            * Limited quantities, while supplies last.
          </span>
        </div>
        <div style={{ padding: "0", textAlign: "left" }}>
          <span style={normalStyle}>*&nbsp;</span>
          <span style={boldStyle}>Free Shipping&nbsp;</span>
          <span style={normalStyle}>
            for orders AED 150+ within contiguous UAE
          </span>
        </div>
        <div style={{ padding: "0", textAlign: "left" }}>
          <span style={normalStyle}>
            * AED 20 fees for Cash-on-delivery service
          </span>
        </div>
        <div style={{ padding: "0", textAlign: "left" }}>
          <span style={normalStyle}>* Offer valid</span>
          <span style={boldStyle}>&nbsp;6/20/2023 - 7/4/2023&nbsp;</span>
        </div>
      </VStack>
      <div
        style={{
          padding: "0",
          textAlign: "left",
          fontFamily: "Lato",
          fontSize: "0.75rem",
        }}
      >
        To redeem, please simply add product to shopping cart. If the order
        total reaches the discount threshold, the amount-off will be
        automatically applied while check out. Limited quantities, while
        supplies last. This offer cannot be combined with other offers. Offer is
        only valid online at luckykidsking.com, luckykidsking.com's Customer
        Service Department, and luckykidsking.com's Sales Department.
      </div>
      <div style={{ padding: "0", textAlign: "left" }}>
        <span style={normalStyle}>Please see</span>
        <span
          style={{
            fontFamily: "Lato",
            fontSize: "0.75rem",
            fontWeight: "bold",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => window.open("/policy", "_blank")}
        >
          &nbsp;luckykidsking.com/policy&nbsp;
        </span>
        <span style={normalStyle}>for additional Terms & Conditions.</span>
      </div>
    </Modal>
  );
}
