import { createContext } from "react";

export type ConstantsContextType = {
  ageGroup: Record<string, string>;
  productType: Record<string, string>;
  trends: Record<string, string>;
};

export const ConstantsContext = createContext<ConstantsContextType | null>(
  null
);
