import { createContext } from "react";

import type { Cart } from "../types";
import type { Dispatch, SetStateAction } from "react";

export type CartContextType = Cart;

export type CartSetterContextType = Dispatch<SetStateAction<Cart>>;

export type CartIdContextType = string;

export const CartContext = createContext<CartContextType | null>(null);
export const CartSetterContext = createContext<CartSetterContextType | null>(
  null
);
export const CartIdContext = createContext<CartIdContextType | null>(null);
