import { useCallback, useState } from "react";

import { ReactComponent as ArrowDownWhite } from "../assets/icons/common/arrow-down-white.svg";
import { ReactComponent as ArrowDown } from "../assets/icons/common/arrow-down.svg";
import { LatoText, PaytoneOneText } from "../components";
import { getColorStyle } from "../utils";

import type { ColorType } from "../types";
import type { FontSizeType, FontWeightType } from "../types";
import type { CSSProperties, ReactNode } from "react";

import { HStack } from "./HStack";
import { VStack } from "./VStack";

type Props = {
  children: ReactNode;
  headerText: string;
  headerSize: FontSizeType;
  headerWeight: FontWeightType;
  headerColor?: ColorType;
  maxHeight: string;
  headerMargin?: string;
  isBorder?: boolean;
  isPreShow?: boolean;
  isWhiteArrow?: boolean;
  isBottomLine?: boolean;
  fontStyle?: "Lato" | "Paytone One";
};

const style: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
  },
  titleWrapper: {
    width: "100%",
    justifyContent: "space-between",
    padding: "0.5rem",
    cursor: "pointer",
  },
  title: {
    textAlign: "left",
  },
  arrowIcon: {
    width: "0.875rem",
    margin: "auto 0",
    transition: "transform 200ms ease-in-out",
  },
  innerWrapper: {
    width: "100%",
    overflow: "hidden",
    transition: "max-height 400ms ease-in-out",
  },
};

export function Drawer({
  children,
  headerText,
  headerWeight,
  headerSize,
  headerMargin,
  headerColor,
  maxHeight,
  isBorder,
  isPreShow,
  isWhiteArrow,
  fontStyle,
  isBottomLine,
}: Props) {
  const [show, setShow] = useState<boolean>(isPreShow || false);

  const handleToggle = useCallback(() => {
    setShow(!show);
  }, [show]);

  return (
    <VStack
      style={{
        ...style.wrapper,
        borderBottom: isBottomLine
          ? `1px solid ${getColorStyle("disabled")}`
          : "none",
      }}
    >
      <HStack
        style={{
          ...style.titleWrapper,
          borderBottom: isBorder
            ? `1px dashed ${getColorStyle("border")}`
            : "none",
          marginBottom: headerMargin ? headerMargin : "1rem",
        }}
        onClick={handleToggle}
      >
        {(!fontStyle || fontStyle === "Lato") && (
          <LatoText
            text={headerText}
            color={headerColor ? headerColor : "black"}
            weight={headerWeight}
            size={headerSize}
            customStyle={style.title}
          />
        )}
        {fontStyle === "Paytone One" && (
          <PaytoneOneText
            text={headerText}
            color="black"
            weight={headerWeight}
            size={headerSize}
            customStyle={style.title}
          />
        )}
        {isWhiteArrow && (
          <ArrowDownWhite
            style={{
              ...style.arrowIcon,
              transform: show ? "rotate(180deg)" : "rotate(0)",
            }}
            className="transition"
          />
        )}
        {!isWhiteArrow && (
          <ArrowDown
            style={{
              ...style.arrowIcon,
              transform: show ? "rotate(180deg)" : "rotate(0)",
            }}
            className="transition"
          />
        )}
      </HStack>
      <VStack
        className="transition"
        style={{ ...style.innerWrapper, maxHeight: show ? maxHeight : "0" }}
      >
        {children}
      </VStack>
    </VStack>
  );
}
