import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getBlogById, getBlogs } from "../api";
import { BlogContext, BlogsContext } from "../context";

import type { SingleBlogContextType } from "../context";
import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

async function initBlogs(setBlogs: (blogs: SingleBlogContextType[]) => void) {
  const blogs = await getBlogs();
  if (blogs) {
    setBlogs(blogs);
  }
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
async function initBlog(id: string, setBlog: (blog: any) => void) {
  const blog = await getBlogById(id);
  if (blog) {
    setBlog(blog);
  }
}
export function BlogsProvider({ children }: Props) {
  const [blogs, setBlogs] = useState<null | SingleBlogContextType[]>(null);

  useEffect(() => {
    const setNewBlogs = (blogs: SingleBlogContextType[]) => {
      setBlogs(blogs);
    };
    void initBlogs(setNewBlogs);
  }, []);

  if (!blogs) {
    return null;
  }

  return (
    <BlogsContext.Provider value={blogs}>{children}</BlogsContext.Provider>
  );
}

export function BlogProvider({ children }: Props) {
  const [blog, setBlog] = useState<any>(null);
  const { id } = useParams();

  useEffect(() => {
    const setNewBlog = (blog: any) => {
      setBlog(blog);
    };
    if (id) void initBlog(id, setNewBlog);
  }, [id]);

  if (!blog) {
    return null;
  }

  return <BlogContext.Provider value={blog}>{children}</BlogContext.Provider>;
}

export function useBlogsContext() {
  const context = useContext(BlogsContext);

  if (!context) throw new Error("BlogsContext is null");
  return context;
}

export function useBlogContext() {
  const context = useContext(BlogContext);

  if (!context) throw new Error("BlogContext is null");
  return context;
}
