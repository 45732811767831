import { useCallback, useMemo } from "react";

import { ReactComponent as CheckMarkDark } from "../assets/icons/common/check-mark-icon-dark.svg";
import { ReactComponent as CheckMarkLight } from "../assets/icons/common/check-mark-icon-light.svg";
import { COLOR_IS_DARK } from "../constants";
import { getColorStyle } from "../utils";

import type { ColorTagType, ColorType } from "../types";
import type { Dispatch, SetStateAction } from "react";

type Props = {
  text: string;
  color: ColorType;
  isSelected: boolean;
  setIsSelected: Dispatch<SetStateAction<boolean>>;
  tag: ColorTagType;
};

export function ColorSelector({
  text,
  color,
  isSelected,
  setIsSelected,
  tag,
}: Props) {
  const isDark = useMemo(() => COLOR_IS_DARK[tag], [tag]);

  const handleOnClick = useCallback(() => {
    setIsSelected(!isSelected);
  }, [isSelected, setIsSelected]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "1rem",
        height: "1rem",
        padding: "0.375rem",
        background: getColorStyle(color),
        borderRadius: "360px",
        cursor: "pointer",
        border:
          color === "white" ? `1px solid ${getColorStyle("black")}` : "none",
      }}
      onClick={handleOnClick}
      title={text}
    >
      {isSelected &&
        (isDark ? <CheckMarkLight style={{}} /> : <CheckMarkDark style={{}} />)}
    </div>
  );
}
