import { SEO } from "./SEO";

export function AccountSEO() {
  return (
    <SEO
      title="Account - Lucky Kids"
      description="Find you account information."
    />
  );
}
