import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { getColorStyle } from "../../utils";

import type { CSSProperties } from "react";

const desktopStyle: Record<string, CSSProperties> = {
  mainWrapper: {
    width: "100%",
    background: getColorStyle("blue"),
  },
  wrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "3rem 1rem",
    margin: "auto",
    gap: "1.5rem",
  },
  item: {
    flex: "0.4",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  mainWrapper: { width: "100%", background: getColorStyle("blue") },
  wrapper: {
    width: "100%",
    padding: "1rem 1.5rem",
    margin: "auto",
    gap: "1.5rem",
  },
  item: {},
};

export function ReviewsBanner() {
  const { isMobile, isPad } = useIsMobile();

  return (
    <HStack
      style={
        isMobile || isPad ? mobileStyle.mainWrapper : desktopStyle.mainWrapper
      }
    >
      <VStack
        style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
      >
        <LatoText
          text="REVIEWS"
          color="white"
          weight="bold"
          size={isMobile || isPad ? "medium" : "large"}
        />
        {isMobile ? (
          <VStack style={{ gap: "1rem" }}>
            <HStack style={mobileStyle.item}>
              <img
                src="/images/product/product-review-0.svg"
                width="100%"
                alt=""
              />
            </HStack>
            <HStack style={mobileStyle.item}>
              <img
                src="/images/product/product-review-1.svg"
                width="100%"
                alt=""
              />
            </HStack>
            <HStack style={mobileStyle.item}>
              <img
                src="/images/product/product-review-2.svg"
                width="100%"
                alt=""
              />
            </HStack>
          </VStack>
        ) : isPad ? (
          <HStack style={{ gap: "2rem" }}>
            <HStack style={desktopStyle.item}>
              <img
                src="/images/product/product-review-0-pad.svg"
                width="100%"
                alt=""
              />
            </HStack>
            <HStack style={desktopStyle.item}>
              <img
                src="/images/product/product-review-1-pad.svg"
                width="100%"
                alt=""
              />
            </HStack>
            <HStack style={desktopStyle.item}>
              <img
                src="/images/product/product-review-2-pad.svg"
                width="100%"
                alt=""
              />
            </HStack>
          </HStack>
        ) : (
          <HStack style={{ gap: "2rem" }}>
            <HStack style={desktopStyle.item}>
              <img
                src="/images/product/product-review-0.svg"
                width="100%"
                alt=""
              />
            </HStack>
            <HStack style={desktopStyle.item}>
              <img
                src="/images/product/product-review-1.svg"
                width="100%"
                alt=""
              />
            </HStack>
            <HStack style={desktopStyle.item}>
              <img
                src="/images/product/product-review-2.svg"
                width="100%"
                alt=""
              />
            </HStack>
          </HStack>
        )}
      </VStack>
    </HStack>
  );
}
