import * as React from "react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LatoText } from "../../components";
import { useUserContext } from "../../providers";
import { getColorStyle } from "../../utils";
import { VStack } from "../VStack";

import type {
  CSSProperties,
  Dispatch,
  SetStateAction,
  MouseEvent,
} from "react";

const hoverFrameStyle: CSSProperties = {
  position: "absolute",
  top: "3rem",
  right: "0rem",
  width: "10rem",
  padding: "1rem",
  background: getColorStyle("eggShell"),
};

type Props = {
  setToggleUser: Dispatch<SetStateAction<boolean>>;
};
export function AccountDropdown({ setToggleUser }: Props) {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClickAccount = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (pathname !== "/account") {
        navigate("/account");
      }
      setToggleUser(false);
    },
    [pathname, setToggleUser, navigate]
  );

  const handleClickSignOut = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (pathname !== "/logout") {
        navigate("/logout");
      }
      setToggleUser(false);
    },
    [pathname, setToggleUser, navigate]
  );

  const handleClickLogIn = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (pathname !== "/login") {
        navigate("/login");
      }
      setToggleUser(false);
    },
    [pathname, setToggleUser, navigate]
  );

  const handleClickSignUp = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (pathname !== "/signup") {
        navigate("/signup");
      }
      setToggleUser(false);
    },
    [pathname, setToggleUser, navigate]
  );

  return (
    <VStack style={hoverFrameStyle}>
      {user ? (
        <>
          <LatoText
            text={`Hi, ${user.firstName}`}
            color="placeholder"
            weight="medium"
            size="normal"
            customStyle={{ padding: "0.5rem", textAlign: "right" }}
          />
          <LatoText
            text="My Account"
            color="black"
            weight="medium"
            size="normal"
            customStyle={{
              textAlign: "right",
              padding: "0.5rem",
              cursor: "pointer",
            }}
            onClick={handleClickAccount}
          />
          <LatoText
            text="Sign Out"
            color="black"
            weight="medium"
            size="normal"
            customStyle={{
              padding: "0.5rem",
              textAlign: "right",
              cursor: "pointer",
            }}
            onClick={handleClickSignOut}
          />
        </>
      ) : (
        <>
          <LatoText
            text="Login"
            color="black"
            weight="medium"
            size="normal"
            customStyle={{
              textAlign: "right",
              padding: "0.5rem",
              cursor: "pointer",
            }}
            onClick={handleClickLogIn}
          />
          <LatoText
            text="Create Account"
            color="black"
            weight="medium"
            size="normal"
            customStyle={{
              padding: "0.5rem",
              textAlign: "right",
              cursor: "pointer",
            }}
            onClick={handleClickSignUp}
          />
        </>
      )}
    </VStack>
  );
}
