import { LatoText, PaytoneOneText } from "../components";
import { VStack } from "../layouts";
import { useMobileViewContext } from "../providers";

import type { CSSProperties } from "react";

const largeFrameStyle: CSSProperties = {
  width: "100%",
  maxWidth: "1280px",
  padding: "3rem 1rem",
  margin: "auto",
};

const largeFrameMobileStyle: CSSProperties = {
  width: "100%",
  padding: "2rem",
};

type StringList = [string, string];
type ContentType = StringList[];

const content: ContentType = [
  ["Shipping Fees:", "title"],
  [
    "Free shipping for any order that reaches AED 150 or above. Otherwise, 20 AED shipping fee per order, non-refundable.",
    "",
  ],
  ["Return policy:", "title"],
  [
    "Return is valid for 7 days with shoes and shoe box intact (not worn, not broken).",
    "",
  ],
  [
    "All products must be returned unworn and unused, with all related accessories (designer tags, shoe laces and brand merchandise, etc. if any), included within the original box and packaging and in a condition enabling us to sell the product as new. Please be sure to try on any shoes you’ve bought on a clean, carpeted surface to avoid scratching the outsole of the shoe, which could result in damaging the item(s) and refusal of the return. Sale products cannot be returned.",
    "",
  ],
  [
    "The product will be taken back with a Restocking Fee of a minimum of either 15 AED or 15% of the order pricing, with the higher amount deducted from the refund as applicable while making return or exchange.",
    "",
  ],
  ["Exchange policy:", "title"],
  [
    "Exchange is valid for 15 days but can only be done in our stores that are located in Abu Dhabi & Dubai. Please send an email to us with all the details of the exchange and we will prepare the product in the store for you and notify you when the product is ready to be exchanged. When in the store, the customer has to present the email from us with exchange details, the online receipt of the purchase and valid photographic identification of any of the followings: Driver’s license, Original passport or your Emirates ID Card.",
    "",
  ],
  ["Payment processing:", "title"],
  [
    "Once our Customer Support Team has been notified of your request to return, it usually takes 3-5 business days for us to receive the returned item(s), perform a quality check, update your account and issue a refund. Depending on method of payment, your bank/financial institution may take 1-7 additional business days to process the transaction.",
    "",
  ],
  ["Cancellations:", "title"],
  [
    "If you would like to cancel your order, you can do so provided that the order has not yet been shipped. If the item you want to cancel has been shipped, the order cannot be canceled.",
    "",
  ],
  ["COD policy:", "title"],
  [
    "For orders below AED 150, we will charge you a COD fee of AED 30. If the value of your order is under AED 150 and you have chosen cash on delivery as your mode of payment, your total charge will be the order value plus AED 50 (AED 20 shipping fee + AED 30 COD fee), non-refundable.",
    "",
  ],
  [
    "Attention: Currently COD orders are not eligible for returns, only exchange policy is applicable.",
    "",
  ],
  ["Note:", "title"],
  [
    "We would like to provide our customers the flexibility of paying cash on delivery, but we are required to pay our courier an extra fee for all cash on delivery (COD) orders. Hence an extra COD charge of AED 30 is added only when you choose cash as your method of payment. There are no extra fees if you use a debit or credit card to pay online.",
    "",
  ],
  [
    "If the products arrived at your place damaged, defective or if the wrong product was shipped:",
    "",
  ],
  [
    "If you notice any visible damage to the product upon deliver or that a wrong product has been delivered, please refuse the delivery and immediately contact us by Email with photos. We will provide free refund/exchange under such circumstances.",
    "",
  ],
  [
    "Lucky Kids reserves the right to refuse the return of any merchandise and packaging that has been worn, used or damaged. Merchandise that has been altered or damaged will not be accepted. Return of defective items is limited to defects that are not caused by either product misuse or failure to follow care instructions. The policy is subjected to change accordingly.",
    "title",
  ],
];

export function ReturnPolicy() {
  const { isMobile } = useMobileViewContext();

  return (
    <VStack style={isMobile ? largeFrameMobileStyle : largeFrameStyle}>
      <VStack style={{ paddingBottom: "3rem" }}>
        <PaytoneOneText
          text="Shipping, Return & Exchanges Policy "
          color="black"
          weight="normal"
          size={isMobile ? "medium" : "large"}
          customStyle={{ textAlign: "left" }}
        />
      </VStack>
      {content.map((c, index) => (
        <VStack style={{ paddingBottom: "1rem" }} key={index}>
          <LatoText
            text={c[0]}
            color="black"
            weight={c[1].length > 0 ? "medium" : "normal"}
            size="normal"
            customStyle={{ textAlign: "left" }}
          />
        </VStack>
      ))}
    </VStack>
  );
}
