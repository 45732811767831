import { HelmetProvider } from "react-helmet-async";

import { CartProvider } from "./CartProvider";
import { CartToggleProvider } from "./CartToggleProvider";
import { ConstantsProvider } from "./ConstantsProvider";
import { LoadingProvider } from "./LoadingProvider";
import { MobileViewProvider } from "./MobileViewProvider";

import type { ReactNode } from "react";

import { UserProvider } from "./UserProvider";

type Props = {
  children: ReactNode;
};
export function BasicProvider({ children }: Props) {
  return (
    <LoadingProvider>
      <MobileViewProvider>
        <ConstantsProvider>
          <UserProvider>
            <CartProvider>
              <HelmetProvider>
                <CartToggleProvider>{children}</CartToggleProvider>
              </HelmetProvider>
            </CartProvider>
          </UserProvider>
        </ConstantsProvider>
      </MobileViewProvider>
    </LoadingProvider>
  );
}
