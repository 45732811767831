import { useCallback } from "react";

import { updateCart, removeCart } from "../../api";
import { ReactComponent as RemoveIcon } from "../../assets/icons/cart/remove-icon.svg";
import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, Price, VStack } from "../../layouts";
import { Quantity } from "../../layouts";

import type { CartItem } from "../../types";

import {
  useCartIdContext,
  useCartSetterContext,
  useLoadingContext,
} from "../../providers";

import type { CSSProperties } from "react";

import { getColorStyle, getSize } from "../../utils";

type Props = {
  line: CartItem;
};

const desktopStyle: Record<string, CSSProperties> = {
  mainWrapper: {
    width: "100%",
    padding: "1.5rem 1rem",
    justifyContent: "space-between",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  mainWrapper: {
    width: "100%",
    padding: "1rem",
    gap: "1rem",
    background: getColorStyle("eggShell"),
    boxShadow: "0px 8px 15px rgba(171, 171, 171, 0.18)",
    borderRadius: "16px",
  },
};

export function CartItem({
  line: {
    lineId,
    title,
    quantity,
    quantityAvailable,
    productPrice,
    compareAtPrice,
    size,
    image,
  },
}: Props) {
  const { isMobile, isPad } = useIsMobile();
  const { setLoading } = useLoadingContext();
  const setCart = useCartSetterContext();
  const cartId = useCartIdContext();

  const setQuantity = useCallback(
    async (value: number) => {
      setLoading(true);
      const cart = await updateCart({
        cartId,
        lines: [
          {
            id: lineId,
            quantity: value,
          },
        ],
      });
      setCart(cart);
      setLoading(false);
    },
    [cartId, lineId, setCart, setLoading]
  );

  const removeLineItem = useCallback(async () => {
    setLoading(true);

    const cart = await removeCart({
      cartId,
      lines: [lineId],
    });

    setCart(cart);
    setLoading(false);
  }, [cartId, lineId, setCart, setLoading]);

  return (
    <HStack
      style={
        isMobile || isPad ? mobileStyle.mainWrapper : desktopStyle.mainWrapper
      }
    >
      <HStack
        style={{
          width: isMobile || isPad ? "100%" : "36%",
          textAlign: "left",
          gap: "1.5rem",
        }}
      >
        <img
          src={image}
          alt=""
          style={{ width: isMobile || isPad ? "32%" : "6.25rem" }}
        />
        <VStack
          style={{
            width: "100%",
            overflow: isMobile || isPad ? "hidden" : "unset",
          }}
        >
          <LatoText
            text={title}
            color="black"
            weight="bold"
            size="normalMedium"
            customStyle={{
              textAlign: "left",
              marginBottom: "0.5rem",
              textOverflow: isMobile || isPad ? "ellipsis" : "unset",
              whiteSpace: isMobile || isPad ? "nowrap" : "unset",
              overflow: isMobile || isPad ? "hidden" : "unset",
            }}
            title={title}
          />
          {isMobile || isPad ? (
            <VStack>
              <Price
                currentPrice={productPrice}
                compareAtPrice={compareAtPrice}
                currencyCode="AED"
                priceSize="normal"
                currentPriceWeight="medium"
                compareAtPriceWeight="normal"
                customStyle={{
                  textAlign: "left",
                  marginBottom: isMobile || isPad ? "0.5rem" : "0.25rem",
                }}
              />
              <HStack
                style={{
                  marginBottom: "0.5rem",
                  gap: "0.5rem",
                }}
              >
                <Quantity
                  quantity={quantity}
                  setQuantity={setQuantity}
                  max={quantityAvailable}
                  style="mobile"
                />
                <VStack style={{ justifyContent: "center" }}>
                  <RemoveIcon
                    width={isMobile || isPad ? "0.875rem" : "1.5rem"}
                    height={isMobile || isPad ? "0.875rem" : "1.5rem"}
                    style={{ cursor: "pointer" }}
                    onClick={removeLineItem}
                  />
                </VStack>
              </HStack>
            </VStack>
          ) : (
            <VStack>
              <LatoText
                text={"QTY: " + quantity}
                color="black"
                weight="medium"
                size="normal"
                customStyle={{ textAlign: "left", marginBottom: "0.25rem" }}
              />
              <Price
                currentPrice={productPrice}
                compareAtPrice={compareAtPrice}
                currencyCode="AED"
                priceSize="normal"
                currentPriceWeight="medium"
                compareAtPriceWeight="normal"
                customStyle={{
                  textAlign: "left",
                  marginBottom: isMobile || isPad ? "0.5rem" : "0.25rem",
                }}
              />
            </VStack>
          )}

          <LatoText
            text={"SIZE: " + getSize(size)}
            color="black"
            weight="medium"
            size="normal"
            customStyle={{ textAlign: "left" }}
          />
        </VStack>
      </HStack>
      {!(isMobile || isPad) && (
        <VStack style={{ width: "15%" }}>
          <HStack
            style={{
              justifyContent: "space-between",
            }}
          >
            <Quantity
              quantity={quantity}
              setQuantity={setQuantity}
              max={quantityAvailable}
              style="desktop"
            />
            <VStack style={{ justifyContent: "center" }}>
              <RemoveIcon
                width="1.5rem"
                height="1.5rem"
                style={{ cursor: "pointer" }}
                onClick={removeLineItem}
              />
            </VStack>
          </HStack>
        </VStack>
      )}
      {!(isMobile || isPad) && (
        <LatoText
          text={"AED" + " " + compareAtPrice}
          color="black"
          weight="bold"
          size="normalMedium"
          customStyle={{ width: "11.2%", textAlign: "right" }}
        />
      )}
    </HStack>
  );
}
