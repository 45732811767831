import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";

import type { CSSProperties } from "react";

const desktopStyle: Record<string, CSSProperties> = {
  bannerWrapper: {
    width: "100%",
    padding: "5rem 1rem",
    alignItems: "center",
  },
  imgWrapper: {
    width: "100%",
    maxWidth: "1280px",
    justifyContent: "space-between",
  },
  text: {
    marginBottom: "4rem",
  },
  img: {
    width: "23.125%",
    cursor: "pointer",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  bannerWrapper: {
    width: "100%",
    padding: "3rem 1.5rem",
    alignItems: "center",
  },
  text: {
    marginBottom: "2rem",
  },
  imgWrapper: {
    marginBottom: "1rem",
    justifyContent: "space-between",
  },
  img: {
    width: "47.9%",
    cursor: "pointer",
  },
};

const IMG_SRC = [
  { src: "/images/home_page/age-group-babies.png", path: "babies" },
  { src: "/images/home_page/age-group-toddlers.png", path: "toddlers" },
  { src: "/images/home_page/age-group-kids.png", path: "kids" },
  { src: "/images/home_page/age-group-teens.png", path: "teens" },
];

export function AgeBanner() {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();

  const handleImageClick = useCallback(
    (path: string) => () => {
      navigate(`/shoes/${path}`);
    },
    [navigate]
  );

  return (
    <VStack
      style={isMobile ? mobileStyle.bannerWrapper : desktopStyle.bannerWrapper}
    >
      <LatoText
        text="SHOP BY AGES"
        color="black"
        weight="bold"
        size={isMobile ? "medium" : "large"}
        customStyle={isMobile ? mobileStyle.text : desktopStyle.text}
      />
      {!isMobile && (
        <HStack style={desktopStyle.imgWrapper}>
          {IMG_SRC.map(({ src, path }) => (
            <img
              src={src}
              style={desktopStyle.img}
              alt=""
              key={path}
              onClick={handleImageClick(path)}
            />
          ))}
        </HStack>
      )}
      {isMobile && (
        <VStack>
          <HStack style={mobileStyle.imgWrapper}>
            <img
              src={IMG_SRC[0].src}
              style={mobileStyle.img}
              alt=""
              onClick={handleImageClick(IMG_SRC[0].path)}
            />
            <img
              src={IMG_SRC[1].src}
              style={mobileStyle.img}
              alt=""
              onClick={handleImageClick(IMG_SRC[1].path)}
            />
          </HStack>
          <HStack style={mobileStyle.imgWrapper}>
            <img
              src={IMG_SRC[2].src}
              style={mobileStyle.img}
              alt=""
              onClick={handleImageClick(IMG_SRC[2].path)}
            />
            <img
              src={IMG_SRC[3].src}
              style={mobileStyle.img}
              alt=""
              onClick={handleImageClick(IMG_SRC[3].path)}
            />
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}
