import { useContext, useEffect, useState } from "react";

import { getProducts } from "../api";
import { ProductsBannerContext } from "../context";
import { organizeProducts } from "../utils";

import type { ProductsBannerContextType } from "../context";
import type { ReactNode } from "react";

type Props = {
  tag: string;
  children: ReactNode;
};

async function initProducts(
  tag: string,
  setProducts: (products: ProductsBannerContextType) => void
) {
  const { products } = await getProducts(tag);
  const { products: newProducts } = organizeProducts(products, 4); // desc: display 4 product cards

  setProducts(newProducts);
}
export function ProductsBannerProvider({ tag, children }: Props) {
  const [context, setContext] = useState<ProductsBannerContextType>({});

  useEffect(() => {
    void initProducts(tag, setContext);
  }, [tag]);

  return (
    <ProductsBannerContext.Provider value={context}>
      {children}
    </ProductsBannerContext.Provider>
  );
}

export function useNewArrivalsProvider() {
  const context = useContext(ProductsBannerContext);

  if (!context) throw new Error("ProductsBannerContext is null");
  return context;
}
