import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Quantity } from "..";
import { removeCart, updateCart } from "../../api";
import { ReactComponent as RemoveIcon } from "../../assets/icons/cart/remove-icon.svg";
import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";

import type { CartItem } from "../../types";
import type { CSSProperties } from "react";

import { useCartIdContext, useCartSetterContext } from "../../providers";
import { getProductId, getSize } from "../../utils";
import { HStack } from "../HStack";
import { Price } from "../Price";
import { VStack } from "../VStack";

type CartItemProps = {
  item: CartItem;
  handleClose: () => void;
};

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "1rem",
    gap: "1.5rem",
    boxShadow: "0px 8px 15px rgba(171, 171, 171, 0.18)",
    borderRadius: "16px",
  },
  imageWrapper: {
    position: "relative",
    width: "12.5rem",
    height: "12.5rem",
  },
  detailWrapper: {
    flexGrow: 1,
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {},
  imageWrapper: {
    width: "6.25rem",
    height: "6.25rem",
  },
};

export function CartItem({
  item: {
    lineId,

    image,
    title,
    productPrice,
    compareAtPrice,
    quantity,
    quantityAvailable,
    size,
    productId,
  },
  handleClose,
}: CartItemProps) {
  const { isMobile, isPad } = useIsMobile();
  const navigate = useNavigate();
  const setCart = useCartSetterContext();
  const cartId = useCartIdContext();

  const setQuantity = useCallback(
    async (value: number) => {
      const cart = await updateCart({
        cartId,
        lines: [
          {
            id: lineId,
            quantity: value,
          },
        ],
      });
      setCart(cart);
    },
    [cartId, lineId, setCart]
  );

  const removeLineItem = useCallback(async () => {
    const cart = await removeCart({
      cartId,
      lines: [lineId],
    });

    setCart(cart);
  }, [cartId, lineId, setCart]);

  const handleImageClick = useCallback(() => {
    navigate(`/product/${title}/${getProductId(productId)}`);
    handleClose();
  }, [handleClose, navigate, productId, title]);

  return (
    <HStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
    >
      <HStack
        style={
          isMobile || isPad
            ? mobileStyle.imageWrapper
            : desktopStyle.imageWrapper
        }
      >
        <img
          src={image}
          alt=""
          style={{ width: "100%", height: "100%", cursor: "pointer" }}
          onClick={handleImageClick}
        />
      </HStack>
      <VStack
        style={
          isMobile || isPad
            ? mobileStyle.detailWrapper
            : desktopStyle.detailWrapper
        }
      >
        <LatoText
          text={title}
          color="black"
          weight="bold"
          size={isMobile || isPad ? "normal" : "normalMedium"}
          customStyle={{ textAlign: "left", marginBottom: "0.5rem" }}
        />
        <Price
          currentPrice={productPrice}
          compareAtPrice={compareAtPrice}
          currencyCode="AED"
          priceSize="normalMedium"
          currentPriceWeight="bold"
          compareAtPriceWeight="normal"
          customStyle={{
            textAlign: "left",
            marginBottom: "0.5rem",
          }}
        />
        <HStack
          style={{
            marginBottom: "0.5rem",
            gap: "0.5rem",
          }}
        >
          <Quantity
            quantity={quantity}
            setQuantity={setQuantity}
            max={quantityAvailable}
            style="dropdown"
          />
          <VStack style={{ justifyContent: "center" }}>
            <RemoveIcon
              width={"0.875rem"}
              height={"0.875rem"}
              style={{ cursor: "pointer" }}
              onClick={removeLineItem}
            />
          </VStack>
        </HStack>
        <LatoText
          text={"SIZE: " + getSize(size)}
          color="black"
          weight="medium"
          size="normal"
          customStyle={{ textAlign: "left", marginBottom: "0.5rem" }}
        />
      </VStack>
    </HStack>
  );
}
