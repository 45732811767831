import { useContext, useMemo, useState } from "react";

import { ModalContext } from "../context";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function ModalProvider({ children }: Props) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [popuped, setPopuped] = useState<boolean>(false);

  const context = useMemo(
    () => ({ modalIsOpen, setModalIsOpen, popuped, setPopuped }),
    [modalIsOpen, setPopuped, popuped]
  );

  return (
    <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
  );
}

export function useModalContext() {
  const context = useContext(ModalContext);

  if (context === null) {
    throw new Error("ModalContext is null");
  }
  return context;
}
