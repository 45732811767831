import { createContext } from "react";

import type { Dispatch, SetStateAction } from "react";

export type ModalContextType = {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  popuped: boolean;
  setPopuped: Dispatch<SetStateAction<boolean>>;
};

export const ModalContext = createContext<ModalContextType | null>(null);
