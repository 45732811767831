import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { getColorStyle } from "../../utils";

import type { CSSProperties } from "react";

const desktopStyle: Record<string, CSSProperties> = {
  mainWrapper: {
    width: "100%",
    background: getColorStyle("yellow"),
  },
  wrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "3rem 1rem",
    margin: "auto",
    gap: "1.5rem",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  mainWrapper: { width: "100%", background: getColorStyle("yellow") },
  wrapper: {
    width: "100%",
    padding: "1rem 0",
    margin: "auto",
    gap: "1rem",
  },
};

export function FeaturesBanner() {
  const { isMobile, isPad } = useIsMobile();

  return (
    <HStack
      style={isMobile ? mobileStyle.mainWrapper : desktopStyle.mainWrapper}
    >
      {isMobile ? (
        <VStack style={mobileStyle.wrapper}>
          <HStack>
            <img
              src="/images/product/product-feature-mobile-0.svg"
              width="100%"
              alt=""
            />
          </HStack>
          <HStack>
            <img
              src="/images/product/product-feature-mobile-1.svg"
              width="100%"
              alt=""
            />
          </HStack>
          <HStack>
            <img
              src="/images/product/product-feature-mobile-2.svg"
              width="100%"
              alt=""
            />
          </HStack>
        </VStack>
      ) : isPad ? (
        <HStack style={desktopStyle.wrapper}>
          <VStack style={{ width: "100%", maxWidth: "1280px" }}>
            <img
              src="/images/product/product-features.svg"
              width="100%"
              alt=""
            />
          </VStack>
        </HStack>
      ) : (
        <HStack style={desktopStyle.wrapper}>
          <HStack
            style={{
              width: "34%",
              marginLeft: "0",
            }}
          >
            <img
              src="/images/product/product-feature-0.svg"
              width="180%"
              alt=""
            />
          </HStack>
          <HStack
            style={{
              width: "34%",
              marginLeft: "0",
            }}
          >
            <img
              src="/images/product/product-feature-1.svg"
              width="180%"
              alt=""
            />
          </HStack>
          <HStack
            style={{
              width: "34%",
              marginLeft: "0",
            }}
          >
            <img
              src="/images/product/product-feature-2.svg"
              width="180%"
              alt=""
            />
          </HStack>
        </HStack>
      )}
    </HStack>
  );
}
