/**
 *                mobile: 320px-480px
 *               tablets: 481px-768px
 *  small screen desktop: 769px-1024px
 *  large screen desktop: 1025px-1280px
 */

// we only have 1 break point to differentiate web and mobile view

import debounce from "lodash/debounce";
import { useState, useEffect, useMemo } from "react";

export function useIsMobile() {
  const [isMobile, setSetIsMobile] = useState<boolean>(window.innerWidth < 480);
  const [isPad, setSetIsPad] = useState<boolean>(
    window.innerWidth >= 480 && window.innerWidth < 960
  );

  const handleResize = useMemo(
    () =>
      debounce(() => {
        if (window.innerWidth < 480 !== isMobile) {
          setSetIsMobile(window.innerWidth < 480);
        }
        if (window.innerWidth >= 480 && window.innerWidth < 960 !== isPad) {
          setSetIsPad(window.innerWidth >= 480 && window.innerWidth < 960);
        }
      }, 200),
    [isMobile, isPad]
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return { isMobile, isPad };
}
