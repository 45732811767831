import { FILTER } from "../constants";

import type { FilterStateType } from "../context";
import type { ProductsListType, ProductType, TagType } from "../types";

export function getFilterState(type: string) {
  const result = {} as FilterStateType;
  Object.keys(FILTER).forEach((key) => {
    const { options } = FILTER[key];
    result[key] = options.map(({ text }) => text.toLowerCase() === type);
  });
  return result;
}

export function getTotalPages(length: number, limit: number) {
  return Math.floor(length / limit) + (length % limit) > 0 ? 1 : 0;
}

export function organizeProducts(
  products: ProductType[],
  first?: number,
  sort?: number
) {
  const result = {} as ProductsListType;
  let productsOrder = [] as string[];
  let counter = 0;
  const tagItems = [] as string[]; // Array that stores items'titles with manual-bs tag

  products.forEach((product) => {
    const { id, title, image, price, tags, compareAtPrice } = product;
    if (tags.includes("manual-bs")) {
      tagItems.push(title);
    }
    if (result[title]) {
      result[title].productId.push(id);
      result[title].tags.push(tags);
      result[title].images.push(image);
      result[title].prices.push(price);
      result[title].compareAtPrices.push(compareAtPrice || "");
      return;
    } else {
      if (first && counter >= first) {
        return;
      }
      productsOrder.push(title);
      result[title] = {
        productId: [id],
        tags: [tags],
        images: [image],
        prices: [price],
        compareAtPrices: [compareAtPrice],
      };
      counter++;
      return;
    }
  });

  // When it's the "newest" filter, move items with manual-bs tag to the front
  if (sort === 0) {
    const filteredTagItems = productsOrder.filter((el) =>
      tagItems.includes(el)
    );
    const filteredNonTagItems = productsOrder.filter(
      (el) => !tagItems.includes(el)
    );
    productsOrder = [...filteredTagItems, ...filteredNonTagItems];
  }

  return { products: result, productsOrder };
}

export function filterSortProducts(
  filter: FilterStateType,
  sort: number,
  products: ProductType[]
) {
  return sortProducts(sort, filterProducts(filter, products));
}

export function filterProducts(
  filter: FilterStateType,
  products: ProductType[]
) {
  const availableTagsMap = {} as Record<string, TagType[]>;

  Object.keys(filter).forEach((key) => {
    const { options } = FILTER[key];
    availableTagsMap[key] = [];
    filter[key].forEach((item, index) => {
      if (item) {
        availableTagsMap[key].push(options[index].tag);
      }
    });
  });

  return filterByTags(availableTagsMap, products);
}

export function filterByTags(
  availableTagsMap: Record<string, TagType[]>,
  products: ProductType[]
) {
  const keys = Object.keys(availableTagsMap);
  return keys.reduce((prev, key) => {
    const availableTags = availableTagsMap[key];

    if (availableTags.length === 0) return prev;

    return prev.filter(({ tags }) =>
      tags.reduce((prev, tag) => prev || availableTags.includes(tag), false)
    );
  }, products);
}

export function sortProducts(sort: number, products: ProductType[]) {
  switch (sort) {
    case 2: // sort by Price High to Low
      return products.sort((a, b) => Number(b.price) - Number(a.price));
    case 1: // sort by Price Low to High
      return products.sort((a, b) => Number(a.price) - Number(b.price));
    case 0: // sort by Newest
      return products.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    default:
      return products;
  }
}

export function getProductsByPage(
  index: number,
  limit: number,
  products: ProductsListType
) {
  return products;
}

export function getProductId(id: string) {
  let result = "";
  for (let i = id.length - 1; id[i] !== "/"; i--) {
    result = id[i] + result;
  }
  return result;
}

export function getSize(size: string) {
  return size.trim();
}

export function transformCamelCaseToWords(text: string) {
  let result = "";
  for (let i = 0; i < text.length; i++) {
    if (text[i].toUpperCase() === text[i]) {
      result += ` ${text[i]}`;
    } else {
      result += text[i];
    }
  }
  return result;
}

export function ageGroupMapping(ageGroup?: string) {
  if (ageGroup === "new-arrivals") {
    return "newArrivals";
  }
  if (ageGroup === "best-sellers") {
    return "bestSellers";
  }
  return ageGroup;
}
