import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { Drawer, VStack } from "../../layouts";

type Props = {
  fitTip: string;
};

export function FitTips({ fitTip }: Props) {
  const { isMobile, isPad } = useIsMobile();

  if (!fitTip) return null;

  return (
    <Drawer
      headerText="Fit Tip"
      headerSize={isMobile || isPad ? "normal" : "normalMedium"}
      headerWeight={"bold"}
      maxHeight="200px"
      isBottomLine={true}
    >
      <VStack style={{ padding: "0 0.5rem 1rem" }}>
        <LatoText
          text={fitTip}
          color="black"
          weight="normal"
          size={isMobile || isPad ? "small" : "normal"}
          customStyle={{
            textAlign: "left",
            lineHeight: isMobile || isPad ? "unset" : "25.6px",
          }}
        />
      </VStack>
    </Drawer>
  );
}
