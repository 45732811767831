import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { LatoText } from "../components";
import { useIsMobile } from "../hooks";
import { getColorStyle, getProductId } from "../utils";

import type { ProductCardType } from "../types";
import type { CSSProperties } from "react";

import { HStack } from "./HStack";
import { Price } from "./Price";
import { TagGroup } from "./TagGroup";
import { VStack } from "./VStack";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    maxWidth: "296px",
    padding: "1rem 1rem 1.5rem",
    background: getColorStyle("white"),
    boxShadow: "0px 8px 15px rgba(171, 171, 171, 0.18)",
    borderRadius: "1rem",
    gap: "1.5rem",
  },
  topWrapper: {
    alignItems: "center",
    flexGrow: "1",
  },
  tagWrapper: {
    minHeight: "1.8125rem",
    width: "100%",
    justifyContent: "right",
  },
  bottomWrapper: {
    width: "100%",
    gap: "0.75rem",
  },
  img: { width: "200px", cursor: "pointer" },
  title: {},
  priceWrapper: {
    justifyContent: "space-around",
    padding: "0 0.5rem",
  },
  price: {},
  thumbnailWrapper: {
    width: "100%",
    gap: "0.5rem",
    alignItems: "center",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "1rem 0.5rem",
    background: getColorStyle("white"),
    boxShadow: "0px 8px 15px rgba(171, 171, 171, 0.18)",
    borderRadius: "1rem",
    gap: "1rem",
  },
  topWrapper: {
    alignItems: "center",
    flexGrow: "1",
  },
  tagWrapper: {
    minHeight: "1.125rem",
    width: "100%",
    justifyContent: "right",
  },
  bottomWrapper: {
    gap: "0.25rem",
  },
  img: { width: "100px" },
  title: { cursor: "pointer" },
  priceWrapper: { justifyContent: "space-around" },
  price: {},
  thumbnailWrapper: {
    width: "100%",
    gap: "0.375rem",
  },
};

export function ProductCard({
  title,
  productId,
  images,
  prices,
  compareAtPrices,
  tags,
}: ProductCardType & { title: string }) {
  const { isMobile, isPad } = useIsMobile();
  const [index, setIndex] = useState<number>(0);
  const titleWords = title.split(" ");
  const mobileTitle = titleWords.slice(0, 2).join(" ");
  const mobileSubTitle = titleWords.slice(2).join(" ");

  const handleThumbnailClick = useCallback(
    (idx: number) =>
      function () {
        if (idx !== index) {
          setIndex(idx);
        }
      },
    [index]
  );

  const imgWrapperStyle = useMemo(() => {
    if (tags && tags[index] && tags[index].length > 0) {
      return isMobile || isPad
        ? mobileStyle.topWrapper
        : desktopStyle.topWrapper;
    }
    return isMobile || isPad
      ? { ...mobileStyle.topWrapper, paddingTop: "1.8125rem" }
      : { ...desktopStyle.topWrapper, paddingTop: "1.125rem" };
  }, [index, isMobile, isPad, tags]);

  return (
    <VStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
    >
      <VStack style={imgWrapperStyle}>
        <HStack
          style={
            isMobile || isPad ? mobileStyle.tagWrapper : desktopStyle.tagWrapper
          }
        >
          {tags && tags[index] && tags[index].length > 0 && (
            <TagGroup tags={tags[index]} />
          )}
        </HStack>
        <Link
          to={`/product/${title.replaceAll(" ", "-")}/${getProductId(
            productId[index]
          )}`}
          target="_blank"
          style={{ margin: "auto" }}
        >
          <img
            src={images[index]}
            alt=""
            style={isMobile || isPad ? mobileStyle.img : desktopStyle.img}
          />
        </Link>
      </VStack>
      <VStack
        style={
          isMobile || isPad
            ? mobileStyle.bottomWrapper
            : desktopStyle.bottomWrapper
        }
      >
        <Link
          to={`/product/${title}/${getProductId(productId[index])}`}
          target="_blank"
        >
          {isMobile || isPad ? (
            <VStack style={{ padding: "0 0.3rem" }}>
              <LatoText
                text={mobileTitle}
                color="black"
                weight="normal"
                size="small"
                customStyle={{
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
              <LatoText
                text={mobileSubTitle}
                color="black"
                weight="normal"
                size="small"
                customStyle={{
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              />
            </VStack>
          ) : (
            <LatoText
              text={title}
              color="black"
              weight="normal"
              size="normal"
              customStyle={{ cursor: "pointer" }}
            />
          )}
        </Link>
        <Price
          currentPrice={prices[index]}
          compareAtPrice={compareAtPrices[index]}
          currencyCode="AED"
          priceSize={isMobile || isPad ? "small" : "normal"}
          currentPriceWeight={isMobile || isPad ? "medium" : "bold"}
          compareAtPriceWeight={isMobile || isPad ? "medium" : "bold"}
          alignCenter={true}
        />
        <Thumbnail
          images={images}
          index={index}
          onClick={handleThumbnailClick}
        />
      </VStack>
    </VStack>
  );
}

type ThumbnailProps = {
  images: string[];
  index: number;
  onClick: (index: number) => () => void;
};

function Thumbnail({ images, index, onClick }: ThumbnailProps) {
  const { isMobile, isPad } = useIsMobile();

  const isMoreThanFour = useMemo(() => images.length > 4, [images.length]);
  return (
    <HStack
      style={
        isMobile || isPad
          ? mobileStyle.thumbnailWrapper
          : desktopStyle.thumbnailWrapper
      }
    >
      {images.map((img, idx) => {
        if (isMoreThanFour && idx > 2) return null;
        return (
          <img
            key={idx}
            src={img}
            alt=""
            onClick={onClick(idx)}
            style={{
              border:
                index === idx
                  ? `1px solid ${getColorStyle("placeholder")}`
                  : `1px solid ${getColorStyle("border")}`,
              width: isMobile || isPad ? "1.5rem" : "2.5rem",
              height: isMobile || isPad ? "1.5rem" : "2.5rem",
              borderRadius: "0.25rem",
              cursor: "pointer",
            }}
          />
        );
      })}
      {isMoreThanFour && (
        <LatoText
          text={`+${images.length - 3} MORE`}
          color="black"
          weight="medium"
          size={isMobile || isPad ? "small" : "normalSmall"}
          customStyle={{ marginLeft: isMobile || isPad ? "0" : "0.5rem" }}
        />
      )}
    </HStack>
  );
}
