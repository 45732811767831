import { Slides, VStack } from "../../layouts";
import { ProductsBannerProvider } from "../../providers";
import { ModalProvider } from "../../providers/ModalProvider";

import { AgeBanner } from "./AgeBanner";
import { DisplayingBanner } from "./DisplayingBanner";

import type { ColorType } from "../../types";

import { MarketingBanner } from "./MarketingBanner";
import { ProductsBanner } from "./ProductsBanner";
import { SlidePromo2 } from "./SlidePromo2";
import { TermsAndConditions } from "./TermsAndConditions";

const mainWrapperDStyle = {
  width: "100%",
};

const SLIDES = [
  // {
  //   src: {
  //     desktop: "/images/home_page/hero-slide-0.png",
  //     mobile: "/images/home_page/hero-slide-0-mobile.png",
  //   },
  //   backgroundColor: "yellow" as ColorType,
  //   promo: <SlidePromo1 />,
  //   link: "shoes/sale",
  // },
  {
    src: {
      desktop: "/images/home_page/temp-hero-slide.png",
      mobile: "/images/home_page/temp-hero-slide-mobile.png",
    },
    backgroundColor: "yellow" as ColorType,
    promo: <SlidePromo2 />,
    link: "shoes/new-arrivals",
  },
];

export function Home() {
  return (
    <>
      <ModalProvider>
        <VStack style={mainWrapperDStyle}>
          <Slides
            slides={SLIDES}
            showArrows={false}
            showThumbs={false}
            showIndicators={true}
            autoPlay={true}
            infiniteLoop={true}
            interval={5000}
          />
          <AgeBanner />
          <ProductsBannerProvider tag="bestSellers">
            <ProductsBanner
              title="BEST SELLERS"
              backgroundColor="cream"
              isBestSellers={true}
            />
          </ProductsBannerProvider>
          <ProductsBannerProvider tag="newArrivals">
            <ProductsBanner
              title="NEW ARRIVALS"
              backgroundColor="inputFilled"
              isNewArrivals={true}
            />
          </ProductsBannerProvider>
          <DisplayingBanner />
          <MarketingBanner />
        </VStack>

        <TermsAndConditions />
      </ModalProvider>
    </>
  );
}
