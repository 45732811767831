import { useCallback } from "react";

import { Radio } from "../../components";
import { SORT } from "../../constants";
import { Drawer } from "../../layouts";
import { useFilterContext } from "../../providers";

export function Sort() {
  const { sort, setSort } = useFilterContext();

  const handleOnSelect = useCallback(
    (idx: number) =>
      function () {
        setSort(idx);
      },
    [setSort]
  );

  return (
    <Drawer
      headerText="Sort By"
      headerSize="normalMedium"
      headerWeight="bold"
      maxHeight="8rem"
      isBorder
      isPreShow
    >
      {SORT.map(({ text }, idx) => (
        <Radio
          key={text}
          text={text}
          selected={sort === idx}
          onClick={handleOnSelect(idx)}
        />
      ))}
    </Drawer>
  );
}
