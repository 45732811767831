import Cookies from "js-cookie";

import { getClientDomain, reportError } from "../utils";

import type {
  Cart,
  CreateCartResponse,
  AddToCartRequest,
  UpdateCartRequest,
  RemoveCartRequest,
} from "../types";
import type { Dispatch, SetStateAction } from "react";

export async function createCart(callback: Dispatch<SetStateAction<string>>) {
  try {
    const response = await fetch(`${getClientDomain()}/api/cart-create`, {
      method: "post",
    });
    const cart = (await response.json()) as CreateCartResponse;
    callback(cart.id);
    Cookies.set("cartId", cart.id);
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/cart-create/",
      method: "POST",
    });
    throw new Error(JSON.stringify(e));
  }
}

export async function getCartById(
  id: string,
  setCart: Dispatch<SetStateAction<Cart>>,
  setCardId: Dispatch<SetStateAction<string>>
) {
  const body = JSON.stringify({ id });
  try {
    const response = await fetch(`${getClientDomain()}/api/get-cart-by-id`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    if (response.status === 400) {
      Cookies.remove("cartId");

      void createCart(setCardId);
    } else {
      const cart = await response.json();
      setCart(cart);
    }
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/get-cart-by-id/",
      method: "POST",
      body,
    });
    throw new Error(JSON.stringify(e));
  }
}

export async function addToCart(request: AddToCartRequest) {
  const body = JSON.stringify(request);
  try {
    const response = await fetch(`${getClientDomain()}/api/cart-add`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    return await response.json();
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/cart-add/",
      method: "POST",
      body,
    });
    throw new Error(JSON.stringify(e));
  }
}

export async function updateCart(request: UpdateCartRequest) {
  const body = JSON.stringify(request);
  try {
    const response = await fetch(`${getClientDomain()}/api/cart-update`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    return await response.json();
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/cart-update/",
      method: "POST",
      body,
    });
    throw new Error(JSON.stringify(e));
  }
}

export async function removeCart(request: RemoveCartRequest) {
  const body = JSON.stringify(request);
  try {
    const response = await fetch(`${getClientDomain()}/api/cart-delete`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    return await response.json();
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/cart-delete/",
      method: "POST",
      body,
    });
    throw new Error(JSON.stringify(e));
  }
}

export async function updateCartByEmail(email: string, cartId: string) {
  const body = JSON.stringify({ cartId, email });
  try {
    const response = await fetch(`${getClientDomain()}/api/cart-email-update`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    return await response.json();
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/cart-email-update/",
      method: "POST",
      body,
    });
    return null;
  }
}

export async function updateCartDiscountCode(
  cartId: string,
  discountCodes: Array<string>
) {
  try {
    const response = await fetch(
      `${getClientDomain()}/api/cart-update-discount-code`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cartId, discountCodes }),
      }
    );
    return await response.json();
  } catch (e: unknown) {
    return null;
  }
}
