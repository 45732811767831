import { useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { HStack } from "../../layouts";
import { getColorStyle, getProductId } from "../../utils";

type Props = {
  thumbnail: {
    id: string;
    image: string;
  }[];
};

export function Thumbnail({ thumbnail }: Props) {
  return (
    <HStack
      style={{
        width: "100%",
        padding: "0.5rem 0",
        gap: "0.5rem",
        flexWrap: "wrap",
      }}
    >
      {thumbnail.map((item) => (
        <Square id={item.id} image={item.image} key={item.id} />
      ))}
    </HStack>
  );
}

type SquareProps = {
  id: string;
  image: string;
};
function Square({ id, image }: SquareProps) {
  const { productId, title } = useParams();
  const navigate = useNavigate();

  const active = useMemo(
    () => !productId || id.includes(productId),
    [id, productId]
  );

  const handleOnClick = useCallback(() => {
    if (!active) {
      navigate(`/product/${title}/${getProductId(id)}`);
    }
  }, [active, id, navigate, title]);

  return (
    <img
      src={image}
      alt=""
      onClick={handleOnClick}
      style={{
        border: active
          ? `1px solid ${getColorStyle("placeholder")}`
          : `1px solid ${getColorStyle("border")}`,
        width: "2.5rem",
        height: "2.5rem",
        borderRadius: "0.25rem",
        cursor: "pointer",
      }}
    />
  );
}
