export const INFO = [
  {
    title: "SUPPORTS",
    children: [
      {
        text: "FAQ",
        link: "/FAQ",
      },
      {
        text: "Shipping, Return & Exchanges Policy",
        link: "/policy",
      },
    ],
  },
  {
    title: "CONTACT US",
    children: [
      {
        text: "Email: business@luckykidsking.com",
        link: "",
      },
      {
        text: "Tel: 971-544648666",
        link: "",
      },
      {
        text: "First Floor Shop No.E-106, Al Asalah St - Zone 1E19 - Zone 1 - Abu Dhabi",
        link: "",
      },
      {
        text: "Ground Floor Shop No.G-85, 8th St - Port Saeed - Dubai",
        link: "",
      },
      {
        text: "Ground Floor Shop No. E-15, Al Rigga Rd - Deira - Dubai",
        link: "",
      },
    ],
  },
  {
    title: "ABOUT US",
    children: [
      {
        text: "Brand Story",
        link: "/about-us",
      },
      {
        text: "Store Locations",
        link: "/about-us",
      },
    ],
  },
];

export const ACCOUNT_INFO_LOGGED_IN = [
  {
    title: "MY ACCOUNT",
    children: [
      {
        text: "Order History",
        link: "/account",
      },
    ],
  },
];

export const ACCOUNT_INFO_NOT_LOGGED_IN = [
  {
    title: "MY ACCOUNT",
    children: [
      {
        text: "Sign in",
        link: "/login",
      },
      {
        text: "Create Account",
        link: "/signup",
      },
    ],
  },
];

export const EMAIL_SUBSCRIBE_EXCLUDES_ROUTE = [
  "/login",
  "/signup",
  "/reset",
  "/logout",
  "/account",
  "/edit",
];
