import { SEO } from "./SEO";

export function AboutSEO() {
  return (
    <SEO
      title="About Us  - Lucky Kids"
      description="Lucky Kids make shoes kids want to wear and parents can afford. Our shoes make kids and parents smile."
    />
  );
}
