export function getProductsSeoTitleDescription(ageGroup?: string) {
  switch (ageGroup) {
    case "babies":
      return {
        title: "Baby Shoes With Style - Lucky Kids",
        description:
          "From casual shoes to sneakers, choose the right shoes for your little one. Shop our wide selection of sweet baby shoes.",
      };
    case "toddlers":
      return {
        title: "Toddler Shoes with Comfort and Style - Lucky Kids",
        description:
          " Check our wide selection of toddler shoes. The style you like, the price you can afford. ",
      };
    case "kids":
      return {
        title: "All in One Kids Shoes Shop - Lucky Kids",
        description:
          "Check Boys & girls kids' shoes from Lucky Kids. Wilde selection on style with prices you can afford. Check our deals today!",
      };
    case "teens":
      return {
        title: "Shop Teenager Shoes For Your Kids - Lucky Kids",
        description:
          "Check teenagers' shoes from Lucky Kids. Wilde selection on style with prices you can afford. Check our deals today!",
      };
    case "newArrivals":
      return {
        title: "New Arrival Kids' Shoes - Lucky Kids",
        description:
          "Shop the latest selection of Kids' Shoes at Lucky Kids. Find the latest sneakers, boots, sandals for kids from all ages. Easy return and fast shipping! ",
      };
    case "sale":
      return {
        title: "Big Sales on Kids's Shoes - Lucky Kids",
        description:
          "Find the kids' shoes on sale with big discounts. Cheap price with high quality and style. Hurry! Shoes on sale go fast!",
      };
    default:
      return {
        title: "Shop Kids' Shoes for Boys & Girls - Lucky Kids",
        description:
          "Shop kids shoes with fashion and style, and get great deals and discounts on the latest shoes for kids. Best price guaranteed! ",
      };
  }
}

export function getProductSeoTitle(title?: string) {
  if (title) return title;
  return "Lucky Kids";
}

export function getProductSeoDescription(description?: string) {
  if (description) return description.substring(0, 160);
  return "Lucky Kids";
}
