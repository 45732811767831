import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { getColorStyle } from "../../utils";
import { HStack } from "../HStack";
import { VStack } from "../VStack";

import type { CSSProperties } from "react";

import { IconGroup } from "./IconGroup";
import { LinkGroup } from "./LinkGroup";
import { Menu } from "./Menu";

import "./header.css";

const desktopStyle: Record<string, CSSProperties> = {
  headerMainWrapper: {
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    background: getColorStyle("eggShell"),
    zIndex: 2,
  },
  headerWrapper: {
    justifyContent: "space-between",
    margin: "0 auto",
    width: "100%",
    maxWidth: "1280px",
    padding: "0 1rem",
  },
  logo: {
    width: "9.1875rem",
    height: "3.5rem",
    margin: "1.75rem 0",
    cursor: "pointer",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  headerWrapper: {
    padding: "1rem 1.625rem",
    width: "100%",
    justifyContent: "space-between",
  },
  logo: {
    width: "4.25rem",
    height: "2rem",
  },
};

export function Header() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile, isPad } = useIsMobile();

  const handleLogoClicked = useCallback(() => {
    if (pathname !== "/") {
      navigate("/");
    }
  }, [navigate, pathname]);

  return (
    <VStack
      style={
        isMobile || isPad
          ? desktopStyle.headerMainWrapper
          : desktopStyle.headerMainWrapper
      }
    >
      <HStack
        style={{
          width: "100%",
          height: isMobile || isPad ? "2.25rem" : "3.5rem",
          position: "relative",
          background: getColorStyle("pink"),
          justifyContent: "center",
        }}
      >
        <HStack
          style={{
            padding: isMobile || isPad ? "0.5rem" : "1rem",
            justifyContent: "center",
          }}
        >
          <LatoText
            text="Free Shipping for Orders AED 150+. Shop Now >>"
            color="black"
            weight={isMobile || isPad ? "medium" : "bold"}
            size={isMobile || isPad ? "normalSmall" : "normalMedium"}
          />
        </HStack>
      </HStack>
      <HStack
        style={
          isMobile || isPad
            ? mobileStyle.headerWrapper
            : desktopStyle.headerWrapper
        }
      >
        {(isMobile || isPad) && <Menu />}
        <img
          src="/images/logo.svg"
          style={isMobile || isPad ? mobileStyle.logo : desktopStyle.logo}
          alt=""
          onClick={handleLogoClicked}
        />
        {!(isMobile || isPad) && <LinkGroup />}
        <IconGroup />
      </HStack>
    </VStack>
  );
}
