import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { emailSubscribe } from "../../api/session";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/common/arrow-right.svg";
import { PaytoneOneText } from "../../components";
import { useIsMobile } from "../../hooks";
import { getColorStyle, getFontWeightStyle, validateEmail } from "../../utils";

import type { CSSProperties, ChangeEvent } from "react";

import { HStack } from "../HStack";
import { VStack } from "../VStack";

import { EMAIL_SUBSCRIBE_EXCLUDES_ROUTE } from "./config";

import "./email.css";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "4rem 1rem",
    position: "relative",
    background: getColorStyle("yellow"),
    overflow: "hidden",
  },
  subWrapper: {
    width: "100%",
    maxWidth: "1280px",
    margin: "auto",
  },
  text: { marginBottom: "2rem", textAlign: "left" },
  inputWrapper: {
    width: "45%",
    position: "relative",
  },
  input: {
    width: "100%",
    padding: "1rem 4rem 1rem 2rem",
    borderRadius: "2rem",
    fontSize: "1.25rem",
    fontFamily: "Paytone One",
    color: getColorStyle("black"),
    fontWeight: getFontWeightStyle("normal"),
  },
  icon: {
    position: "absolute",
    width: "1.25rem",
    top: "1.2rem",
    right: "2rem",
    cursor: "pointer",
  },
  error: {
    width: "100%",
    height: "1.25rem",
    marginTop: "1rem",
    textAlign: "left",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "3.5rem 1.5rem",
    position: "relative",
    background: getColorStyle("yellow"),
    overflow: "hidden",
  },
  subWrapper: {
    width: "100%",
  },
  text: { marginBottom: "1rem", textAlign: "left" },
  inputWrapper: {
    width: "100%",
    position: "relative",
  },
  input: {
    width: "100%",
    padding: "0.5rem 3rem 0.5rem 1.5rem",
    borderRadius: "2rem",
    fontSize: "1rem",
    fontFamily: "Paytone One",
    color: getColorStyle("black"),
    fontWeight: getFontWeightStyle("normal"),
  },
  icon: {
    position: "absolute",
    width: "1rem",
    top: "0.7rem",
    right: "1rem",
    cursor: "pointer",
  },
  error: {
    width: "100%",
    height: "1rem",
    marginTop: "0.5rem",
    textAlign: "left",
  },
};
export function EmailSubscribe() {
  const { isMobile, isPad } = useIsMobile();
  const { pathname } = useLocation();
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const handleEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
      if (message) {
        setMessage("");
      }
    },
    [message]
  );

  const handleEmailClick = useCallback(async () => {
    const message = validateEmail(email);
    if (message) {
      setSuccess(false);
      setMessage(message);
      return;
    }

    const response = await emailSubscribe({
      email,
    });
    setSuccess(response.success);
    setMessage(response.msg);
  }, [email]);

  if (EMAIL_SUBSCRIBE_EXCLUDES_ROUTE.includes(pathname))
    return (
      <HStack
        style={{
          width: "100%",
          height: isMobile || isPad ? "5rem" : "10rem",
          background: "transparent",
        }}
      >
        {null}
      </HStack>
    );

  return (
    <HStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
    >
      <VStack
        style={
          isMobile || isPad ? mobileStyle.subWrapper : desktopStyle.subWrapper
        }
      >
        <PaytoneOneText
          text="Subscribe to our newsletters to get a lucky gift"
          color="black"
          weight="normal"
          size={isMobile || isPad ? "normal" : "medium"}
          customStyle={isMobile || isPad ? mobileStyle.text : desktopStyle.text}
        />
        <VStack
          style={
            isMobile || isPad
              ? mobileStyle.inputWrapper
              : desktopStyle.inputWrapper
          }
        >
          <input
            type="email"
            className="email-field"
            value={email}
            placeholder="email"
            onChange={handleEmailChange}
            style={isMobile || isPad ? mobileStyle.input : desktopStyle.input}
          />
          <ArrowRightIcon
            style={isMobile || isPad ? mobileStyle.icon : desktopStyle.icon}
            onClick={handleEmailClick}
          />
          <PaytoneOneText
            text={message}
            color={success ? "green" : "red"}
            weight="normal"
            size="normal"
            customStyle={
              isMobile || isPad ? mobileStyle.error : desktopStyle.error
            }
          />
        </VStack>
      </VStack>
    </HStack>
  );
}
