type HeroImageProps = {
  src: string;
};

export function HeroImage({ src }: HeroImageProps) {
  return (
    <div>
      <img src={src} alt="" style={{ width: "100%" }} />
    </div>
  );
}
