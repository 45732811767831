import { ColorSelector } from "../../components";
import { HStack } from "../../layouts";

import type { FilterItemType, ColorTagType, ColorType } from "../../types";
import type { CSSProperties } from "react";

type Props = {
  options: FilterItemType[];
  filter: boolean[];
  onSelectItem: (idx: number) => () => void;
};

const style: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    flexWrap: "wrap",
    gap: "0.5rem",
  },
  iconWrapper: {
    padding: "0.5rem",
  },
};
export function ColorFilterSection({ options, filter, onSelectItem }: Props) {
  return (
    <HStack style={style.wrapper}>
      {options.map(({ text, color, tag }, index) => (
        <HStack key={text} style={style.iconWrapper}>
          <ColorSelector
            color={color as ColorType}
            isSelected={filter[index]}
            setIsSelected={onSelectItem(index)}
            tag={tag as ColorTagType}
            text={text}
          />
        </HStack>
      ))}
    </HStack>
  );
}
