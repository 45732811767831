import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { editInfo } from "../../api/session";
import { TextInput, Button, PaytoneOneText } from "../../components";
import { useIsLoggedIn } from "../../hooks";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";

import type { ChangeEvent, CSSProperties } from "react";

import { useUserContext } from "../../providers";
import { validateFirstName, validateLastName } from "../../utils";

type FormState = {
  firstName: {
    value: string;
    error: string | undefined;
  };
  lastName: {
    value: string;
    error: string | undefined;
  };
};

const validator: Record<
  keyof FormState,
  (value: string) => string | undefined
> = {
  firstName: validateFirstName,
  lastName: validateLastName,
};

const largeFrameStyle: CSSProperties = {
  width: "100%",
  padding: "5rem 0rem 0rem",
};

const largeFrameMobileStyle: CSSProperties = {
  width: "100%",
  padding: "2rem 0 0",
};

const frameStyle: CSSProperties = {
  margin: "auto",
  padding: "2rem",
  width: "30rem",
};

const frameMobileStyle: CSSProperties = {
  margin: "auto",
  padding: "3rem 1rem 0 1rem",
};

const mainFrameStyle: CSSProperties = {
  paddingTop: "1.5rem",
  gap: "1rem",
};

export function EditAccountInfo() {
  const { user } = useUserContext();
  const { isMobile, isPad } = useIsMobile();
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  const [form, setForm] = useState<FormState>({
    firstName: {
      value: user?.firstName ?? "",
      error: validator.firstName(""),
    },
    lastName: {
      value: user?.lastName ?? "",
      error: validator.lastName(""),
    },
  });

  useEffect(() => {
    if (!isLoggedIn) navigate(-1);
  }, [navigate, isLoggedIn]);

  const isDisabled = useMemo(
    () => Boolean(form.firstName.error || form.lastName.error),
    [form.firstName.error, form.lastName.error]
  );

  const handleInputOnChange = useCallback(
    (key: keyof FormState) =>
      function (e: ChangeEvent<HTMLInputElement>) {
        const errorMessage = validator[key](e.target.value);
        setForm({
          ...form,
          [key]: {
            value: e.target.value,
            error: errorMessage,
          },
        });
      },
    [form]
  );

  const handleSaveChange = useCallback(async () => {
    const token = Cookies.get("session");
    if (
      token &&
      user &&
      (form.firstName.value !== user.firstName ||
        form.lastName.value !== user.lastName)
    ) {
      await editInfo({
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        token: token,
      });
    }
    navigate("/account");
    window.location.reload();
  }, [navigate, form, user]);

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <VStack style={isMobile || isPad ? largeFrameMobileStyle : largeFrameStyle}>
      <VStack style={isMobile || isPad ? frameMobileStyle : frameStyle}>
        <VStack style={{ width: "100%" }}>
          <PaytoneOneText
            text="Edit account information"
            color="black"
            weight="normal"
            size={isMobile || isPad ? "medium" : "large"}
          />
        </VStack>

        <VStack style={mainFrameStyle}>
          <TextInput
            type="text"
            label="FIRST NAME"
            placeholder="John"
            isRequired={false}
            value={form.firstName.value}
            errorMessage={form.firstName.error}
            onChange={handleInputOnChange("firstName")}
          />
          <TextInput
            type="text"
            label="LAST NAME"
            placeholder="Doe"
            isRequired={false}
            value={form.lastName.value}
            errorMessage={form.lastName.error}
            onChange={handleInputOnChange("lastName")}
          />
        </VStack>
        <HStack
          style={{ marginTop: "3rem", gap: "1rem", alignItems: "center" }}
        >
          <Button
            name="cancel"
            onClick={handleCancel}
            isDisabled={false}
            backgroundColor="pink"
          />
          <Button
            name={isMobile || isPad ? "save" : "save change"}
            onClick={handleSaveChange}
            isDisabled={isDisabled}
          />
        </HStack>
      </VStack>
    </VStack>
  );
}
