import { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as AccountIcon } from "../../assets/icons/common/account-icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/common/cross-icon.svg";
import { LatoText, PaytoneOneText } from "../../components";
import { AGE_GROUP_MENU_MAP, HEADER_HEIGHT } from "../../constants";
import { useIsLoggedIn } from "../../hooks";
import { useConstantsContext } from "../../providers";

import type { CSSProperties } from "react";

import { getColorStyle } from "../../utils";
import { Drawer } from "../Drawer";
import { HStack } from "../HStack";
import { VStack } from "../VStack";

const style: Record<string, CSSProperties> = {
  wrapper: {
    textAlign: "left",
  },
  menuImg: {
    width: "1.25rem",
    height: "1.25rem",
    margin: "0.375rem 0",
    cursor: "pointer",
  },
  menuWrapper: {
    position: "fixed",
    width: "100vw",
    height: `calc(100vh - ${HEADER_HEIGHT.mobile})`,
    top: HEADER_HEIGHT.mobile,
    left: "0",
    overflow: "scroll",
    padding: "1rem",
    gap: "3rem",
    background: getColorStyle("eggShell"),
  },
  dropdownWrapper: {},
  sessionWrapper: {
    padding: "0.5rem",
    gap: "0.5rem",
    justifyContent: "left",
  },
};

export function Menu() {
  const [toggle, setToggle] = useState<boolean>(false);
  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();
  const { ageGroup, trends, productType } = useConstantsContext();

  const handleMenuClick = useCallback(() => setToggle(!toggle), [toggle]);

  const handleSessionClick = useCallback(() => {
    if (isLoggedIn) {
      navigate("/logout");
    } else {
      navigate("login");
    }
    handleMenuClick();
  }, [handleMenuClick, isLoggedIn, navigate]);

  const handleItemClick = useCallback(
    (ageGroup: string, text: string) =>
      function () {
        navigate(`/shoes/${ageGroup.toLowerCase()}/${text.toLowerCase()}`);
        handleMenuClick();
      },
    [handleMenuClick, navigate]
  );

  const handleBlogClick = useCallback(() => {
    navigate("/blogs");
    handleMenuClick();
  }, [handleMenuClick, navigate]);

  return (
    <VStack style={style.wrapper}>
      {toggle ? (
        <CrossIcon onClick={handleMenuClick} style={style.menuImg} />
      ) : (
        <img
          src="/icons/home_page/menu-icon.svg"
          style={style.menuImg}
          onClick={handleMenuClick}
          alt=""
        />
      )}
      {toggle && (
        <VStack style={style.menuWrapper}>
          <VStack>
            {Object.keys(ageGroup).map((age) => (
              <VStack style={{ width: "100%", padding: "0.5rem 0" }} key={age}>
                <Drawer
                  headerText={AGE_GROUP_MENU_MAP[age]}
                  headerSize="normal"
                  headerWeight="normal"
                  maxHeight="30rem"
                  headerMargin="0"
                  fontStyle="Paytone One"
                >
                  <VStack
                    style={{
                      width: "100%",
                      padding: "0.5rem 0 0.5rem 0.5rem",
                    }}
                  >
                    <VStack>
                      {Object.keys(trends).map((trend) => (
                        <LatoText
                          key={trend}
                          text={
                            trends[trend] === "All" ? "View All" : trends[trend]
                          }
                          color={
                            trend === "newArrivals"
                              ? "blue"
                              : trend === "sale"
                              ? "red"
                              : "black"
                          }
                          weight="medium"
                          size="normal"
                          customStyle={{
                            textAlign: "left",
                            padding: "0.5rem",
                          }}
                          onClick={handleItemClick(age, trend)}
                        />
                      ))}
                    </VStack>
                    <VStack>
                      {Object.keys(productType).map((type) => (
                        <LatoText
                          key={type}
                          text={productType[type]}
                          color="black"
                          weight="medium"
                          size="normal"
                          customStyle={{
                            textAlign: "left",
                            padding: "0.5rem",
                          }}
                          onClick={handleItemClick(age, type)}
                        />
                      ))}
                    </VStack>
                  </VStack>
                </Drawer>
              </VStack>
            ))}
            <PaytoneOneText
              text="Blog"
              weight="normal"
              size="normal"
              color="black"
              customStyle={{ textAlign: "left", padding: "2rem 0.5rem 0" }}
              onClick={handleBlogClick}
            />
          </VStack>
          <VStack style={{ gap: "0.5rem" }}>
            {isLoggedIn && (
              <Link to="/account">
                <HStack style={style.sessionWrapper} onClick={handleMenuClick}>
                  <PaytoneOneText
                    text="My Account"
                    color="black"
                    weight="normal"
                    size="normal"
                  />
                </HStack>
              </Link>
            )}
            <HStack style={style.sessionWrapper} onClick={handleSessionClick}>
              <AccountIcon />
              <PaytoneOneText
                text={isLoggedIn ? "Log out" : "Sign in"}
                color="black"
                weight="normal"
                size="normal"
              />
            </HStack>
          </VStack>
        </VStack>
      )}
    </VStack>
  );
}
