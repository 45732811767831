import { useCallback } from "react";
import { Link } from "react-router-dom";

import ErrorSign from "../../assets/icons/common/error-sign.svg";
import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { getColorStyle } from "../../utils";

import type { CSSProperties, Dispatch, SetStateAction, LegacyRef } from "react";

type Props = {
  sizes: {
    id: string;
    size: string;
    maxQuantity: string | null;
  }[];
  selectSize: number | null;
  setSelectSize: Dispatch<SetStateAction<number | null>>;
  handleResetQuantity: Dispatch<SetStateAction<number>>;
  sizeAlert: boolean;
  setSizeAlert: Dispatch<SetStateAction<boolean>>;
  innerRef?: LegacyRef<HTMLDivElement>;
};

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: { width: "100%", gap: "1rem", marginBottom: "1.5rem" },
  textWrapper: { justifyContent: "space-between" },
  sizeBoxWrapper: { justifyContent: "left", gap: "1rem", flexWrap: "wrap" },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    gap: "0.5rem",
    marginBottom: "1rem",
  },
  textWrapper: {},
  sizeBoxWrapper: {
    width: "100%",
    gap: "1rem",
    flexWrap: "wrap",
  },
};
export function Sizes({
  sizes,
  selectSize,
  setSelectSize,
  handleResetQuantity,
  sizeAlert,
  setSizeAlert,
  innerRef,
}: Props) {
  const { isMobile, isPad } = useIsMobile();

  const handleSizeClick = useCallback(
    (index: number, maxQuantity: string | null) =>
      function () {
        if (!maxQuantity || maxQuantity === "0") {
          return;
        }
        if (selectSize !== index) {
          setSelectSize(index);
          handleResetQuantity(1);
          setSizeAlert(false);
        }
      },
    [handleResetQuantity, selectSize, setSelectSize, setSizeAlert]
  );

  return (
    <VStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
      innerRef={innerRef}
    >
      <HStack
        style={
          isMobile || isPad ? mobileStyle.textWrapper : desktopStyle.textWrapper
        }
      >
        <LatoText
          text="Size"
          color="black"
          weight={"bold"}
          size={isMobile || isPad ? "normal" : "normalMedium"}
        />
        {!(isMobile || isPad) && (
          <Link to="/size">
            <LatoText
              text="size guide"
              color="blue"
              weight="bold"
              size="normalMedium"
              customStyle={{ cursor: "pointer" }}
            />
          </Link>
        )}
      </HStack>
      {sizeAlert && (
        <HStack
          style={{
            gap: "0.5rem",
            justifyContent: "left",
          }}
        >
          <img src={ErrorSign} alt="" />
          <LatoText
            text="Please choose a size to continue"
            color="error"
            weight="bold"
            size={isMobile || isPad ? "normal" : "normalMedium"}
            customStyle={{ textAlign: "left" }}
          />
        </HStack>
      )}
      <HStack
        style={
          isMobile || isPad
            ? mobileStyle.sizeBoxWrapper
            : desktopStyle.sizeBoxWrapper
        }
      >
        {sizes.map(({ size, id, maxQuantity }, index) => (
          <LatoText
            text={size + " EU"}
            color={!maxQuantity || maxQuantity === "0" ? "border" : "black"}
            weight="normal"
            size="normal"
            key={id}
            customStyle={{
              padding: "0.5rem",
              border: `1px solid ${getColorStyle(
                index === selectSize && maxQuantity && maxQuantity !== "0"
                  ? "black"
                  : "border"
              )}`,
              lineHeight: "160%",
              borderRadius: "4px",
              cursor: !maxQuantity || maxQuantity === "0" ? "" : "pointer",
            }}
            onClick={handleSizeClick(index, maxQuantity)}
          />
        ))}
      </HStack>
      {(isMobile || isPad) && (
        <Link to="/size">
          <LatoText
            text="size guide"
            color="blue"
            weight="bold"
            size="normalMedium"
            customStyle={{
              textAlign: "left",
              marginTop: "0.5rem",
            }}
          />
        </Link>
      )}
    </VStack>
  );
}
