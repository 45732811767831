import { getClientDomain, reportError } from "../utils";

import type { ProductType } from "../types";

export async function getProducts(tag?: string) {
  if (tag) {
    try {
      const response = await fetch(
        `${getClientDomain()}/api/get-products-by-tag/${tag}`
      );
      const products = (await response.json()) as ProductType[];
      return { products };
    } catch (e: unknown) {
      // @ts-expect-error known object
      if (e.message.includes("No products found.")) {
        return { products: [] };
      } else {
        reportError(e as Error, {
          endpoint: "/api/get-products-by-tag/" + tag,
          method: "GET",
        });
        throw new Error(JSON.stringify(e));
      }
    }
  }
  try {
    const response = await fetch(`${getClientDomain()}/api/products`);
    const products = (await response.json()) as ProductType[];
    return { products };
  } catch (e) {
    reportError(e as Error, {
      endpoint: "/api/products/",
      method: "GET",
    });
    throw new Error(JSON.stringify(e));
  }
}
