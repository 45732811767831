import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { getColorStyle } from "../../utils";

export function DisplayingBanner() {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const handleTeenSneakerClick = useCallback(() => {
    navigate("/shoes/teens/sneakers");
  }, [navigate]);
  const handleKidSandalClick = useCallback(() => {
    navigate("/shoes/kids/sandals");
  }, [navigate]);
  const handleKidSneakerClick = useCallback(() => {
    navigate("/shoes/kids/sneakers");
  }, [navigate]);

  return (
    <>
      <VStack
        style={{
          width: "100%",
          background: getColorStyle("yellow"),
          cursor: "pointer",
        }}
      >
        {isMobile ? (
          <img
            src="/images/home_page/teen-collection-mobile.png"
            onClick={handleTeenSneakerClick}
          />
        ) : (
          <img
            src="/images/home_page/teen-collection.png"
            style={{ width: "100%", margin: "auto", maxWidth: "1280px" }}
            onClick={handleTeenSneakerClick}
          />
        )}
      </VStack>
      {isMobile ? (
        <VStack
          style={{
            width: "100%",
            background: getColorStyle("cream"),
          }}
        >
          <img
            src="/images/home_page/kids-collection-mobile-1.png"
            alt=""
            onClick={handleKidSandalClick}
          />
          <img
            src="/images/home_page/kids-collection-mobile-2.png"
            alt=""
            onClick={handleTeenSneakerClick}
          />
          <img
            src="/images/home_page/kids-collection-mobile-3.png"
            alt=""
            onClick={handleKidSneakerClick}
          />
        </VStack>
      ) : (
        <HStack
          style={{
            width: "100%",
            background: getColorStyle("cream"),
            justifyContent: "center",
          }}
        >
          <img
            src="/images/home_page/kids-collection-1.png"
            alt=""
            style={{ width: "50%", maxWidth: "640px", cursor: "pointer" }}
            onClick={handleKidSandalClick}
          />
          <img
            src="/images/home_page/kids-collection-2.png"
            alt=""
            style={{ width: "50%", maxWidth: "640px", cursor: "pointer" }}
            onClick={handleKidSneakerClick}
          />
        </HStack>
      )}

      {isMobile ? (
        <VStack
          style={{
            width: "100%",
          }}
        >
          <img src="/images/home_page/introduce-image-mobile-1.png" />
          <img src="/images/home_page/introduce-image-mobile-2.png" />
        </VStack>
      ) : (
        <VStack
          style={{
            width: "100%",
          }}
        >
          <img
            src="/images/home_page/introduce-image.png"
            style={{ width: "100%", margin: "auto", maxWidth: "1280px" }}
          />
        </VStack>
      )}
      {isMobile ? (
        <img
          src="/images/home_page/about-us-mobile.png"
          style={{ width: "100%" }}
        />
      ) : (
        <VStack
          style={{
            width: "100%",
            background: getColorStyle("cream"),
          }}
        >
          <img
            src="/images/home_page/about-us.png"
            style={{ width: "100%", margin: "auto", maxWidth: "1280px" }}
          />
        </VStack>
      )}
    </>
  );
}
