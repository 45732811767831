import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Button, PaytoneOneText, LatoText } from "../../components";
import { useIsLoggedIn } from "../../hooks";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";

import type { CSSProperties } from "react";

import { useUserContext } from "../../providers";

const largeFrameStyle: CSSProperties = {
  width: "100%",
  padding: "2rem 0rem 2rem",
};

const largeFrameMobileStyle: CSSProperties = {
  width: "100%",
  padding: "2rem 0rem 0rem",
};

const frameStyle: CSSProperties = {
  margin: "auto",
  padding: "2rem",
  width: "30rem",
};

const frameMobileStyle: CSSProperties = {
  margin: "auto",
  padding: "1rem",
  width: "100%",
  gap: "1rem",
};

const mainFrameStyle: CSSProperties = {
  paddingTop: "1.5rem",
  gap: "1rem",
};

export function Logout() {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const isLoggedIn = useIsLoggedIn();
  const { isMobile, isPad } = useIsMobile();

  const handleLogout = useCallback(() => {
    const token = Cookies.get("session");
    if (isLoggedIn) {
      setUser(null);
    }
    if (token) {
      Cookies.set("session", token, { expires: 0 });
    }
    navigate("/");
  }, [isLoggedIn, navigate, setUser]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    navigate(-1);
  };

  if (!user) {
    return null;
  }

  return (
    <VStack style={isMobile || isPad ? largeFrameMobileStyle : largeFrameStyle}>
      <VStack style={isMobile || isPad ? frameMobileStyle : frameStyle}>
        <VStack style={{ width: "100%" }}>
          <PaytoneOneText
            text="Log out"
            color="black"
            weight="normal"
            size={isMobile || isPad ? "medium" : "large"}
          />
        </VStack>
        <VStack style={isMobile || isPad ? frameMobileStyle : mainFrameStyle}>
          <LatoText
            text={`Log out from the account ${user.email}?`}
            color="black"
            weight="normal"
            size="normal"
          />
        </VStack>

        <HStack
          style={{ marginTop: "3rem", gap: "1rem", alignItems: "center" }}
        >
          <Button
            name="cancel"
            onClick={handleCancel}
            isDisabled={false}
            backgroundColor="pink"
          />
          <Button name="log out" onClick={handleLogout} isDisabled={false} />
        </HStack>
      </VStack>
    </VStack>
  );
}
