import * as CryptoJS from "crypto-js";
import Cookies from "js-cookie";

import { getClientDomain, reportError } from "../utils";

import type { CreateSessionResponseType } from "../types";
import type { User } from "../types";
import type { Dispatch, SetStateAction } from "react";

export async function signIn({
  email,
  password,
}: {
  email: string;
  password: string;
}) {
  const encryptedAES = CryptoJS.AES.encrypt(
    password,
    process.env.REACT_APP_SHOPIFY_API_ENCRYPT_TOKEN as string
  ).toString();
  const body = JSON.stringify({
    email: email,
    password: encryptedAES,
  });
  try {
    const response = await fetch(
      `${getClientDomain()}/api/user/email-sign-in`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
      }
    );
    return (await response.json()) as CreateSessionResponseType;
  } catch (e) {
    // FIXME:  throw new Error(JSON.stringify(e));
    reportError(e as Error, {
      endpoint: "/api/user/email-sign-in",
      method: "POST",
      body,
    });
    return "login fail";
  }
}

export async function signInBySession({
  session,
  setUser,
}: {
  session: string;
  setUser: Dispatch<SetStateAction<User | null>>;
}) {
  try {
    const response = await fetch(
      `${getClientDomain()}/api/user/token-sign-in/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: session,
        }),
      }
    );
    const user = await response.json();
    delete user.token;
    setUser(user);
    return;
  } catch (e) {
    Cookies.set("session", session, { expires: 0 });
    // FIXME:  throw new Error(JSON.stringify(e));
    reportError(e as Error, {
      endpoint: "/api/user/token-sign-in/",
      method: "POST",
    });
    return "login fail";
  }
}

export async function signUp({
  firstName,
  lastName,
  email,
  password,
  acceptsMarketing = true,
}: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  acceptsMarketing: boolean;
}) {
  const encryptedPassword = CryptoJS.AES.encrypt(
    password,
    process.env.REACT_APP_SHOPIFY_API_ENCRYPT_TOKEN as string
  ).toString();
  const body = JSON.stringify({
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: encryptedPassword,
    acceptsMarketing: acceptsMarketing,
  });

  try {
    const response = await fetch(`${getClientDomain()}/api/user/create/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    return (await response.json()) as CreateSessionResponseType;
  } catch (e) {
    // FIXME:  throw new Error(JSON.stringify(e));
    reportError(e as Error, {
      endpoint: "/api/user/create/",
      method: "POST",
      body,
    });
    return "register fail";
  }
}

export async function resetPassword({ email }: { email: string }) {
  const body = JSON.stringify({
    email: email,
  });
  try {
    const response = await fetch(
      `${getClientDomain()}/api/send-reset-password-email`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
      }
    );
    return await response.json();
  } catch (e) {
    // FIXME:  throw new Error(JSON.stringify(e));
    reportError(e as Error, {
      endpoint: "/api/send-reset-password-email",
      method: "POST",
      body,
    });
    return "resend fail";
  }
}

export async function editInfo({
  firstName,
  lastName,
  token,
}: {
  firstName: string;
  lastName: string;
  token: string;
}) {
  const body = JSON.stringify({
    token: token.toString(),
    firstName: firstName,
    lastName: lastName,
  });
  try {
    const response = await fetch(`${getClientDomain()}/api/update-user`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    return await response.json();
  } catch (e) {
    // FIXME:  throw new Error(JSON.stringify(e));
    reportError(e as Error, {
      endpoint: "/api/update-user",
      method: "POST",
      body,
    });
    return "edit info fail";
  }
}

export async function emailSubscribe({ email }: { email: string }) {
  try {
    const response = await fetch(`${getClientDomain()}/api/email-subscribe/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
      }),
    });
    const msg = await response.text();
    const subscribed =
      response.status === 200 || msg === "Email has already been taken";
    return {
      success: subscribed,
      msg: subscribed
        ? "You’ve successfully subscribed!"
        : "Email address is invalid",
    };
  } catch (e) {
    reportError(e as Error, {
      endpoint: "/api/email-subscribe/",
      method: "POST",
    });
    return {
      success: false,
      msg: "Subscription unsuccessful",
    };
  }
}
