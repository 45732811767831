import { useContext, useEffect, useState } from "react";

import { getConstants } from "../api";
import { ConstantsContext } from "../context";

import type { ConstantsContextType } from "../context";
import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function ConstantsProvider({ children }: Props) {
  const [context, setContext] = useState<null | ConstantsContextType>(null);

  useEffect(() => {
    void getConstants(setContext);
  }, []);

  if (!context) {
    return null;
  }

  return (
    <ConstantsContext.Provider value={context}>
      {children}
    </ConstantsContext.Provider>
  );
}

export function useConstantsContext() {
  const context = useContext(ConstantsContext);

  if (!context) throw new Error("ConstantsContext is null");
  return context;
}
