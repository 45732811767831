import { LatoText } from "../components";

import type { FontSizeType, FontWeightType } from "../types";
import type { CSSProperties } from "react";

type Props = {
  compareAtPrice?: string;
  currentPrice: string;
  currencyCode: string;
  priceSize: FontSizeType;
  currentPriceWeight: FontWeightType;
  compareAtPriceWeight: FontWeightType;
  customStyle?: CSSProperties;
  isVertical?: boolean;
  alignCenter?: boolean;
};

export function Price({
  compareAtPrice,
  currentPrice,
  currencyCode,
  priceSize,
  currentPriceWeight,
  compareAtPriceWeight,
  customStyle,
  isVertical,
  alignCenter,
}: Props) {
  return compareAtPrice && compareAtPrice > currentPrice ? (
    <div
      style={
        isVertical
          ? {
              gap: "0.75rem",
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
            }
          : alignCenter
          ? {
              gap: "0.75rem",
              display: "flex",
              flexDirection: "row",
              boxSizing: "border-box",
              justifyContent: "center",
            }
          : {
              gap: "0.75rem",
              display: "flex",
              flexDirection: "row",
              boxSizing: "border-box",
            }
      }
    >
      <LatoText
        text={currencyCode + " " + currentPrice}
        color="placeholder"
        weight={compareAtPriceWeight}
        size={priceSize}
        customStyle={{
          textAlign: "left",
          textDecoration: "line-through",
        }}
      />
      <LatoText
        text={currencyCode + " " + compareAtPrice}
        color="red"
        weight={currentPriceWeight}
        size={priceSize}
        customStyle={{
          textAlign: "left",
          ...customStyle,
        }}
      />
    </div>
  ) : (
    <LatoText
      text={currencyCode + " " + currentPrice}
      color="black"
      weight={currentPriceWeight}
      size={priceSize}
      customStyle={
        alignCenter
          ? { ...customStyle }
          : {
              textAlign: "left",
              ...customStyle,
            }
      }
    />
  );
}
