import { Fragment } from "react";

import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { ProductCard, SizeGuideCard } from "../../layouts";
import { useLoadingContext, usePaginationContext } from "../../providers";
import { getColorStyle } from "../../utils";

import type { CSSProperties } from "react";

const style: Record<string, CSSProperties> = {
  wrapper: {
    display: "grid",
    gap: "1rem",
    width: "100%",
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
    gridAutoRows: "max-content",
    background: getColorStyle("eggShell"),
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    display: "grid",
    gap: "1rem",
    width: "100%",
    padding: "1.5rem 0",
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
    gridAutoRows: "max-content",
    background: getColorStyle("eggShell"),
  },
};

export function ProductsList() {
  const { products, productsOrder } = usePaginationContext();
  const { loading } = useLoadingContext();
  const { isMobile } = useIsMobile();

  if (loading) return null;

  if (Object.keys(products).length < 1)
    return (
      <LatoText
        text="No Products Found"
        color="black"
        weight="bold"
        size="large"
      />
    );

  return (
    <div style={isMobile ? mobileStyle.wrapper : style.wrapper}>
      {productsOrder.map((key, index) => {
        const targetIndex = isMobile ? 3 : 2;
        if (index === targetIndex) {
          return (
            <Fragment key={key}>
              <SizeGuideCard />
              <ProductCard {...products[key]} title={key} />
            </Fragment>
          );
        }
        return <ProductCard {...products[key]} title={key} key={key} />;
      })}
    </div>
  );
}
