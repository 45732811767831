import { SEO } from "./SEO";

export function SizeGuideSEO() {
  return (
    <SEO
      title="Size Help  - Lucky Kids"
      description="Not sure what size to order? Check the size guide to find the best size for your kids."
    />
  );
}
