import type {
  ReactNode,
  CSSProperties,
  DetailedHTMLProps,
  HTMLAttributes,
  LegacyRef,
} from "react";

type Props = {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  innerRef?: LegacyRef<HTMLDivElement>;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function HStack({
  children,
  style,
  className,
  innerRef,
  ...props
}: Props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        boxSizing: "border-box",
        ...style,
      }}
      className={className}
      ref={innerRef}
      {...props}
    >
      {children}
    </div>
  );
}
