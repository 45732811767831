import { createContext } from "react";

import type { ProductsListType, ProductType } from "../types";
import type { Dispatch, SetStateAction } from "react";

export type FilterStateType = Record<string, boolean[]>;

export type FilterContextType = {
  sort: number;
  setSort: (index: number) => void;
  filter: FilterStateType;
  setFilter: (filter: FilterStateType) => void;
  resetFilter: () => void;
  productsCount: number;
  luckyChoiceBabies?: ProductType;
  luckyChoiceToddlers?: ProductType;
  luckyChoiceKids?: ProductType;
  luckyChoiceTeens?: ProductType;
};

export type PaginationContextType = {
  products: ProductsListType;
  index: number;
  setIndex: Dispatch<SetStateAction<number>>;
  limit: number;
  setLimit: Dispatch<SetStateAction<number>>;
  totalPages: number;
  productsOrder: string[];
};

export const FilterContext = createContext<FilterContextType | null>(null);
export const PaginationContext = createContext<PaginationContextType | null>(
  null
);
