import { useMemo } from "react";

import {
  SaleTag,
  KidsKingTag,
  LuckyChoiceTag,
  NewArrivals,
} from "../components";

import { HStack } from "./HStack";

import type { TagType } from "../types";

type Props = {
  tags: TagType[];
};

export function TagGroup({ tags }: Props) {
  const tempTags = useMemo(() => {
    const TAGS_ORDER: TagType[] = [
      "luckyChoice",
      "sale",
      "newArrivals",
      "kidsKing",
    ];
    const result = [] as TagType[];

    TAGS_ORDER.forEach((tag) => {
      if (tags.includes(tag) && result.length < 2) result.push(tag);
    });

    return result;
  }, [tags]);
  return (
    <HStack style={{ width: "100%", justifyContent: "right", gap: "0.5rem" }}>
      {tempTags.map((tag) =>
        // desc: only display first two tag
        getTag(tag)
      )}
    </HStack>
  );
}

function getTag(tag: TagType) {
  switch (tag) {
    case "sale":
      return <SaleTag key={tag} />;
    case "newArrivals":
      return <NewArrivals key={tag} />;
    case "luckyChoice":
      return <LuckyChoiceTag key={tag} />;
    case "kidsKing":
      return <KidsKingTag key={tag} />;
    case undefined:
      return null;
  }
}
