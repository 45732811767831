import { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";

import { getProducts, searchProducts } from "../api";
import { FilterContext, PaginationContext } from "../context";
import {
  ageGroupMapping,
  filterSortProducts,
  getFilterState,
  getProductsByPage,
  getTotalPages,
  organizeProducts,
} from "../utils";

import type { FilterStateType } from "../context";
import type { ProductType, ProductsListType } from "../types";
import type { ReactNode } from "react";

import { useLoadingContext } from "./LoadingProvider";

async function initProducts(
  setProducts: (products: ProductType[]) => void,
  tag?: string
) {
  const { products } = await getProducts(tag);
  setProducts(products);
}

async function initSearchProducts(
  setProducts: (products: ProductType[]) => void,
  content?: string
) {
  const { products } = await searchProducts(content);
  setProducts(products);
}

type PaginationProviderProps = {
  children: ReactNode;
  products: ProductsListType;
  productsOrder: string[];
};

function PaginationProvider({
  children,
  products,
  productsOrder,
}: PaginationProviderProps) {
  const [index, setIndex] = useState<number>(0);
  const [limit, setLimit] = useState<number>(16);
  const paginationContext = useMemo(
    () => ({
      index,
      setIndex,
      limit,
      setLimit,
      totalPages: getTotalPages(Object.keys(products).length, limit),
      products: getProductsByPage(index, limit, products),
      productsOrder,
    }),
    [index, limit, products, productsOrder]
  );

  return (
    <PaginationContext.Provider value={paginationContext}>
      {children}
    </PaginationContext.Provider>
  );
}

type FilterProviderProps = {
  children: ReactNode;
  products: ProductType[];
};
export function FilterProvider({ children, products }: FilterProviderProps) {
  const { type } = useParams();

  const [sort, setSort] = useState<number>(0);

  const [filter, setFilter] = useState<FilterStateType>(
    getFilterState(type || "")
  );

  useEffect(() => {
    setFilter(getFilterState(type || ""));
  }, [type]);

  const resetFilter = useCallback(() => {
    setFilter(getFilterState(""));
  }, []);

  const luckyChoice = useMemo(
    () =>
      products.find(
        ({ tags }) =>
          tags.includes("luckyChoiceBabies") ||
          tags.includes("luckyChoiceToddlers") ||
          tags.includes("luckyChoiceKids") ||
          tags.includes("luckyChoiceTeens")
      ),
    [products]
  );

  const luckyChoiceBabies = useMemo(
    () => products.find(({ tags }) => tags.includes("luckyChoiceBabies")),
    [products]
  );

  const luckyChoiceToddlers = useMemo(
    () => products.find(({ tags }) => tags.includes("luckyChoiceToddlers")),
    [products]
  );

  const luckyChoiceKids = useMemo(
    () => products.find(({ tags }) => tags.includes("luckyChoiceKids")),
    [products]
  );

  const luckyChoiceTeens = useMemo(
    () => products.find(({ tags }) => tags.includes("luckyChoiceTeens")),
    [products]
  );

  const filteredSortedProducts = useMemo(
    () => [...filterSortProducts(filter, sort, products)],
    [filter, products, sort]
  );

  const filteredProducts = useMemo(
    () => organizeProducts(filteredSortedProducts, undefined, sort), // pass undefined for the parameter "first"
    [filteredSortedProducts, sort]
  );

  const context = useMemo(
    () => ({
      sort,
      setSort,
      filter,
      setFilter,
      resetFilter,
      productsCount: filteredSortedProducts.length,
      luckyChoice,
      luckyChoiceBabies,
      luckyChoiceToddlers,
      luckyChoiceKids,
      luckyChoiceTeens,
    }),
    [
      filter,
      filteredSortedProducts.length,
      luckyChoice,
      resetFilter,
      sort,
      luckyChoiceBabies,
      luckyChoiceToddlers,
      luckyChoiceKids,
      luckyChoiceTeens,
    ]
  );

  return (
    <FilterContext.Provider value={context}>
      <PaginationProvider
        products={filteredProducts.products}
        productsOrder={filteredProducts.productsOrder}
      >
        {children}
      </PaginationProvider>
    </FilterContext.Provider>
  );
}

type Props = {
  children: ReactNode;
};
export function ProductsProvider({ children }: Props) {
  const { ageGroup } = useParams();
  const [products, setProducts] = useState<ProductType[]>([]);
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    setLoading(true);
    const setNewProducts = (products: ProductType[]) => {
      setProducts(products);
      setLoading(false);
    };
    void initProducts(setNewProducts, ageGroupMapping(ageGroup));
  }, [ageGroup, setLoading]);

  return <FilterProvider products={products}>{children}</FilterProvider>;
}

export function SearchProductsProvider({ children }: Props) {
  const { content } = useParams();
  const [products, setProducts] = useState<ProductType[]>([]);

  useEffect(() => {
    const setNewProducts = (products: ProductType[]) => {
      setProducts(products);
    };
    void initSearchProducts(setNewProducts, content);
  }, [content]);

  return <FilterProvider products={products}>{children}</FilterProvider>;
}

export function useFilterContext() {
  const context = useContext(FilterContext);

  if (!context) throw new Error("ProductsContext is null");
  return context;
}

export function usePaginationContext() {
  const context = useContext(PaginationContext);

  if (!context) throw new Error("ProductsContext is null");
  return context;
}
