import { getClientDomain, reportError } from "../utils";

import type { ProductType } from "../types";

export async function searchProducts(content?: string) {
  if (content) {
    const body = JSON.stringify({
      search: content,
    });
    try {
      const response = await fetch(`${getClientDomain()}/api/search-bar/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
      });
      const products = (await response.json()) as ProductType[];
      return { products };
    } catch (e: unknown) {
      // @ts-expect-error known object
      if (e.message.includes("No products found.")) {
        return { products: [] };
      } else {
        reportError(e as Error, {
          endpoint: "/api/search-bar/",
          method: "POST",
          body,
        });
        throw new Error(JSON.stringify(e));
      }
    }
  }
  return { products: [] };
}
