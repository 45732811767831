import { LatoText, PaytoneOneText } from "../components";
import { useIsMobile } from "../hooks";
import { HStack } from "../layouts";
import { VStack } from "../layouts";
import { getColorStyle } from "../utils";

import type { CSSProperties } from "react";

const TEXT = "Oops, something went wrong";
const ToHomePage = "back to homepage";

const handleClick = () => {
  window.location.href = "/";
};

const errorFrameStyle: CSSProperties = {
  width: "100%",
  padding: "5rem 5rem 7.5rem",
  gap: "1.5rem",
  background: getColorStyle("white"),
};

const errorFrameStyle2: CSSProperties = {
  alignItems: "center",
  gap: "2rem",
  width: "100%",
  maxWidth: "1280px",
  margin: "auto",
};

const errorImgStyle: CSSProperties = {
  alignItems: "center",
  width: "17rem",
};

const errorWordWrapperStyle: CSSProperties = {
  alignItems: "center",
  gap: "2rem",
};

const buttonStyle: CSSProperties = {
  alignItems: "center",
  padding: "0.625rem 1.5rem 0.75rem",
  gap: "2rem",
  backgroundColor: getColorStyle("yellow"),
  border: "0.09375rem solid" + getColorStyle("yellow"),
  borderRadius: "3rem",
  cursor: "pointer",
};

const errorFrameStyleM: CSSProperties = {
  alignItems: "center",
  width: "100%",
};

const errorFrameStyleM2: CSSProperties = {
  alignItems: "center",
  padding: "2rem 1.5rem 4rem 1.5rem",
  gap: "2rem",
  width: "100%",
  maxWidth: "23.75rem",
};

const errorImgFrameStyleM: CSSProperties = {
  alignItems: "center",
  padding: "1rem",
  gap: "1.5rem",
  width: "12rem",
  height: "17rem",
};

const errorImgStyleM: CSSProperties = {
  alignItems: "center",
  width: "10rem",
};

const errorWordWrapperStyleM: CSSProperties = {
  alignItems: "center",
  gap: "3rem",
  width: "16.375rem",
};

const buttonStyleM: CSSProperties = {
  alignItems: "center",
  padding: "0.625rem 1.5rem 0.75rem",
  gap: "2rem",
  backgroundColor: getColorStyle("yellow"),
  border: "0.09375rem solid" + getColorStyle("yellow"),
  borderRadius: "2rem",
};

export function Error() {
  const { isMobile } = useIsMobile();
  return (
    <HStack style={isMobile ? errorFrameStyleM : errorFrameStyle}>
      <VStack style={isMobile ? errorFrameStyleM2 : errorFrameStyle2}>
        <HStack style={isMobile ? errorImgFrameStyleM : errorImgStyle}>
          <img
            src="/images/errorPageImage.svg"
            alt=""
            style={isMobile ? errorImgStyleM : errorImgStyle}
          />
        </HStack>
        <VStack
          style={isMobile ? errorWordWrapperStyleM : errorWordWrapperStyle}
        >
          <LatoText
            text={TEXT}
            color="black"
            weight="bold"
            size={isMobile ? "normalMedium" : "larger"}
          />
          <HStack
            onClick={handleClick}
            style={isMobile ? buttonStyleM : buttonStyle}
          >
            <PaytoneOneText
              text={ToHomePage}
              color="black"
              weight="normal"
              size={isMobile ? "normal" : "medium"}
            />
          </HStack>
        </VStack>
      </VStack>
    </HStack>
  );
}
