import { getColorStyle, getFontWeightStyle, getFontSizeStyle } from "../utils";

import type { ColorType, FontWeightType, FontSizeType } from "../types";
import type { CSSProperties, DetailedHTMLProps, HTMLAttributes } from "react";

type Props = {
  text: string;
  color: ColorType;
  weight: FontWeightType;
  size: FontSizeType;
  fontFamily: "Lato" | "Paytone One";
  customStyle?: CSSProperties;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const textStyle = ({
  color,
  weight,
  size,
  fontFamily,
}: Omit<Props, "text" | "customStyle" | "onClick">): CSSProperties => ({
  color: getColorStyle(color),
  fontWeight: getFontWeightStyle(weight),
  fontSize: getFontSizeStyle(size),
  fontFamily,
  lineHeight: "120%",
  textAlign: "center",
});

export function Text({
  text,
  color,
  size,
  fontFamily,
  weight,
  customStyle,
  ...props
}: Props) {
  return (
    <div
      style={{
        ...textStyle({ color, size, fontFamily, weight }),
        ...customStyle,
      }}
      {...props}
    >
      {text}
    </div>
  );
}

export function LatoText(props: Omit<Props, "fontFamily">) {
  return <Text fontFamily="Lato" {...props} />;
}

export function PaytoneOneText(props: Omit<Props, "fontFamily">) {
  return <Text fontFamily="Paytone One" {...props} />;
}
