import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";

import { resetPassword } from "../../api/session";
import WarningSign from "../../assets/icons/common/warning-sign.svg";
import { TextInput, Button, PaytoneOneText, LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";

import type { ChangeEvent, CSSProperties } from "react";

import { useLoadingContext } from "../../providers";
import { validateEmail } from "../../utils";

type FormState = {
  email: {
    value: string;
    error: string | undefined;
  };
};

const validator: Record<
  keyof FormState,
  (value: string) => string | undefined
> = {
  email: validateEmail,
};

const largeFrameStyle: CSSProperties = {
  width: "100%",
  padding: "5rem 0rem 0rem",
};

const largeFrameMobileStyle: CSSProperties = {
  width: "100%",
};

const frameStyle: CSSProperties = {
  margin: "auto",
  padding: "2rem",
  width: "35rem",
  gap: "4rem",
};

const frameMobileStyle: CSSProperties = {
  margin: "auto",
  padding: "3rem 1rem 0 1rem",
  width: "100%",
  gap: "2rem",
};

const mainFrameStyle: CSSProperties = {
  gap: "1rem",
};

export function ResetPassword() {
  const navigate = useNavigate();
  const { isMobile, isPad } = useIsMobile();
  const { setLoading } = useLoadingContext();

  const [form, setForm] = useState<FormState>({
    email: {
      value: "",
      error: validator.email(""),
    },
  });

  const isDisabled = useMemo(
    () => Boolean(form.email.error),
    [form.email.error]
  );

  const handleInputOnChange = useCallback(
    (key: keyof FormState) =>
      function (e: ChangeEvent<HTMLInputElement>) {
        const errorMessage = validator[key](e.target.value);
        setForm({
          ...form,
          [key]: {
            value: e.target.value,
            error: errorMessage,
          },
        });
      },
    [form]
  );

  const handleButtonClick = useCallback(async () => {
    setLoading(true);
    await resetPassword({
      email: form.email.value,
    });
    setLoading(false);
    navigate("/login/reset");
  }, [form.email.value, navigate, setLoading]);

  return (
    <VStack style={isMobile || isPad ? largeFrameMobileStyle : largeFrameStyle}>
      <VStack style={isMobile || isPad ? frameMobileStyle : frameStyle}>
        <PaytoneOneText
          text="Reset your password"
          color="black"
          weight="normal"
          size={isMobile || isPad ? "medium" : "large"}
        />
        <VStack style={mainFrameStyle}>
          <HStack
            style={{
              gap: "0.5rem",
              justifyContent: "center",
            }}
          >
            <img src={WarningSign} alt="" />
            <LatoText
              text="We will send you an email to reset your password"
              color="black"
              weight="normal"
              size="normal"
            />
          </HStack>
          <TextInput
            type="text"
            label="EMAIL"
            placeholder="johndoe@gmail.com"
            isRequired={true}
            value={form.email.value}
            errorMessage={form.email.error}
            onChange={handleInputOnChange("email")}
          />
        </VStack>
        <VStack style={{ gap: "1rem" }}>
          <Button
            name="send reset email"
            onClick={handleButtonClick}
            isDisabled={isDisabled}
          />
          <Link to="/login">
            <VStack style={{ cursor: "pointer" }}>
              <LatoText
                text="Cancel"
                color="red"
                weight="bold"
                size="normalMedium"
              />
            </VStack>
          </Link>
        </VStack>
      </VStack>
    </VStack>
  );
}
