import { useIsMobile } from "../hooks";
import { useLoadingContext } from "../providers";

import { HStack } from "./HStack";
import { VStack } from "./VStack";

import type { CSSProperties } from "react";

const style: Record<string, CSSProperties> = {
  mainWrapper: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 999,
    justifyContent: "center",
    background: "#ffffff82",
  },
  innerWrapper: {
    justifyContent: "center",
    width: "100%",
  },
};

export function Loading() {
  const { loading } = useLoadingContext();
  const { isMobile } = useIsMobile();

  if (loading) {
    return (
      <VStack style={style.mainWrapper}>
        <HStack style={style.innerWrapper}>
          <img
            src="/loading.gif"
            alt=""
            style={{ width: isMobile ? "75%" : "30%" }}
          />
        </HStack>
      </VStack>
    );
  }
  return null;
}
