import cloneDeep from "lodash/cloneDeep";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import { Checkbox } from "../../components";
import { FILTER } from "../../constants";
import { Drawer, VStack } from "../../layouts";
import { useFilterContext } from "../../providers";

import type { FilterItemType } from "../../types";

import { ColorFilterSection } from "./ColorFilterSection";

export function FilterMenu() {
  const { filter, setFilter } = useFilterContext();

  const handleOnSelect = useCallback(
    (key: string) =>
      function (idx: number) {
        return function () {
          const newFilter = cloneDeep(filter);
          const value = newFilter[key][idx];
          newFilter[key][idx] = !value;
          setFilter(newFilter);
        };
      },
    [filter, setFilter]
  );

  return (
    <Drawer
      headerText="Filter By"
      headerSize="normalMedium"
      headerWeight="bold"
      maxHeight="1280px"
      isBorder
      isPreShow
    >
      <VStack style={{ gap: "1rem" }}>
        {Object.keys(FILTER).map((key) => {
          const { options, height } = FILTER[key];
          return (
            <FilterItem
              key={key}
              title={key}
              options={options}
              filter={filter[key]}
              height={height}
              onSelectItem={handleOnSelect(key)}
            />
          );
        })}
      </VStack>
    </Drawer>
  );
}

type FilterItemProps = {
  title: string;
  options: FilterItemType[];
  filter: boolean[];
  height: string;
  onSelectItem: (idx: number) => () => void;
};
function FilterItem({
  title,
  options,
  filter,
  height,
  onSelectItem,
}: FilterItemProps) {
  const { ageGroup } = useParams();
  const isNewArrivals = useMemo(() => ageGroup === "newArrivals", [ageGroup]);
  const isSale = useMemo(() => ageGroup === "sale", [ageGroup]);
  const hasAgeGroup = useMemo(
    () =>
      ageGroup === "babies" ||
      ageGroup === "toddlers" ||
      ageGroup === "kids" ||
      ageGroup === "teens",
    [ageGroup]
  );

  if (hasAgeGroup && title === "AGE GROUP") return null;
  return (
    <Drawer
      headerText={title}
      headerSize="normalSmall"
      headerWeight="medium"
      headerMargin="0px"
      maxHeight={height}
      isPreShow
    >
      {title === "COLOR" && (
        <ColorFilterSection
          options={options}
          filter={filter}
          onSelectItem={onSelectItem}
        />
      )}
      {title !== "COLOR" &&
        options.map(({ text, color }, idx) => {
          if (isSale && text === "Sale") return null;
          if (isNewArrivals && text === "New Arrivals") return null;
          return (
            <Checkbox
              key={text}
              isCheck={filter[idx]}
              setIsCheck={onSelectItem(idx)}
              label={text}
              color={color}
            />
          );
        })}
    </Drawer>
  );
}
