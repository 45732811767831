import { getClientDomain, reportError } from "../utils";

import type { SingleBlogContextType } from "../context";

export async function getBlogs() {
  try {
    const response = await fetch(`${getClientDomain()}/api/get-blogs`);
    const blogs = await response.json();
    if (typeof blogs !== "undefined") {
      const filteredBlog = [];
      for (const blog of blogs) {
        if (blog.fields.shortDescription) {
          filteredBlog.push(blog);
        }
      }
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      const structuredBlog = filteredBlog.map((blog: any) => ({
        title: blog.fields.title,
        publishedDate: blog.fields.publishedDate,
        shortDescription: blog.fields.shortDescription,
        src: blog.fields.featuredImage.fields.file.url,
        slug: blog.fields.slug,
        id: blog.sys.id,
      }));
      return structuredBlog as SingleBlogContextType[];
    }
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/get-blogs/",
      method: "GET",
    });
    throw new Error(JSON.stringify(e));
  }
}

export async function getBlogById(id: string) {
  try {
    const response = await fetch(`${getClientDomain()}/api/get-blogs`);
    const blogs = await response.json();
    if (typeof blogs !== "undefined") {
      for (const blog of blogs) {
        if (blog.sys.id === id) {
          return blog;
        }
      }
    }
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/get-blogs/",
      method: "GET",
    });
    throw new Error(JSON.stringify(e));
  }
}
