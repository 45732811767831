import { useCallback } from "react";

import { ReactComponent as CheckboxActiveIcon } from "../assets/icons/common/check-box-active.svg";
import { ReactComponent as CheckboxInActiveIcon } from "../assets/icons/common/check-box-inactive.svg";

import { LatoText } from "./Text";

import type { ColorType } from "../types";
import type { CSSProperties, Dispatch, SetStateAction } from "react";

type Props = {
  isCheck: boolean;
  setIsCheck: Dispatch<SetStateAction<boolean>>;
  label: string;
  color?: ColorType;
};

const style: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "0.5rem 1rem",
    display: "flex",
    cursor: "pointer",
  },
  icon: { width: "1rem", height: "1rem", margin: "auto 0.375rem auto 0" },
  label: { textAlign: "left" },
};

export function Checkbox({
  isCheck,
  setIsCheck,
  label,
  color = "black",
}: Props) {
  const handleOnClick = useCallback(() => {
    setIsCheck(!isCheck);
  }, [isCheck, setIsCheck]);

  return (
    <div onClick={handleOnClick} style={style.wrapper}>
      {isCheck ? (
        <CheckboxActiveIcon style={style.icon} />
      ) : (
        <CheckboxInActiveIcon style={style.icon} />
      )}
      <LatoText
        text={label}
        color={color}
        weight="normal"
        size="normal"
        customStyle={style.label}
      />
    </div>
  );
}
