import { LatoText, PaytoneOneText } from "../components";
import { VStack } from "../layouts";
import { useMobileViewContext } from "../providers";

import type { CSSProperties } from "react";

const desktopStyle: Record<string, CSSProperties> = {
  largeFrame: {
    width: "100%",
    alignItems: "center",
  },
  mainFrame: {
    width: "100%",
    maxWidth: "1280px",
    padding: "5rem 1rem",
    gap: "1rem",
    textAlign: "left",
  },
  wrapper: {
    height: "100%",
    textAlign: "left",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  largeFrame: {
    width: "100%",
  },
  mainFrame: {
    width: "100%",
    padding: "3rem 1rem",
    gap: "1rem",
    textAlign: "left",
  },
  wrapper: {
    height: "100%",
    textAlign: "left",
  },
};

const MEASURE_TEXT = [
  "Making your own foot measuring sheet is easy, follow the instructions below to create your own sheet and use it to measure your child's feet:",
  "Don’t have a printer? No worries, making your own foot measuring sheet is easy, follow the instructions below to create your own sheet and use it to measure your child's feet:",
  "Step 1. Find a vertical wall and place a piece of paper at the foot of the wall for measurement.",
  "Step 2. Have the child stand against the wall with the largest foot on the paper.",
  "Step 3. Trace both feet and measure the length from heel to longest toe and the width at the widest part.",
  "Step 4. Find the size which fits you best on the size guide below.",
];

const SIZE_CHART = [
  {
    text: "Babies (0-1 year old)",
    src: "/images/size_guide/chart0.svg",
    imgWidth: "35rem",
    width: "35rem",
  },
  {
    text: "Toddlers (1-4 year old)",
    src: "/images/size_guide/chart1.svg",
    imgWidth: "100%",
    width: "75rem",
  },
  {
    text: "Kids (4-13 year old)",
    src: "/images/size_guide/chart2.svg",
    imgWidth: "100%",
    width: "75rem",
  },
  {
    text: "Teens (13-18 year old) ",
    src: "/images/size_guide/chart3.svg",
    imgWidth: "35rem",
    width: "35rem",
  },
];

const GUARANTEE_TEXT = [
  "We understand how important it is to find the perfect fit for your child’s footwear. Even with our best efforts to provide accurate size charts and measurements, every child’s feet are unique in terms of length and width. Therefore, we cannot guarantee that our shoes will fit perfectly every time. However, we want to assure you that we stand behind our products and are committed to making sure you are satisfied with your purchase.",
  "If you receive your shoes and they are not the right fit for your child, we offer hassle-free exchange and refund policies. You can simply send us an email request or visit one of our available stores to arrange an exchange as soon as possible. Our team will work with you to find the right size and fit for your child’s feet. If an exchange is not possible, we will provide a refund, subject to our refund policy.",
  "We believe that our customers deserve the best shopping experience possible, which is why we offer these policies to ensure your satisfaction. Thank you for choosing our brand, and we look forward to helping you find the perfect pair of shoes for your child.",
];

export function SizeGuide() {
  const { isMobile, isPad } = useMobileViewContext();

  return (
    <VStack
      style={
        isMobile || isPad ? mobileStyle.largeFrame : desktopStyle.largeFrame
      }
    >
      <VStack
        style={
          isMobile || isPad ? mobileStyle.mainFrame : desktopStyle.mainFrame
        }
      >
        {SIZE_CHART.map(({ text, src, imgWidth, width }) => (
          <VStack style={{ width: "100%", gap: "0.5rem" }}>
            <PaytoneOneText
              text={text}
              color="black"
              weight="normal"
              size={isMobile || isPad ? "normalMedium" : "medium"}
              customStyle={{ textAlign: "left" }}
            />
            <VStack
              style={
                isMobile || isPad
                  ? {
                      position: "relative",
                      overflowX: "scroll",
                    }
                  : { position: "relative" }
              }
            >
              <VStack
                style={
                  isMobile || isPad
                    ? { ...mobileStyle.wrapper, width: width }
                    : desktopStyle.wrapper
                }
              >
                <img
                  src={src}
                  style={{ maxHeight: "12rem", width: imgWidth }}
                />
              </VStack>
            </VStack>
            <LatoText
              text="Swipe horizontally to see more"
              color="placeholder"
              weight="normal"
              size="normalSmall"
              customStyle={{ textAlign: "left", paddingLeft: "1rem" }}
            />
          </VStack>
        ))}
        <VStack style={{ paddingBottom: "1rem" }}>
          <PaytoneOneText
            text="Easy ways to accurately measure foot at home"
            color="black"
            weight="normal"
            size={isMobile || isPad ? "medium" : "large"}
            customStyle={{ textAlign: "left" }}
          />
        </VStack>
        <VStack style={{ width: "100%", gap: "1rem" }}>
          {MEASURE_TEXT.map((text) => (
            <VStack>
              <LatoText
                text={text}
                color="black"
                weight="normal"
                size="normal"
                customStyle={{ textAlign: "left", lineHeight: "25.6px" }}
              />
            </VStack>
          ))}
        </VStack>

        <VStack style={{ gap: "1rem" }}>
          <PaytoneOneText
            text="Guaranteed Fit"
            color="black"
            weight="normal"
            size={isMobile || isPad ? "normalMedium" : "medium"}
            customStyle={{ textAlign: "left" }}
          />
          <VStack style={{ width: "100%", gap: "1rem" }}>
            {GUARANTEE_TEXT.map((text) => (
              <VStack>
                <LatoText
                  text={text}
                  color="black"
                  weight="normal"
                  size="normal"
                  customStyle={{ textAlign: "left", lineHeight: "25.6px" }}
                />
              </VStack>
            ))}
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}
