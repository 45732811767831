import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getProduct } from "../../api/product";
import { LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, Slides, VStack, LinkHeader, Price } from "../../layouts";

import type { SlideType } from "../../layouts";

import { useLoadingContext } from "../../providers";

import type { ColorType, ProductDetailType } from "../../types";

import { SEO } from "../../router/SEO/SEO";

import type { CSSProperties } from "react";

import {
  getColorStyle,
  getAgeGroup,
  getProductType,
  getProductSeoTitle,
  getProductSeoDescription,
} from "../../utils";

import { Detail } from "./Detail";
import { FeaturesBanner } from "./FeaturesBanner";
import { HeroImage } from "./HeroImage";
import { ImagesBanner } from "./ImagesBanner";
import { Reviews } from "./Reviews";
import { ReviewsBanner } from "./ReviewsBanner";
import { SuggestProducts } from "./SuggestProducts";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: { width: "100%" },
  linkHeaderWrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "1rem 1rem",
    margin: "auto",
  },
  innerWrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "3rem 1rem",
    margin: "auto",
    gap: "3rem",
  },
  heroImg: {
    flex: 0.5,
    alignItems: "center",
  },
  detailWrapper: {
    flex: 0.5,
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    background: getColorStyle("eggShell"),
  },
  linkHeaderWrapper: {
    width: "100%",
    padding: "0.5rem 1.5rem",
  },
  innerWrapper: {
    flexDirection: "column",
    width: "100%",
    padding: "1.5rem",
    gap: "1rem",
  },
  heroImg: {
    background: getColorStyle("white"),
    boxShadow: "0px 8px 16px rgba(171, 171, 171, 0.2)",
    borderRadius: "16px",
    overflow: "hidden",
    padding: "1rem",
  },
  detailWrapper: {},
};

export function Product() {
  const { productId, title } = useParams();
  const { setLoading } = useLoadingContext();
  const navigate = useNavigate();
  const { isMobile, isPad } = useIsMobile();

  const [products, setProduct] = useState<ProductDetailType[]>([]);

  if (!productId || !title) {
    navigate("/404");
    return null;
  }

  const thumbnail = useMemo(
    () =>
      products.map((item) => ({
        id: item.id,
        image: item.images[0],
      })),
    [products]
  );

  const product = useMemo(
    () => products.find((item) => item.id.includes(productId)),
    [productId, products]
  );

  const mobileSlides = useMemo(
    () =>
      product
        ? product?.images.map(
            (image) =>
              ({
                src: { desktop: image, mobile: image },
                backgroundColor: "white" as ColorType,
                text: "",
                link: "",
                height: "40vh",
              } as SlideType)
          )
        : [],
    [product]
  );

  const suggestProducts = useMemo(() => {
    const temp = products[0];
    if (!temp) return null;

    const ageGroup = getAgeGroup(temp.tags);
    const productType = getProductType(temp.tags);
    const productsId = products.map((pro) => pro.id);

    if (!ageGroup || !productType || productsId.length === 0) return null;

    return {
      ageGroup,
      productType,
      productsId,
    };
  }, [products]);

  const links = useMemo(() => {
    const temp = products[0];
    if (!temp) return null;

    const ageGroup = getAgeGroup(temp.tags);
    if (!ageGroup) return null;
    return [
      { text: "HOME", link: "/" },
      {
        text: ageGroup.toUpperCase(),
        link: `/shoes/${ageGroup}`,
      },
    ];
  }, [products]);

  useEffect(() => {
    setLoading(true);
    function callback(products: ProductDetailType[]) {
      setProduct(products);
      setTimeout(() => setLoading(false), 500);
    }

    void getProduct(title, callback);
  }, [productId, setLoading, title]);

  if (!product) {
    return <div />;
  }

  return (
    <>
      <VStack
        style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
      >
        <VStack
          style={
            isMobile || isPad
              ? mobileStyle.linkHeaderWrapper
              : desktopStyle.linkHeaderWrapper
          }
        >
          <LinkHeader links={links} />
        </VStack>
        <HStack
          style={
            isMobile || isPad
              ? mobileStyle.innerWrapper
              : desktopStyle.innerWrapper
          }
        >
          {isMobile || isPad ? (
            <VStack>
              <LatoText
                text={product.title}
                color="black"
                weight={isMobile || isPad ? "normal" : "bold"}
                size={isMobile || isPad ? "medium" : "large"}
                customStyle={{ textAlign: "left", marginBottom: "1rem" }}
              />
              <Price
                currentPrice={product.price}
                compareAtPrice={product.compareAtPrice}
                currencyCode="AED"
                priceSize={isMobile || isPad ? "medium" : "large"}
                currentPriceWeight="bold"
                compareAtPriceWeight="bold"
                customStyle={{
                  textAlign: "left",
                  marginBottom: isMobile || isPad ? "1rem" : "1.75rem",
                }}
              />
              <HStack style={mobileStyle.heroImg}>
                <Slides
                  slides={mobileSlides}
                  showArrows={false}
                  showThumbs={false}
                  limitHeight={true}
                />
              </HStack>
            </VStack>
          ) : (
            <HStack style={desktopStyle.heroImg}>
              <HeroImage src={product.images[0]} />
            </HStack>
          )}
          <Detail
            title={product.title}
            description={product.descriptionHtml}
            colorDescription={product.colorDescription}
            variants={product.variants}
            price={product.price}
            compareAtPrice={product.compareAtPrice}
            thumbnail={thumbnail}
          />
        </HStack>
        {!(isMobile || isPad) && <ImagesBanner images={product.images} />}
        <FeaturesBanner />
        <ReviewsBanner />
        <HStack>
          <Reviews />
        </HStack>
        {suggestProducts && <SuggestProducts {...suggestProducts} />}
      </VStack>
      <SEO
        title={getProductSeoTitle(title) + " - Lucky Kids"}
        description={getProductSeoDescription(product.descriptionHtml)}
      />
    </>
  );
}
