import { useContext, useMemo, useState } from "react";

import { LoadingContext } from "../context";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function LoadingProvider({ children }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const context = useMemo(() => ({ loading, setLoading }), [loading]);

  return (
    <LoadingContext.Provider value={context}>
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoadingContext() {
  const context = useContext(LoadingContext);

  if (context === null) {
    throw new Error("LoadingContext is null");
  }
  return context;
}
