import { useLayoutEffect, useRef } from "react";

import { LatoText, PaytoneOneText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { getColorStyle } from "../../utils";

import type { CSSProperties } from "react";

const desktopStyle: Record<string, CSSProperties> = {
  bannerWrapper: {
    width: "100%",
    background: getColorStyle("yellow"),
    padding: "4.25rem 5rem",
    alignItems: "center",
  },
  textWrapper: {
    marginBottom: "2.5rem",
  },
  marketingCardWrapper: {
    width: "100%",
    maxWidth: "1076px",
    justifyContent: "space-between",
    margin: "auto",
  },
  cardWrapper: {
    width: "31%",
    padding: "1rem",
    background: getColorStyle("eggShell"),
    borderRadius: "0.5rem",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  bannerWrapper: {
    width: "100%",
    background: getColorStyle("yellow"),
    alignItems: "center",
  },
  textWrapper: {
    margin: "2rem 2rem 2.5rem 2rem",
    wordBreak: "break-word",
  },
  marketingCardWrapper: {
    width: "65.25rem",
    height: "100%",
    justifyContent: "space-between",
    position: "absolute",
    padding: "0 1rem",
  },
};

const MARKETING_CARD = [
  {
    src: "/images/home_page/marketing_card_0.svg",
    text: "We are here to help measure your child’s feet and recommend the best size. Our flexible return and exchange policy ensure your children can put their best foot forward.",
  },
  {
    src: "/images/home_page/marketing_card_1.svg",
    text: "Run, jump, and twist. Our shoes are durable and can withstand your child’s best binkies. Your child will happily walk a mile in Lucky Shoes.",
  },
  {
    src: "/images/home_page/marketing_card_2.svg",
    text: "We stock a selection of fashionable sandals, running shoes, dress shoes, and boots. Our stylish shoes make both children and parents smile. Luck is on your side that you’ll find your perfect pair.",
  },
];

export function MarketingBanner() {
  const { isMobile, isPad } = useIsMobile();
  const marketRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    // scroll to middle of the div
    const div = marketRef.current as HTMLDivElement;
    div.scrollLeft = div.offsetWidth / 2;
  }, []);

  return (
    <VStack
      style={
        isMobile || isPad
          ? mobileStyle.bannerWrapper
          : desktopStyle.bannerWrapper
      }
    >
      <VStack
        style={
          isMobile || isPad ? mobileStyle.textWrapper : desktopStyle.textWrapper
        }
      >
        <PaytoneOneText
          color="black"
          weight="bold"
          size={isMobile || isPad ? "medium" : "large"}
          text="At Lucky Shoes,"
        />
        <PaytoneOneText
          color="black"
          weight="bold"
          size={isMobile || isPad ? "medium" : "large"}
          text="we bring fit, function, & fashion to footwear."
        />
      </VStack>
      <div
        style={
          isMobile || isPad
            ? {
                width: "100%",
                position: "relative",
                height: "23.625rem",
                marginBottom: "2rem",
                overflow: "scroll",
              }
            : { width: "100%" }
        }
      >
        <HStack
          style={
            isMobile || isPad
              ? mobileStyle.marketingCardWrapper
              : desktopStyle.marketingCardWrapper
          }
          innerRef={marketRef}
        >
          {MARKETING_CARD.map(({ src, text }) => (
            <MarketingCard src={src} text={text} key={src} />
          ))}
        </HStack>
      </div>
    </VStack>
  );
}

type MarketingCardProps = {
  src: string;
  text: string;
};

function MarketingCard({ src, text }: MarketingCardProps) {
  return (
    <VStack style={desktopStyle.cardWrapper}>
      <div style={{ position: "relative" }}>
        <img src={src} alt="" style={{ marginBottom: "1rem", width: "100%" }} />
      </div>
      <LatoText
        text={text}
        color="black"
        weight="normal"
        size="normal"
        customStyle={{ textAlign: "left", lineHeight: "150%" }}
      />
    </VStack>
  );
}
