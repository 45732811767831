import { Link } from "react-router-dom";

import { LatoText, PaytoneOneText } from "../components";
import { VStack } from "../layouts";
import { useBlogsContext, useMobileViewContext } from "../providers";
import { getColorStyle } from "../utils";

import type { CSSProperties } from "react";

const mobileStyle: Record<string, CSSProperties> = {
  largeFrame: {
    width: "100%",
    padding: "1rem 1rem 2rem",
    gap: "1rem",
  },
  blogCard: {
    width: "100%",
    padding: "1rem",
    background: getColorStyle("white"),
    boxShadow: "0px 8px 15px rgba(171, 171, 171, 0.18)",
    borderRadius: "1rem",
    gap: "0.5rem",
  },
  textWrapper: {
    textAlign: "left",
  },
  blogsFrame: {
    gap: "1rem",
  },
};
const desktopStyle: Record<string, CSSProperties> = {
  largeFrame: {
    width: "100%",
    maxWidth: "800px",
    padding: "3rem 2rem",
    margin: "auto",
    gap: "1.5rem",
  },
  blogCard: {
    width: "100%",
    maxWidth: "624px",
    padding: "1.5rem",
    background: getColorStyle("white"),
    boxShadow: "0px 8px 15px rgba(171, 171, 171, 0.18)",
    borderRadius: "1rem",
    gap: "1rem",
  },
  topBlogCard: {
    border: "solid 1px #ECECEC",
    padding: "1rem",
    gap: "1rem",
  },
  textWrapper: {
    textAlign: "left",
  },
  blogsFrame: {
    display: "grid",
    gap: "1rem",
    width: "100%",
    padding: "1rem 0",
    gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
    gridAutoRows: "max-content",
  },
};

export function Blogs() {
  const { isMobile } = useMobileViewContext();
  const blogs = useBlogsContext();

  return (
    <VStack style={isMobile ? mobileStyle.largeFrame : desktopStyle.largeFrame}>
      <VStack style={{ paddingBottom: isMobile ? "1rem" : "3rem" }}>
        <PaytoneOneText
          text="Blog & News "
          color="black"
          weight="normal"
          size={isMobile ? "medium" : "large"}
        />
      </VStack>
      {blogs.map(
        ({ title, shortDescription, publishedDate, src, id }, index) =>
          index === 0 &&
          !isMobile && (
            <Link to={`/blog/${id}`}>
              <TopBlogCard
                src={src}
                title={title}
                text={shortDescription}
                date={publishedDate}
              />
            </Link>
          )
      )}
      <VStack
        style={isMobile ? mobileStyle.blogsFrame : desktopStyle.blogsFrame}
      >
        {blogs.map(
          ({ src, title, shortDescription, publishedDate, id }, index) =>
            (index !== 0 || isMobile) && (
              <Link to={`/blog/${id}`}>
                <BlogCard
                  src={src}
                  title={title}
                  text={shortDescription}
                  date={publishedDate}
                />
              </Link>
            )
        )}
      </VStack>
    </VStack>
  );
}

type Props = {
  src: string;
  title: string;
  text: string;
  date: string;
};
function TopBlogCard({ src, title, text, date }: Props) {
  return (
    <VStack style={desktopStyle.topBlogCard}>
      <img src={src} width="100%" />
      <LatoText
        text={title}
        color="black"
        weight="bold"
        size="medium"
        customStyle={{ textAlign: "left" }}
      />
      <LatoText
        text={date}
        color="black"
        weight="normal"
        size="normal"
        customStyle={{ textAlign: "left" }}
      />
      <LatoText
        text={text}
        color="black"
        weight="normal"
        size="normal"
        customStyle={desktopStyle.textWrapper}
      />
      <LatoText
        text="read post"
        color="blue"
        weight="bold"
        size="normal"
        customStyle={{ textAlign: "left" }}
      />
    </VStack>
  );
}

function BlogCard({ src, title, text, date }: Props) {
  const { isMobile } = useMobileViewContext();
  return (
    <VStack style={isMobile ? mobileStyle.blogCard : desktopStyle.blogCard}>
      <VStack style={{ borderRadius: "0.5rem", overflow: "hidden" }}>
        <img src={src} width="100%" />
      </VStack>
      <LatoText
        text={title}
        color="black"
        weight="bold"
        size={isMobile ? "normalMedium" : "medium"}
        customStyle={{ textAlign: "left" }}
      />
      <LatoText
        text={text}
        color="black"
        weight="normal"
        size="normal"
        customStyle={
          isMobile ? mobileStyle.textWrapper : desktopStyle.textWrapper
        }
      />
      <LatoText
        text="read post"
        color="blue"
        weight="bold"
        size="normal"
        customStyle={{ textAlign: "left" }}
      />
      <LatoText
        text={date}
        color="black"
        weight="normal"
        size="normal"
        customStyle={{ textAlign: "left" }}
      />
    </VStack>
  );
}
