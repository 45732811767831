import { useIsMobile } from "../../hooks";
import { VStack } from "../../layouts";

import { FilterMenu } from "./FilterMenu";
import { Sort } from "./Sort";

export function Filter() {
  const { isMobile, isPad } = useIsMobile();

  return (
    <VStack
      style={{
        flex: isMobile || isPad ? "1" : "0.25",
        padding: isMobile || isPad ? "1rem 0" : "1rem",
        gap: "1rem",
      }}
    >
      <Sort />
      <FilterMenu />
    </VStack>
  );
}
