import { ReactComponent as RadioActive } from "../assets/icons/common/redio-button-active.svg";
import { ReactComponent as RadioInactive } from "../assets/icons/common/redio-button-inactive.svg";
import { HStack } from "../layouts";

import { LatoText } from "./Text";

import type { ColorType } from "../types";
import type { CSSProperties } from "react";

type Props = {
  text: string;
  selected: boolean;
  onClick: () => void;
  color?: ColorType;
};

const style: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "0.375rem 1rem",
  },
  image: {
    width: "1rem",
    margin: "auto 0.375rem auto 0",
    cursor: "pointer",
  },
  text: {
    textAlign: "left",
    cursor: "pointer",
  },
};
export function Radio({ text, selected, onClick, color }: Props) {
  return (
    <HStack style={style.wrapper} onClick={onClick}>
      {selected ? (
        <RadioActive style={style.image} />
      ) : (
        <RadioInactive style={style.image} />
      )}
      <LatoText
        text={text}
        color={color || "black"}
        weight="normal"
        size="normal"
        customStyle={style.text}
      />
    </HStack>
  );
}
