import { getClientDomain, reportError } from "../utils";

import type { ProductDetailType } from "../types";

export async function getProduct(
  title: string,
  callback: (product: ProductDetailType[]) => void
) {
  try {
    const replaceTitle = title.replaceAll("-", " ");
    const response = await fetch(
      `${getClientDomain()}/api/get-products-by-title/${replaceTitle}`
    );
    const productDetail = await response.json();
    callback(productDetail);
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/get-products-by-title/" + title,
      method: "GET",
    });
    throw new Error(JSON.stringify(e));
  }
}
