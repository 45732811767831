import { useCallback, useMemo } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";

import { useIsMobile } from "../../hooks";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getColorStyle } from "../../utils";
import { HStack } from "../HStack";

import type { ColorType } from "../../types";
import type {
  MouseEvent,
  KeyboardEvent,
  CSSProperties,
  ReactNode,
} from "react";

export type SlideType = {
  src: {
    desktop: string;
    mobile: string;
  };
  backgroundColor: ColorType;
  promo?: ReactNode;
  link: string;
  limitHeight?: boolean;
};

type SlideProps = {
  slides: SlideType[];
  showArrows?: boolean;
  showThumbs?: boolean;
  showIndicators?: boolean;
  autoPlay?: boolean;
  infiniteLoop?: boolean;
  interval?: number;
  limitHeight?: boolean;
};

export function Slides({
  slides,
  showArrows,
  showThumbs,
  autoPlay,
  infiniteLoop,
  interval,
  showIndicators = true,
  limitHeight,
}: SlideProps) {
  return (
    <Carousel
      showArrows={showArrows}
      showThumbs={showThumbs}
      showIndicators={showIndicators && slides.length > 1}
      showStatus={false}
      autoPlay={autoPlay}
      infiniteLoop={infiniteLoop}
      interval={interval || 3000}
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
      renderIndicator={(
        clickHandler: (e: MouseEvent | KeyboardEvent) => void,
        isSelected: boolean,
        index: number
      ) => (
        <SlidesIndicator
          clickHandler={clickHandler}
          isSelected={isSelected}
          isFirst={index === 0}
          isLast={index === slides.length - 1}
        />
      )}
    >
      {slides.map((slide) => (
        <Slide key={slide.src.desktop} limitHeight={limitHeight} {...slide} />
      ))}
    </Carousel>
  );
}

export function Slide({
  src,
  backgroundColor,
  promo,
  link,
  limitHeight,
}: SlideType) {
  const { isMobile, isPad } = useIsMobile();
  const navigate = useNavigate();

  const handleClickImg = useCallback(() => {
    navigate(link);
  }, [navigate, link]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        width: "100%",
        background: getColorStyle(backgroundColor),
      }}
    >
      <HStack
        style={{
          width: "100%",
          margin: "auto",
          position: "relative",
          flexDirection: isMobile || isPad ? "column" : "row",
          height: limitHeight ? "40vh" : "unset",
        }}
        onClick={handleClickImg}
      >
        <img
          src={isMobile || isPad ? src.mobile : src.desktop}
          height={limitHeight ? "100%" : "unset"}
          style={{ objectFit: limitHeight ? "scale-down" : "unset" }}
          alt=""
        />
        {promo && promo}
      </HStack>
    </div>
  );
}

// Desktop CSS Styling

const indicatorStyle: CSSProperties = {
  width: "2.3rem",
  height: "3.5rem",
  padding: "1rem 0.75rem",
  display: "inline-block",
  background: "rgba(236, 236, 236, 0.32)",
};

const desktopStyle: Record<string, CSSProperties> = {
  firstIndicator: {
    ...indicatorStyle,
    width: "3.05rem",
    padding: "1rem 0.75rem 1rem 1.5rem",
    borderTopLeftRadius: "2rem",
    borderBottomLeftRadius: "2rem",
  },
  lastIndicator: {
    ...indicatorStyle,
    width: "3.05rem",
    padding: "1rem 1.5rem 1rem 0.75rem",
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  },
  indicatorImg: {
    width: "0.8rem",
    height: "1.5rem",
  },
};

// Mobile CSS Styling

const indicatorMStyle: CSSProperties = {
  width: "1.1875rem",
  height: "2rem",
  padding: "0.5rem 0.375rem",
  display: "inline-block",
  background: "rgba(236, 236, 236, 0.32)",
};

const mobileStyle: Record<string, CSSProperties> = {
  firstIndicator: {
    ...indicatorMStyle,
    width: "1.8125rem",
    padding: "0.5rem 0.375rem 0.5rem 1rem",
    borderTopLeftRadius: "2rem",
    borderBottomLeftRadius: "2rem",
  },
  lastIndicator: {
    ...indicatorMStyle,
    width: "1.8125rem",
    padding: "0.5rem 1rem 0.5rem 0.375rem",
    borderTopRightRadius: "2rem",
    borderBottomRightRadius: "2rem",
  },
  indicatorImg: {
    width: "0.4375rem",
    height: "0.75rem",
  },
};

function SlidesIndicator({
  clickHandler,
  isSelected,
  isFirst,
  isLast,
}: {
  clickHandler: (e: MouseEvent | KeyboardEvent) => void;
  isSelected: boolean;
  isFirst: boolean;
  isLast: boolean;
}) {
  const { isMobile } = useIsMobile();
  const style = useMemo(() => {
    if (!isMobile) {
      return isFirst
        ? desktopStyle.firstIndicator
        : isLast
        ? desktopStyle.lastIndicator
        : indicatorStyle;
    }
    return isFirst
      ? mobileStyle.firstIndicator
      : isLast
      ? mobileStyle.lastIndicator
      : indicatorMStyle;
  }, [isFirst, isLast, isMobile]);

  if (isSelected) {
    return (
      <li style={style}>
        <img
          style={
            isMobile ? mobileStyle.indicatorImg : desktopStyle.indicatorImg
          }
          src="/icons/home_page/carousel-indicator-icon-active.svg"
          alt=""
        />
      </li>
    );
  }

  return (
    <li style={{ ...style, cursor: "pointer" }} onClick={clickHandler}>
      <img
        style={isMobile ? mobileStyle.indicatorImg : desktopStyle.indicatorImg}
        src="/icons/home_page/carousel-indicator-icon.svg"
        alt=""
      />
    </li>
  );
}
