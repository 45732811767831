import { ReactComponent as EmptyCartImg } from "../../assets/images/layouts/empty_cart_img.svg";
import { Button, PaytoneOneText } from "../../components";
import { HStack } from "../HStack";

type EmptyCartProps = {
  handleClose: () => void;
};

export function EmptyCart({ handleClose }: EmptyCartProps) {
  return (
    <>
      <PaytoneOneText
        text="your shopping cart is empty"
        color="black"
        weight="normal"
        size="large"
      />
      <HStack style={{ width: "100%", justifyContent: "center" }}>
        <EmptyCartImg />
      </HStack>
      <Button
        name="continue shopping"
        onClick={handleClose}
        isDisabled={false}
      />
    </>
  );
}
