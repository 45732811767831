import Cookies from "js-cookie";
import { useContext, useState, useEffect } from "react";

import { getCartById, createCart } from "../api";
import { CartContext, CartSetterContext, CartIdContext } from "../context";
import { createCartInitialState } from "../utils";

import type { CartIdContextType, CartContextType } from "../context";
import type { ReactNode } from "react";

type CartProviderProps = {
  children: ReactNode;
};

export function CartProvider({ children }: CartProviderProps) {
  const [cartId, setCartId] = useState<CartIdContextType>(
    Cookies.get("cartId") || ""
  );
  const [cart, setCart] = useState<CartContextType>(createCartInitialState());

  useEffect(() => {
    if (!cartId) {
      void createCart(setCartId);
      return;
    } else {
      void getCartById(cartId, setCart, setCartId);
    }
  }, [cartId]);

  if (!cart.id) return null;

  return (
    <CartIdContext.Provider value={cartId}>
      <CartSetterContext.Provider value={setCart}>
        <CartContext.Provider value={cart}>{children}</CartContext.Provider>
      </CartSetterContext.Provider>
    </CartIdContext.Provider>
  );
}

export function useCartIdContext() {
  const id = useContext(CartIdContext);
  if (id === null) throw new Error("Cart Id context is null");

  return id;
}

export function useCartSetterContext() {
  const setCard = useContext(CartSetterContext);
  if (setCard === null) throw new Error("Cart setter context is null");

  return setCard;
}

export function useCartContext() {
  const cart = useContext(CartContext);
  if (cart === null) throw new Error("Cart context is null");

  return cart;
}
