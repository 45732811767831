import { SEO } from "./SEO";

export function BlogSEO() {
  return (
    <SEO
      title="Blog - Lucky Kids"
      description="All resource about your little one."
    />
  );
}
