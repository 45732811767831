import LogRocket from "logrocket";
import { v4 as uuidv4 } from "uuid";

export function reportError(
  error: Error,
  extraDara?: Record<string, string | number | boolean>
) {
  LogRocket.captureException(error, {
    extra: {
      id: uuidv4(),
      message: error.message,
      stack: error.stack ?? "No stack found",
      ...extraDara,
    },
  });

  return error.message;
}
