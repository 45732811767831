import { useCallback } from "react";
import { Link } from "react-router-dom";

import { LatoText } from "../../components";
import { useIsLoggedIn, useIsMobile } from "../../hooks";
import { getColorStyle } from "../../utils";
import { Drawer } from "../Drawer";
import { HStack } from "../HStack";
import { VStack } from "../VStack";

import type { CSSProperties } from "react";

import {
  ACCOUNT_INFO_LOGGED_IN,
  ACCOUNT_INFO_NOT_LOGGED_IN,
  INFO,
} from "./config";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    position: "relative",
    width: "100%",
    background: getColorStyle("black"),
    padding: "0 1rem",
  },
  topWrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "3rem",
    justifyContent: "space-between",
    margin: "auto",
    zIndex: "1",
  },
  infoWrapper: {
    width: "23%",
    gap: "0.5rem",
  },
  infoTitle: {
    marginBottom: "1.5rem",
    textAlign: "left",
  },
  infoItem: {
    marginBottom: "0.5rem",
    textAlign: "left",
    cursor: "pointer",
    wordBreak: "break-word",
  },
  bottomWrapper: {
    height: "5rem",
  },
  socialIconWrapper: {
    height: "1rem",
    margin: "0 auto",
  },
  img: {
    width: "17.5rem",
    position: "absolute",
    top: "-30%",
    right: "15%",
    zIndex: "1",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    position: "relative",
    width: "100%",
    background: getColorStyle("black"),
    padding: "3rem 1.5rem",
  },
  topWrapper: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  infoMainWrapper: {
    borderTop: "solid 1px #8A8A8A",
    zIndex: "1",
  },
  infoWrapper: {
    width: "100%",
    marginBottom: "2rem",
    padding: "0 1.5rem 0 0.5rem",
    gap: "0.5rem",
  },
  infoTitle: {
    marginBottom: "1rem",
    textAlign: "left",
  },
  infoItem: {
    marginBottom: "0.5rem",
    textAlign: "left",
    cursor: "pointer",
    wordBreak: "break-word",
  },
  bottomWrapper: {
    height: "auto",
  },
  socialIconWrapper: {
    height: "1rem",
    margin: "0 auto",
  },
  img: {
    width: "5rem",
    position: "absolute",
    top: "-10%",
    right: "15%",
  },
};

export function InfoSection() {
  const { isMobile, isPad } = useIsMobile();
  const isLoggedIn = useIsLoggedIn();

  const handleClickMedia = useCallback((link: string) => {
    window.open(link);
  }, []);

  return (
    <VStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
    >
      <img
        src="/images/home_page/footer-logo.svg"
        alt=""
        style={isMobile || isPad ? mobileStyle.img : desktopStyle.img}
      />
      <HStack
        style={
          isMobile || isPad ? mobileStyle.topWrapper : desktopStyle.topWrapper
        }
      >
        {isMobile || isPad
          ? INFO.map(({ title, children }) => (
              <VStack style={mobileStyle.infoMainWrapper}>
                <Drawer
                  headerText={title}
                  headerSize="normal"
                  headerWeight="bold"
                  maxHeight="320px"
                  headerColor="white"
                  isWhiteArrow
                >
                  <VStack key={title} style={mobileStyle.infoWrapper}>
                    {children.map(({ text, link }) => (
                      <InfoItem text={text} link={link} key={text} />
                    ))}
                  </VStack>
                </Drawer>
              </VStack>
            ))
          : INFO.map(({ title, children }) => (
              <VStack key={title} style={desktopStyle.infoWrapper}>
                <LatoText
                  text={title}
                  color="white"
                  weight="bold"
                  size={isMobile || isPad ? "normal" : "medium"}
                  customStyle={desktopStyle.infoTitle}
                />
                {children.map(({ text, link }) => (
                  <InfoItem text={text} link={link} key={text} />
                ))}
              </VStack>
            ))}
        {isMobile || isPad
          ? (isLoggedIn
              ? ACCOUNT_INFO_LOGGED_IN
              : ACCOUNT_INFO_NOT_LOGGED_IN
            ).map(({ title, children }) => (
              <VStack style={mobileStyle.infoMainWrapper}>
                <Drawer
                  headerText={title}
                  headerSize="normal"
                  headerWeight="bold"
                  maxHeight="320px"
                  headerColor="white"
                  isWhiteArrow
                >
                  <VStack key={title} style={mobileStyle.infoWrapper}>
                    {children.map(({ text, link }) => (
                      <InfoItem text={text} link={link} key={text} />
                    ))}
                  </VStack>
                </Drawer>
              </VStack>
            ))
          : (isLoggedIn
              ? ACCOUNT_INFO_LOGGED_IN
              : ACCOUNT_INFO_NOT_LOGGED_IN
            ).map(({ title, children }) => (
              <VStack key={title} style={desktopStyle.infoWrapper}>
                <LatoText
                  text={title}
                  color="white"
                  weight="bold"
                  size="medium"
                  customStyle={desktopStyle.infoTitle}
                />
                {children.map(({ text, link }) => (
                  <InfoItem text={text} link={link} key={text} />
                ))}
              </VStack>
            ))}
      </HStack>
      <HStack
        style={
          isMobile || isPad
            ? mobileStyle.bottomWrapper
            : desktopStyle.bottomWrapper
        }
      >
        <HStack style={desktopStyle.socialIconWrapper}>
          <img
            src="/icons/home_page/fb-icon.svg"
            alt=""
            onClick={() => {
              handleClickMedia("https://www.facebook.com/LuckyKidsUAE/");
            }}
            style={{ width: "0.5rem", marginRight: "2rem", cursor: "pointer" }}
          />
          <img
            src="/icons/home_page/ins-icon.svg"
            alt=""
            onClick={() => {
              handleClickMedia("https://www.instagram.com/luckykidsuae/");
            }}
            style={{ width: "1rem", marginRight: "2rem", cursor: "pointer" }}
          />
        </HStack>
      </HStack>
    </VStack>
  );
}

type InfoItemProps = {
  text: string;
  link: string;
};

function InfoItem({ text, link }: InfoItemProps) {
  return (
    <Link to={link}>
      <LatoText
        text={text}
        color="white"
        weight="normal"
        size="normalSmall"
        customStyle={desktopStyle.infoItem}
      />
    </Link>
  );
}
