import { useContext } from "react";

import { MobileViewContext } from "../context";
import { useIsMobile } from "../hooks";

import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export function MobileViewProvider({ children }: Props) {
  const context = useIsMobile();

  return (
    <MobileViewContext.Provider value={context}>
      {children}
    </MobileViewContext.Provider>
  );
}

export function useMobileViewContext() {
  const context = useContext(MobileViewContext);

  if (!context) throw new Error("MobileViewContext is null");
  return context;
}
