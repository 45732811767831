import { useNavigate } from "react-router-dom";

import { Button, LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, ProductCard, VStack } from "../../layouts";
import { useNewArrivalsProvider } from "../../providers";
import { getColorStyle } from "../../utils";

import type { ColorType } from "../../types";
import type { CSSProperties } from "react";

type Props = {
  title: string;
  backgroundColor: ColorType;
  isNewArrivals?: boolean;
  isSales?: boolean;
  isBestSellers?: boolean;
};

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
  },
  mainWrapper: {
    width: "100%",
    position: "relative",
    maxWidth: "1280px",
    padding: "5rem 1rem",
    alignItems: "center",
    gap: "3rem",
    margin: "auto",
  },
};
const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
  },
  mainWrapper: {
    width: "100%",
    padding: "3rem 1.5rem",
    alignItems: "center",
    gap: "1.5rem",
  },
};

export function ProductsBanner({
  title,
  backgroundColor,
  isNewArrivals,
  isSales,
  isBestSellers,
}: Props) {
  const { isMobile, isPad } = useIsMobile();
  const products = useNewArrivalsProvider();
  const navigate = useNavigate();

  if (Object.keys(products).length === 0) return null;

  return (
    <VStack
      style={{
        ...(isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper),
        background:
          backgroundColor === "lightPink"
            ? "rgba(255, 214, 214, 0.2)"
            : getColorStyle(backgroundColor),
      }}
    >
      <VStack
        style={
          isMobile || isPad ? mobileStyle.mainWrapper : desktopStyle.mainWrapper
        }
      >
        <LatoText
          text={title}
          color="black"
          weight="bold"
          size={isMobile || isPad ? "medium" : "large"}
        />
        <HStack
          style={{
            width: "100%",
            maxWidth: isMobile || isPad ? "unset" : "1280px",
            margin: "auto",
            gap: isMobile || isPad ? "1rem" : "2rem",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(products).map((key, index) => {
            if (index === Object.keys(products).length - 1 && isPad)
              return null;
            const { productId, images, tags, prices, compareAtPrices } =
              products[key];
            return (
              <HStack
                style={{
                  width: isMobile ? "40%" : isPad ? "30%" : "20%",
                  flexGrow: 1,
                }}
                key={key}
              >
                <ProductCard
                  productId={productId}
                  images={images}
                  tags={tags}
                  prices={prices}
                  compareAtPrices={compareAtPrices}
                  title={key}
                />
              </HStack>
            );
          })}
        </HStack>
        {isNewArrivals && (
          <HStack style={{ width: "12rem" }}>
            <Button
              name="shop more"
              onClick={() => navigate("/shoes/new-arrivals")}
              isDisabled={false}
              color="white"
              fontSize={isMobile || isPad ? "normal" : "medium"}
              backgroundColor="blue"
            />
          </HStack>
        )}
        {isSales && (
          <HStack style={{ width: "12rem" }}>
            <Button
              name="shop more"
              onClick={() => navigate("/shoes/sale")}
              isDisabled={false}
              color="white"
              fontSize={isMobile || isPad ? "normal" : "medium"}
              backgroundColor="blue"
            />
          </HStack>
        )}
        {isBestSellers && (
          <HStack style={{ width: "12rem" }}>
            <Button
              name="shop more"
              onClick={() => navigate("/shoes/best-sellers")}
              isDisabled={false}
              color="white"
              fontSize={isMobile || isPad ? "normal" : "medium"}
              backgroundColor="blue"
            />
          </HStack>
        )}
      </VStack>
    </VStack>
  );
}
