import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as ArrowDown } from "../assets/icons/common/arrow-down.svg";
import { Button, LatoText, PaytoneOneText, TextInput } from "../components";
import { HorizontalLine } from "../components/HorizontalLine";
import { HEADER_HEIGHT } from "../constants";
import { useIsMobile } from "../hooks";

import type { CSSProperties, ChangeEvent } from "react";

import { HStack, VStack } from "../layouts";
import { useFilterContext } from "../providers";
import { getColorStyle } from "../utils";

import { AgeBanner } from "./Home/AgeBanner";
import { Filter } from "./Products/Filter";
import { ProductsList } from "./Products/ProductsList";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: { width: "100%" },
  bottomWrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "3rem 1rem",
    margin: "auto",
    gap: "2rem",
  },
  searchBarWrapper: {
    width: "100%",
    gap: "1rem",
    padding: "1rem 3rem",
  },
  mainWrapper: {
    width: "100%",
    margin: "auto",
    gap: "2rem",
    justifyContent: "space-between",
  },
  searchIcon: {
    position: "absolute",
    top: "0.5rem",
    left: "1rem",
  },
  closeIcon: {
    position: "absolute",
    top: "0.5rem",
    right: "1rem",
    cursor: "pointer",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: { width: "100%" },
  bottomWrapper: {
    width: "100%",
    padding: "0 1.5rem",
  },
  searchBarWrapper: {
    width: "100%",
    gap: "1rem",
    padding: "1rem",
  },
  searchIcon: {
    position: "absolute",
    top: "0.5rem",
    left: "1rem",
  },
};

export function Search() {
  const { isMobile, isPad } = useIsMobile();
  const { productsCount, resetFilter } = useFilterContext();
  const navigate = useNavigate();
  const [searchContent, setSearchContent] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [toggleMobileFilter, setToggleMobileFilter] = useState<boolean>(false);

  const handleSearch = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const searchValue = e.target.value;
      setSearchContent(searchValue);
      setIsSearched(true);
      navigate(`/search/${searchValue}`);
    },
    [navigate, setIsSearched]
  );
  const handleCancel = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const handleClear = useCallback(() => {
    setSearchContent("");
    setIsSearched(false);
    navigate("/search/");
  }, [setSearchContent, navigate, setIsSearched]);

  const handleMobileFilterButtonClick = useCallback(() => {
    setToggleMobileFilter(!toggleMobileFilter);
  }, [toggleMobileFilter]);

  const handleMobileFilterBackClick = useCallback(() => {
    resetFilter();
    setToggleMobileFilter(!toggleMobileFilter);
  }, [resetFilter, toggleMobileFilter]);

  return (
    <VStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
    >
      <VStack
        style={
          isMobile || isPad
            ? mobileStyle.bottomWrapper
            : desktopStyle.bottomWrapper
        }
      >
        <VStack
          style={
            isMobile || isPad
              ? mobileStyle.searchBarWrapper
              : desktopStyle.searchBarWrapper
          }
        >
          <HStack
            style={{
              gap: isMobile || isPad ? "0.5rem" : "1rem",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%", position: "relative" }}>
              <div
                style={
                  isMobile || isPad
                    ? mobileStyle.searchIcon
                    : desktopStyle.searchIcon
                }
              >
                <img
                  src="/icons/home_page/search-icon.svg"
                  style={{ width: "12px" }}
                />
              </div>
              <TextInput
                type="text"
                isRequired={false}
                placeholder="Search"
                value={searchContent}
                errorMessage=""
                onChange={handleSearch}
                customStyle={{ paddingLeft: "2.5rem" }}
              />
              {!(isMobile || isPad) && searchContent && (
                <div style={desktopStyle.closeIcon} onClick={handleClear}>
                  <img
                    src="/icons/home_page/close-icon.svg"
                    style={{ width: "12px" }}
                  />
                </div>
              )}
            </div>
            <HStack style={{ cursor: "pointer" }} onClick={handleCancel}>
              <LatoText
                text="cancel"
                color="blue"
                weight="bold"
                size="normalMedium"
              />
            </HStack>
          </HStack>
          {isSearched && productsCount == 0 ? (
            <VStack style={{ gap: "1rem" }}>
              <img
                src="/icons/common/no-result-icon.svg"
                style={{ height: "4rem" }}
              />
              <LatoText
                text={
                  "Sorry, we couldn’t find any matching results for this search."
                }
                color="black"
                weight="normal"
                size={isMobile || isPad ? "normal" : "normalMedium"}
              />
              <LatoText
                text={"These categories might interest you."}
                color="black"
                weight="normal"
                size={isMobile || isPad ? "normal" : "normalMedium"}
              />
            </VStack>
          ) : (
            <LatoText
              text={"Showing " + productsCount + " product results"}
              color="black"
              weight="normal"
              size={isMobile || isPad ? "normal" : "normalMedium"}
            />
          )}
        </VStack>
        {productsCount > 0 && (isMobile || isPad) && (
          <HStack
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <HStack
              style={{
                padding: "0.5rem 1rem",
                margin: "0.25rem auto 0.25rem 0",
                border: `1px solid ${getColorStyle("border")}`,
                borderRadius: "8px",
                gap: "0.5rem",
                alignItems: "center",
              }}
              onClick={handleMobileFilterButtonClick}
            >
              <LatoText
                text="Filter and Sort"
                color="black"
                weight="medium"
                size="small"
              />
              <ArrowDown style={{ width: "0.625rem", height: "0.375rem" }} />
            </HStack>
            <HStack style={{ padding: "0.5rem 1rem", justifyContent: "right" }}>
              <LatoText
                text={productsCount + " PRODUCTS"}
                color="black"
                weight="medium"
                size="small"
              />
            </HStack>
          </HStack>
        )}
        {productsCount > 0 && (isMobile || isPad) && toggleMobileFilter && (
          <VStack
            style={{
              position: "fixed",
              width: "100vw",
              height: `calc(100vh - ${HEADER_HEIGHT.mobile})`,
              top: HEADER_HEIGHT.mobile,
              left: "0",
              padding: "1rem 2rem 2rem",
              gap: "1rem",
              zIndex: "900",
              background: getColorStyle("white"),
            }}
          >
            <HStack
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.5rem",
              }}
            >
              <HStack style={{ gap: "0.5rem" }}>
                <img
                  src="/icons/common/back-arrow.svg"
                  onClick={handleMobileFilterBackClick}
                />
                <LatoText
                  text="Filter and Sort"
                  color="black"
                  weight="bold"
                  size="normalMedium"
                />
              </HStack>
              <PaytoneOneText
                text="clear"
                color="blue"
                weight="normal"
                size="normal"
                customStyle={{ cursor: "pointer" }}
                onClick={resetFilter}
              />
            </HStack>
            <HStack
              style={{ width: "100%", height: "100%", overflow: "scroll" }}
            >
              <Filter />
            </HStack>
            <LatoText
              text={productsCount + " product results"}
              color="black"
              weight="normal"
              size="normalSmall"
            />
            <Button
              name="apply"
              onClick={handleMobileFilterButtonClick}
              isDisabled={false}
              backgroundColor="yellow"
              fontSize="normal"
            />
          </VStack>
        )}
        {productsCount > 0 && (
          <HStack
            style={
              isMobile || isPad
                ? mobileStyle.mainWrapper
                : desktopStyle.mainWrapper
            }
          >
            {!(isMobile || isPad) && <Filter />}
            <VStack style={{ flex: isMobile || isPad ? "1" : "0.75" }}>
              <ProductsList />
            </VStack>
          </HStack>
        )}

        <HorizontalLine
          text="OR"
          lineStyle={{ borderBottom: "1px dashed #ECECEC" }}
          fontStyle={{
            fontFamily: "Lato",
            fontSize: "1rem",
            fontWeight: "400",
          }}
        />
        <AgeBanner />
      </VStack>
    </VStack>
  );
}
