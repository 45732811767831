import { useParams } from "react-router-dom";

import { getProductsSeoTitleDescription } from "../../utils";

import { SEO } from "./SEO";

export function ProductsSEO() {
  const { ageGroup } = useParams();

  return (
    <SEO
      title={getProductsSeoTitleDescription(ageGroup).title}
      description={getProductsSeoTitleDescription(ageGroup).description}
    />
  );
}
