import type { CSSProperties } from "react";

type Props = {
  lineStyle: CSSProperties;
  fontStyle: CSSProperties;
  text: string;
};

export function HorizontalLine({ lineStyle, fontStyle, text }: Props) {
  return (
    <h2
      style={{
        ...lineStyle,
        width: "100%",
        textAlign: "center",
        lineHeight: "0.1em",
      }}
    >
      <span
        style={{
          ...fontStyle,
          background: "#fff",
          padding: "0 10px",
        }}
      >
        {text}
      </span>
    </h2>
  );
}
