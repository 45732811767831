import { useCallback, useMemo, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";

import { addToCart } from "../../api";
import { Button, LatoText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack, Quantity, Price, Drawer } from "../../layouts";

import type { Variant } from "../../types";

import {
  useLoadingContext,
  useCartIdContext,
  useCartSetterContext,
} from "../../providers";

import type { CSSProperties } from "react";

import { useCartToggleContext } from "../../providers/CartToggleProvider";
import { getColorStyle, getSize } from "../../utils";

import { DeliveryOptions } from "./DeliveryOptions";
import { FitTips } from "./FitTips";
import { ReturnExchange } from "./ReturnExchange";
import { Sizes } from "./Sizes";
import { Thumbnail } from "./Thumbnail";

type DetailProps = {
  title: string;
  description: string;
  colorDescription: string;
  variants: Variant[];
  price: string;
  compareAtPrice?: string;
  thumbnail: {
    id: string;
    image: string;
  }[];
};

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    flex: 0.5,
    padding: "0 1.5rem",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
  },
};

export function Detail({
  title,
  price,
  compareAtPrice,
  description,
  colorDescription,
  variants,
  thumbnail,
}: DetailProps) {
  const { isMobile, isPad } = useIsMobile();
  const { setLoading } = useLoadingContext();
  const navigate = useNavigate();
  const cartId = useCartIdContext();
  const setCart = useCartSetterContext();
  const { toggleCart, setToggleCart } = useCartToggleContext();
  const sizeRef = useRef<HTMLDivElement | null>(null);

  const [selectSize, setSelectSize] = useState<number | null>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [sizeAlert, setSizeAlert] = useState<boolean>(false);
  const [cartAddedNotification, setCartAddedNotification] =
    useState<boolean>(false);

  const sizes = useMemo(
    () =>
      variants.map((variant) => ({
        size: getSize(variant.sizes),
        id: variant.id,
        maxQuantity: variant.quantityAvailable,
      })),
    [variants]
  );

  const maxQuantities = useMemo(
    () => variants.map((variant) => variant.quantityAvailable),
    [variants]
  );

  const handleResetQuantity = useCallback(() => {
    setQuantity(1);
  }, []);

  const setAlertTrue = useCallback(() => {
    setSizeAlert(true);
  }, [setSizeAlert]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleAddToCart = useCallback(async () => {
    if (selectSize === null) {
      if (!sizeRef || !sizeRef.current) throw new Error("Missing size ref");

      window.scroll({
        top: sizeRef.current.scrollHeight + 78,
        behavior: "smooth",
      });
      setAlertTrue();
      return;
    }
    setLoading(true);
    const { id: merchandiseId } = variants[selectSize];

    const newCart = await addToCart({
      cartId,
      lines: [{ merchandiseId, quantity }],
    });

    // GTM tracking
    TagManager.dataLayer({
      dataLayer: {
        event: "add_to_cart",
        product_name: title,
        price: price,
        currency: "AED",
      },
    });

    // FB tracking
    fbq("track", "add_to_cart", {
      currency: "AED",
      product_name: title,
    });

    setCart(newCart);
    setLoading(false);
    if (isMobile) {
      setCartAddedNotification(true);
      const ATCpopUp = document.getElementById("ATCpopUp");
      if (ATCpopUp) {
        ATCpopUp.style.display = "flex";
      }
    } else {
      setToggleCart(!toggleCart);
    }
  }, [
    cartId,
    quantity,
    selectSize,
    setCart,
    setLoading,
    variants,
    price,
    title,
    setAlertTrue,
    isMobile,
    toggleCart,
    setToggleCart,
  ]);

  const handleClickCart = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  setTimeout(function () {
    const ATCpopUp = document.getElementById("ATCpopUp");
    if (ATCpopUp) {
      ATCpopUp.style.display = "none";
    }
  }, 3000);

  useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  return (
    <>
      <VStack
        style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
      >
        {!(isMobile || isPad) && (
          <VStack>
            <LatoText
              text={title}
              color="black"
              weight={isMobile || isPad ? "normal" : "bold"}
              size={isMobile || isPad ? "medium" : "large"}
              customStyle={{ textAlign: "left", marginBottom: "1rem" }}
            />
            <Price
              currentPrice={price}
              compareAtPrice={compareAtPrice}
              currencyCode="AED"
              priceSize={isMobile || isPad ? "medium" : "large"}
              currentPriceWeight="bold"
              compareAtPriceWeight="bold"
              customStyle={{
                textAlign: "left",
                marginBottom: isMobile || isPad ? "1rem" : "1.75rem",
              }}
            />
          </VStack>
        )}

        <VStack
          style={{
            width: "100%",
            gap: "0.5rem",
            marginBottom: isMobile || isPad ? "1rem" : "1.5rem",
          }}
        >
          {!(isMobile || isPad) && (
            <ColorTags colorDescription={colorDescription} />
          )}
          <Thumbnail thumbnail={thumbnail} />
        </VStack>
        <Sizes
          sizes={sizes}
          selectSize={selectSize}
          setSelectSize={setSelectSize}
          handleResetQuantity={handleResetQuantity}
          sizeAlert={sizeAlert}
          setSizeAlert={setSizeAlert}
          innerRef={sizeRef}
        />
        <VStack
          style={{
            width: "100%",
            gap: "1rem",
            marginBottom: isMobile || isPad ? "1rem" : "1.5rem",
          }}
        >
          <LatoText
            text="Quantity"
            color="black"
            weight={"bold"}
            size={isMobile || isPad ? "normal" : "normalMedium"}
            customStyle={{ textAlign: "left" }}
          />
          <HStack style={{ marginRight: "auto" }}>
            <Quantity
              quantity={quantity}
              setQuantity={setQuantity}
              max={selectSize !== null ? maxQuantities[selectSize] : null}
              style={isMobile || isPad ? "mobile" : "desktop"}
            />
          </HStack>
          {selectSize !== null && maxQuantities[selectSize] === "0" && (
            <LatoText
              text="Product out of stock"
              color="red"
              weight="medium"
              size={isMobile || isPad ? "normalMedium" : "medium"}
              customStyle={{ textAlign: "left" }}
            />
          )}
        </VStack>
        <HStack
          style={{
            width: "100%",
            position: isMobile || isPad ? "fixed" : "unset",
            bottom: isMobile || isPad ? "0" : "unset",
            left: isMobile || isPad ? "0" : "unset",
            padding: "1rem",
            background: getColorStyle("white"),
          }}
        >
          <Button
            name="add to cart"
            onClick={handleAddToCart}
            isCustomDisabled={
              selectSize === null ||
              !maxQuantities[selectSize] ||
              maxQuantities[selectSize] === "0"
            }
            isDisabled={false}
            fontSize={isMobile || isPad ? "normal" : "medium"}
          />
        </HStack>

        <VStack style={{ marginLeft: "-0.5rem" }}>
          <Drawer
            headerText="Product Details"
            headerSize={isMobile || isPad ? "normal" : "normalMedium"}
            headerWeight="bold"
            maxHeight="1000px"
            isBottomLine={true}
          >
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              style={{
                textAlign: "left",
                fontFamily: "Lato",
                fontSize: isMobile || isPad ? "0.75rem" : "1rem",
                padding: "0 0.5rem 1rem",
                lineHeight: isMobile || isPad ? "unset" : "25.6px",
              }}
            />
          </Drawer>
          <FitTips fitTip="If there is any doubt about what size to order, it may be better to go a size up, as children will always continue to grow!" />
          <DeliveryOptions deliveryOptions="Free shipping for any order that reaches AED 150 or above. Otherwise, 20 AED shipping fee per order, non-refundable. We currently offer next day delivery on orders made from Monday to Saturday in UAE." />
          <ReturnExchange />
        </VStack>
      </VStack>
      {cartAddedNotification && (
        <HStack
          style={{
            position: "fixed",
            top: "0",
            zIndex: "20",
            background: getColorStyle("blue"),
            width: "100vw",
            marginLeft: "-1.5rem",
            padding: "0.8rem",
            justifyContent: "space-between",
            animation: "flyInAnimation 480ms ease-in forwards",
            display: "flex",
          }}
          id="ATCpopUp"
        >
          <img src="/icons/common/cart-icon-white.svg" />
          <LatoText
            text="You have added 1 item!"
            color="white"
            weight="bold"
            size="normalSmall"
          />
          <LatoText
            text="View Cart"
            color="white"
            weight="normal"
            size="normalSmall"
            customStyle={{ textDecoration: "underline" }}
            onClick={handleClickCart}
          />
          <img
            src="/icons/common/close-icon-white.png"
            alt=""
            width="13px"
            height="13px"
            onClick={() => {
              setCartAddedNotification(false);
            }}
          />
        </HStack>
      )}
    </>
  );
}

type ColorTagProps = {
  colorDescription: string;
};

function ColorTags({ colorDescription }: ColorTagProps) {
  const { isMobile, isPad } = useIsMobile();
  return (
    <HStack>
      <LatoText
        text="Color"
        color="black"
        weight="bold"
        size={isMobile || isPad ? "normal" : "normalMedium"}
      />
      <LatoText
        text={colorDescription}
        color="black"
        weight="medium"
        size="normalSmall"
        customStyle={{
          background: getColorStyle("yellow"),
          padding: "0.25rem 1rem",
          borderRadius: "24px",
          lineHeight: "140%",
          marginLeft: "0.5rem",
        }}
      />
    </HStack>
  );
}
