import type { ColorType, FontSizeType, FontWeightType } from "../types";

export function getColorStyle(color: ColorType) {
  switch (color) {
    case "black":
      return "#3C3C3C";
    case "white":
      return "#FFFFFE";
    case "yellow":
      return "#FFDF86";
    case "blue":
      return "#6E94F2";
    case "red":
      return "#EA5D63";
    case "eggShell":
      return "#FFFDF8";
    case "placeholder":
      return "#8A8A8A";
    case "error":
      return "#B21414";
    case "border":
      return "#ECECEC";
    case "activeBorder":
      return "#C5D4FA";
    case "inputFilled":
      return "#F1F4FE";
    case "disabled":
      return "#D8D8D8";
    case "pink":
      return "#FFC9C9";
    case "brown":
      return "#99582A";
    case "grey":
      return "#8A8A8A";
    case "green":
      return "#019F6A";
    case "gold":
      return "#EDC531";
    case "beige":
      return "#F5EBE0";
    case "purple":
      return "#A663CC";
    case "silver":
      return "#E9ECEF";
    case "cream":
      return "#FFFBF1";
    default:
      return color;
  }
}

export function getFontSizeStyle(size: FontSizeType) {
  switch (size) {
    case "small":
      return "0.75rem";
    case "normalSmall":
      return "0.875rem";
    case "normal":
      return "1rem";
    case "normalBMedium":
      return "1.125rem";
    case "normalMedium":
      return "1.25rem";
    case "medium":
      return "1.5rem";
    case "mediumLarge":
      return "1.75rem";
    case "large":
      return "2rem";
    case "larger":
      return "2.25rem";
    case "xLarge":
      return "3rem";
    case "xxLarge":
      return "4rem";
    default:
      return size;
  }
}

export function getFontWeightStyle(weight: FontWeightType) {
  switch (weight) {
    case "light":
      return 200;
    case "normal":
      return 400;
    case "medium":
      return 600;
    case "bold":
      return 700;
    default:
      return weight;
  }
}
