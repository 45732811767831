import { VStack } from "../VStack";

import { EmailSubscribe } from "./EmailSubscribe";
import { InfoSection } from "./InfoSection";

export function Footer() {
  return (
    <>
      <VStack>
        <EmailSubscribe />
        <InfoSection />
      </VStack>
      {/*disable email subscribe popup for now*/}
      {/*<ModalProvider>*/}
      {/*  <EmailPopup />*/}
      {/*</ModalProvider>*/}
    </>
  );
}
