import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";

import { HEADER_HEIGHT } from "../constants";
import ErrorBoundary from "../ErrorBoundary";
import { useIsMobile } from "../hooks";
import { Header, Footer, VStack, Loading } from "../layouts";
import { BasicProvider } from "../providers";
import { reportError } from "../utils";

import type { CSSProperties } from "react";

import { Routes, ScrollToTop } from "./Routes";

//GTM initialization
const tagManagerArgs = {
  gtmId: "GTM-N6WNS6J",
};

TagManager.initialize(tagManagerArgs);

const mainWrapperStyle: CSSProperties = {
  minHeight: "100vh",
  padding: `${HEADER_HEIGHT.desktop} 0 0 0`,
  margin: 0,
  justifyContent: "space-between",
};

const mobileMainWrapperStyle: CSSProperties = {
  minHeight: "100vh",
  padding: `${HEADER_HEIGHT.mobile} 0 0 0`,
  margin: 0,
  justifyContent: "space-between",
};

export function Router() {
  return (
    <ErrorBoundary onError={reportError}>
      <BrowserRouter>
        <ScrollToTop />
        <BasicProvider>
          <Loading />
          <Content />
        </BasicProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

function Content() {
  const { isMobile, isPad } = useIsMobile();
  return (
    <VStack
      style={isMobile || isPad ? mobileMainWrapperStyle : mainWrapperStyle}
    >
      <Header />
      <Routes />
      <Footer />
    </VStack>
  );
}
