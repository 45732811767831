import { createContext } from "react";

import type { User } from "../types";
import type { Dispatch, SetStateAction } from "react";

type UserContextType = {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
};

export const UserContext = createContext<UserContextType | null>(null);
