import { createContext } from "react";

import type { Dispatch, SetStateAction } from "react";

export type LoadingContextType = {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export const LoadingContext = createContext<LoadingContextType | null>(null);
