import { SEO } from "./SEO";

export function ReturnPolicySEO() {
  return (
    <SEO
      title="Return Policy - Lucky Kids"
      description="Check our return policy for easy return."
    />
  );
}
