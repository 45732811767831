import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ReactComponent as ArrowDown } from "../../assets/icons/common/arrow-down.svg";
import { Button, LatoText, PaytoneOneText } from "../../components";
import { HEADER_HEIGHT } from "../../constants";
import { useIsMobile } from "../../hooks";
import { HStack, VStack, LinkHeader } from "../../layouts";

import type { CSSProperties } from "react";

import { useFilterContext } from "../../providers";
import { getColorStyle, transformCamelCaseToWords } from "../../utils";

import { Banner } from "./Banner";
import { Filter } from "./Filter";
import { ProductsList } from "./ProductsList";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: { width: "100%" },
  bottomWrapper: {
    width: "100%",
    padding: "3rem 1rem",
  },
  headLinkWrapper: {
    width: "100%",
    maxWidth: "1280px",
    padding: "0.5rem 0",
    margin: " 0 auto 1rem auto",
  },
  mainWrapper: {
    width: "100%",
    maxWidth: "1280px",
    margin: "auto",
    gap: "2rem",
    justifyContent: "space-between",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: { width: "100%" },
  bottomWrapper: {
    width: "100%",
    padding: "1rem 1.5rem",
  },
};

export function Products() {
  const { ageGroup } = useParams();
  const { isMobile, isPad } = useIsMobile();
  const { productsCount, resetFilter } = useFilterContext();

  // desc: the state only controlling show/hide a full page filter in mobile view only
  const [toggleMobileFilter, setToggleMobileFilter] = useState<boolean>(false);

  const links = useMemo(
    () => [
      { text: "HOME", link: "/" },
      {
        text: ageGroup
          ? transformCamelCaseToWords(ageGroup).toUpperCase()
          : "Products",
        link: ageGroup ? `/shoes/${ageGroup}` : "/products",
      },
    ],
    [ageGroup]
  );

  const handleMobileFilterButtonClick = useCallback(() => {
    setToggleMobileFilter(!toggleMobileFilter);
  }, [toggleMobileFilter]);

  const handleMobileFilterBackClick = useCallback(() => {
    resetFilter();
    setToggleMobileFilter(!toggleMobileFilter);
  }, [resetFilter, toggleMobileFilter]);

  return (
    <VStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
    >
      <Banner tag={ageGroup || "babies"} />
      <VStack
        style={
          isMobile || isPad
            ? mobileStyle.bottomWrapper
            : desktopStyle.bottomWrapper
        }
      >
        <HStack
          style={
            isMobile || isPad
              ? mobileStyle.headLinkWrapper
              : desktopStyle.headLinkWrapper
          }
        >
          <LinkHeader links={links} />
        </HStack>
        {(isMobile || isPad || isPad) && (
          <HStack
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <HStack
              style={{
                padding: "0.5rem 1rem",
                margin: "0.25rem auto 0.25rem 0",
                border: `1px solid ${getColorStyle("border")}`,
                borderRadius: "8px",
                gap: "0.5rem",
                alignItems: "center",
              }}
              onClick={handleMobileFilterButtonClick}
            >
              <LatoText
                text="Filter and Sort"
                color="black"
                weight="medium"
                size="small"
              />
              <ArrowDown style={{ width: "0.625rem", height: "0.375rem" }} />
            </HStack>
            <HStack style={{ padding: "0.5rem 1rem", justifyContent: "right" }}>
              <LatoText
                text={productsCount + " PRODUCTS"}
                color="black"
                weight="medium"
                size="small"
              />
            </HStack>
          </HStack>
        )}
        {(isMobile || isPad) && toggleMobileFilter && (
          <VStack
            style={{
              position: "fixed",
              width: "100vw",
              height: `calc(100vh - ${HEADER_HEIGHT.mobile})`,
              top: HEADER_HEIGHT.mobile,
              left: "0",
              padding: "1rem 2rem 2rem",
              gap: "1rem",
              zIndex: "900",
              background: getColorStyle("white"),
            }}
          >
            <HStack
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.5rem",
              }}
            >
              <HStack style={{ gap: "0.5rem" }}>
                <img
                  src="/icons/common/back-arrow.svg"
                  onClick={handleMobileFilterBackClick}
                />
                <LatoText
                  text="Filter and Sort"
                  color="black"
                  weight="bold"
                  size="normalMedium"
                />
              </HStack>
              <PaytoneOneText
                text="clear"
                color="blue"
                weight="normal"
                size="normal"
                customStyle={{ cursor: "pointer" }}
                onClick={resetFilter}
              />
            </HStack>
            <HStack
              style={{ width: "100%", height: "100%", overflowY: "auto" }}
            >
              <Filter />
            </HStack>
            <LatoText
              text={productsCount + " product results"}
              color="black"
              weight="normal"
              size="normalSmall"
            />
            <Button
              name="apply"
              onClick={handleMobileFilterButtonClick}
              isDisabled={false}
              backgroundColor="yellow"
              fontSize="normal"
            />
          </VStack>
        )}
        <HStack
          style={
            isMobile || isPad
              ? mobileStyle.mainWrapper
              : desktopStyle.mainWrapper
          }
        >
          {!(isMobile || isPad) && <Filter />}
          <VStack style={{ flex: isMobile || isPad ? "1" : "0.75" }}>
            {!(isMobile || isPad) && (
              <HStack
                style={{ padding: "0.5rem 1rem", justifyContent: "right" }}
              >
                <LatoText
                  text={productsCount + " PRODUCTS"}
                  color="black"
                  weight="medium"
                  size="normalSmall"
                />
              </HStack>
            )}
            <ProductsList />
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  );
}
