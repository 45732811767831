import { useEffect } from "react";
import { Route, Routes as ReactRoutes, useLocation } from "react-router-dom";

import {
  EditAccountInfo,
  Home,
  Product,
  Products,
  Error,
  Account,
  Login,
  Signup,
  ResetPassword,
  Logout,
  Cart,
  About,
  ReturnPolicy,
  SizeGuide,
  FAQ,
  Search,
  Blogs,
  Blog,
} from "../pages";
import {
  ProductsProvider,
  SearchProductsProvider,
  BlogsProvider,
  BlogProvider,
} from "../providers";

import {
  ProductsSEO,
  HomeSEO,
  ReturnPolicySEO,
  SizeGuideSEO,
  AboutSEO,
  AccountSEO,
  LoginSEO,
  CommonSEO,
  BlogSEO,
} from "./SEO";

export function Routes() {
  return (
    <ReactRoutes>
      <Route
        path="/"
        element={
          <>
            <HomeSEO />
            <Home />
          </>
        }
      />
      <Route
        path="shoes/:ageGroup?/:type?"
        element={
          <>
            <ProductsSEO />
            <ProductsProvider>
              <Products />
            </ProductsProvider>
          </>
        }
      />
      <Route path="product/:title/:productId" element={<Product />} />
      <Route
        path="login/:reset?"
        element={
          <>
            <LoginSEO />
            <Login />
          </>
        }
      />
      <Route
        path="signup/:fail?"
        element={
          <>
            <CommonSEO />
            <Signup />
          </>
        }
      />
      <Route
        path="reset"
        element={
          <>
            <CommonSEO />
            <ResetPassword />
          </>
        }
      />
      <Route
        path="logout"
        element={
          <>
            <CommonSEO />
            <Logout />
          </>
        }
      />
      <Route
        path="account"
        element={
          <>
            <AccountSEO />
            <Account />
          </>
        }
      />
      <Route
        path="edit"
        element={
          <>
            <CommonSEO />
            <EditAccountInfo />
          </>
        }
      />
      <Route
        path="cart"
        element={
          <>
            <CommonSEO />
            <Cart />
          </>
        }
      />
      <Route
        path="policy"
        element={
          <>
            <ReturnPolicySEO />
            <ReturnPolicy />
          </>
        }
      />
      <Route
        path="about-us"
        element={
          <>
            <AboutSEO />
            <About />
          </>
        }
      />
      <Route
        path="size"
        element={
          <>
            <SizeGuideSEO />
            <SizeGuide />
          </>
        }
      />
      <Route
        path="FAQ"
        element={
          <>
            <CommonSEO />
            <FAQ />
          </>
        }
      />
      <Route
        path="search/:content?"
        element={
          <>
            <CommonSEO />
            <SearchProductsProvider>
              <Search />
            </SearchProductsProvider>
          </>
        }
      />
      <Route
        path="/blogs"
        element={
          <>
            <BlogSEO />
            <BlogsProvider>
              <Blogs />
            </BlogsProvider>
          </>
        }
      />
      <Route
        path="/blog/:id"
        element={
          <>
            <BlogSEO />
            <BlogProvider>
              <Blog />
            </BlogProvider>
          </>
        }
      />
      <Route
        path="/*"
        element={
          <>
            <CommonSEO />
            <Error />
          </>
        }
      />
    </ReactRoutes>
  );
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
    });
  }, [pathname]);

  return null;
}
