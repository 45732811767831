import { getClientDomain, reportError } from "../utils";

import type { ConstantsContextType } from "../context";
import type { Dispatch, SetStateAction } from "react";

export async function getConstants(
  callback: Dispatch<SetStateAction<ConstantsContextType | null>>
) {
  try {
    const response = await fetch(`${getClientDomain()}/api/constants`);
    const constants = await response.json();
    callback(constants);
  } catch (e: unknown) {
    reportError(e as Error, {
      endpoint: "/api/constants/",
      method: "GET",
    });
    throw new Error(JSON.stringify(e));
  }
}
