import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Button, LatoText } from "../components";
import { useIsMobile } from "../hooks";

import { VStack } from "./VStack";

import type { CSSProperties } from "react";

const desktopStyle: Record<string, CSSProperties> = {
  wrapper: {
    position: "relative",
    width: "100%",
    filter: "drop-shadow(0px 8px 16px rgba(171, 171, 171, 0.25))",
    borderRadius: "16px",
    gap: "1.5rem",
    backgroundImage: "url('/images/products/size-guide-card-bg.png')",
    backgroundSize: "cover",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  topWrapper: {
    alignItems: "center",
  },
  tagWrapper: {
    minHeight: "1.8125rem",
    width: "100%",
    justifyContent: "right",
  },
  bottomWrapper: {
    width: "100%",
    gap: "0.75rem",
  },
  img: { width: "200px", cursor: "pointer" },
  title: {},
  priceWrapper: {},
  price: {},
  thumbnailWrapper: {
    width: "100%",
    gap: "0.5rem",
    alignItems: "center",
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "1rem",
    filter: "drop-shadow(0px 8px 16px rgba(171, 171, 171, 0.25))",
    borderRadius: "16px",
    gap: "1.5rem",
    backgroundImage: "url('/images/products/promo-card-bg.png')",
    backgroundSize: "cover",
    justifyContent: "end",
    alignItems: "center",
    cursor: "pointer",
  },
  topWrapper: {
    alignItems: "center",
  },
};

export function SizeGuideCard() {
  const { isMobile, isPad } = useIsMobile();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate("/size");
  }, [navigate]);

  return (
    <VStack
      style={isMobile || isPad ? mobileStyle.wrapper : desktopStyle.wrapper}
      onClick={handleClick}
    >
      <VStack style={{ gap: "2rem", width: "100%" }}>
        <LatoText
          text="Finding the Perfect size for your kids"
          color="black"
          weight={isMobile || isPad ? "medium" : "bold"}
          size={isMobile || isPad ? "normal" : "medium"}
          customStyle={{
            width: isMobile || isPad ? "auto" : "67%",
            padding: "1rem",
            background: "rgba(255, 255, 254, 0.8)",
            borderRadius: "16px",
            margin: "auto",
            userSelect: "none",
          }}
        />
        <Button
          name="check size guide"
          onClick={() => ({})}
          isDisabled={false}
          color="white"
          backgroundColor="blue"
          fontSize={isMobile || isPad ? "small" : "normal"}
          customStyle={{
            width: isMobile || isPad ? "100%" : "58%",
            margin: "auto",
          }}
        />
      </VStack>
    </VStack>
  );
}
