import type { Cart } from "../types";

export function createCartInitialState() {
  return {
    id: "",
    checkoutUrl: "",
    lines: [],
    checkoutChargeAmount: "0",
    subtotalAmount: "0",
  } as Cart;
}

export function formatDate(date: Date) {
  const temp = new Date(date);
  const year = temp.getFullYear();
  const month = temp.getMonth();
  const day = temp.getDay();

  function mapMonth() {
    switch (month) {
      case 0:
        return "Jan";
      case 1:
        return "Feb";
      case 2:
        return "Mar";
      case 3:
        return "Apr";
      case 4:
        return "May";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Oct";
      case 10:
        return "Nov";
      case 11:
        return "Dec";
    }
  }

  return `${mapMonth()}. ${day}, ${year}`;
}

export function fulfillmentMap(fulfillmentStatus: string) {
  if (fulfillmentStatus === "FULFILLED") {
    return "Shipped";
  } else if (fulfillmentStatus === "UNFULFILLED") {
    return "Preparing to ship";
  }
  return fulfillmentStatus;
}
