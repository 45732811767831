import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { updateCartDiscountCode } from "../../api";
import { Button, LatoText, PaytoneOneText, TextInput } from "../../components";
import { HEADER_HEIGHT } from "../../constants";
import { useCheckout, useIsMobile } from "../../hooks";
import {
  useCartContext,
  useCartIdContext,
  useLoadingContext,
} from "../../providers";

import type { CSSProperties, MouseEvent, ChangeEvent } from "react";

import { getColorStyle } from "../../utils";
import { HStack } from "../HStack";
import { VStack } from "../VStack";

import { CartItem } from "./CartItem";
import { EmptyCart } from "./EmptyCart";

const desktopStyle: Record<string, CSSProperties> = {
  mainWrapper: {
    position: "fixed",
    left: 0,
    top: HEADER_HEIGHT.desktop,
    width: "100vw",
    height: `calc(100vh - ${HEADER_HEIGHT.desktop})`,
    background: "rgba(10, 10, 10, 0.5)",
  },
  wrapper: {
    position: "absolute",
    width: "36.625rem",
    gap: "1rem",
    padding: "2rem",
    background: getColorStyle("white"),
    justifyContent: "space-between",
    top: "0",
    right: "0",
    zIndex: "2",
    maxHeight: `calc(100vh - ${HEADER_HEIGHT.desktop})`,
  },
};

const padStyle: Record<string, CSSProperties> = {
  mainWrapper: {
    position: "fixed",
    left: 0,
    top: HEADER_HEIGHT.mobile,
    width: "100vw",
    height: `calc(100vh - ${HEADER_HEIGHT.mobile})`,
    background: "rgba(10, 10, 10, 0.5)",
  },
  wrapper: {
    position: "absolute",
    width: "34rem",
    maxWidth: "90vw",
    gap: "2rem",
    padding: "1rem",
    background: getColorStyle("white"),
    justifyContent: "space-between",
    right: "0",
    zIndex: "2",
    height: `calc(100vh - ${HEADER_HEIGHT.mobile})`,
  },
};

type Props = {
  handleClose: () => void;
};

export function CartDropdown({ handleClose }: Props) {
  const { lines, subtotalAmount, checkoutChargeAmount, discountCodes } =
    useCartContext();
  const { setLoading } = useLoadingContext();
  const cartId = useCartIdContext();
  const navigate = useNavigate();
  const checkout = useCheckout();
  const { isPad } = useIsMobile();
  const [code, setCode] = useState("");

  const isEmptyCart = useMemo(() => !lines || lines.length === 0, [lines]);

  const handleCutBubble = useCallback((event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }, []);

  const handleViewCart = useCallback(() => {
    navigate("/cart");
    handleClose();
  }, [handleClose, navigate]);

  const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  }, []);

  const handleApplyCode = useCallback(async () => {
    setLoading(true);
    await updateCartDiscountCode(cartId, [code]);
    window.location.reload();
    setLoading(false);
    setCode("");
  }, [cartId, code, setLoading]);

  return (
    <HStack
      style={isPad ? padStyle.mainWrapper : desktopStyle.mainWrapper}
      onClick={handleClose}
    >
      <VStack
        style={isPad ? padStyle.wrapper : desktopStyle.wrapper}
        onClick={handleCutBubble}
      >
        {isEmptyCart ? (
          <EmptyCart handleClose={handleClose} />
        ) : (
          <>
            {isEmptyCart ? (
              <PaytoneOneText
                text="your shopping cart is empty"
                color="black"
                weight="normal"
                size="large"
              />
            ) : (
              <HStack
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <VStack style={{ cursor: "pointer" }} onClick={handleClose}>
                  <img src="/icons/common/back-arrow.svg" />
                </VStack>
                <PaytoneOneText
                  text="shopping cart"
                  color="black"
                  weight="normal"
                  size={isPad ? "medium" : "large"}
                />
                <VStack style={{ cursor: "pointer" }} onClick={handleViewCart}>
                  <LatoText
                    text="view cart"
                    color="blue"
                    weight="bold"
                    size={isPad ? "normal" : "normalMedium"}
                  />
                </VStack>
              </HStack>
            )}

            <VStack
              style={{
                width: "100%",
                gap: "1rem",
                maxHeight: "50vh",
                flexGrow: "1",
                overflow: "auto",
              }}
            >
              {lines.map((line) => (
                <CartItem
                  key={line.lineId}
                  item={line}
                  handleClose={handleClose}
                />
              ))}
            </VStack>

            <VStack style={{ gap: "0.5rem" }}>
              <HStack
                style={{
                  justifyContent: "space-between",
                  paddingBottom: "0.5rem",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <TextInput
                  type="text"
                  placeholder="input promo code"
                  isRequired={false}
                  value={code}
                  errorMessage=""
                  onChange={handleOnChange}
                />
                <LatoText
                  text="apply"
                  color="blue"
                  weight="bold"
                  size="normalMedium"
                  customStyle={{ cursor: "pointer" }}
                  onClick={handleApplyCode}
                />
              </HStack>
              {discountCodes &&
                discountCodes["applicable"] &&
                discountCodes.code && (
                  <HStack style={{ justifyContent: "space-between" }}>
                    <LatoText
                      text="Applied Code"
                      color="black"
                      weight="normal"
                      size="normal"
                    />
                    <LatoText
                      text={discountCodes.code}
                      color="black"
                      weight="normal"
                      size="normal"
                    />
                  </HStack>
                )}
              {subtotalAmount !== checkoutChargeAmount && (
                <VStack
                  style={{
                    gap: "0.5rem",
                  }}
                >
                  <HStack style={{ justifyContent: "space-between" }}>
                    <LatoText
                      text="Original price"
                      color="black"
                      weight="normal"
                      size="normal"
                    />
                    <LatoText
                      text={"AED " + checkoutChargeAmount}
                      color="black"
                      weight="normal"
                      size="normal"
                    />
                  </HStack>
                  <HStack style={{ justifyContent: "space-between" }}>
                    <LatoText
                      text="Saving"
                      color="red"
                      weight="normal"
                      size="normal"
                    />
                    <LatoText
                      text={
                        "AED " +
                        (Number(checkoutChargeAmount) - Number(subtotalAmount))
                          .toFixed(1)
                          .toString()
                      }
                      color="red"
                      weight="normal"
                      size="normal"
                    />
                  </HStack>
                </VStack>
              )}
              <HStack
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <LatoText
                  text="Cart subtotal:"
                  color="black"
                  weight="bold"
                  size={isPad ? "normalMedium" : "medium"}
                />
                <LatoText
                  text={"AED " + subtotalAmount}
                  color="black"
                  weight="bold"
                  size="medium"
                />
              </HStack>
            </VStack>
            <Button name="check out" onClick={checkout} isDisabled={false} />
          </>
        )}
      </VStack>
    </HStack>
  );
}
