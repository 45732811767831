import { Link } from "react-router-dom";

import { ReactComponent as ArrowRightIcon } from "../assets/icons/layouts/arrow-right.svg";
import { LatoText } from "../components";
import { useIsMobile } from "../hooks";
import { getFontSizeStyle } from "../utils";

import type { CSSProperties } from "react";

import { HStack } from "./HStack";

type Props = {
  links: { text: string; link: string }[] | null;
};

const style: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    justifyContent: "left",
    alignItems: "center",
  },
  icon: {
    fontSize: getFontSizeStyle("small"),
  },
};

const mobileStyle: Record<string, CSSProperties> = {
  wrapper: {
    width: "100%",
    padding: "0.5rem 0",
    overflow: "hidden",
  },
};

export function LinkHeader({ links }: Props) {
  const { isMobile, isPad } = useIsMobile();

  if (links == null || links.length === 0) return null;

  if (links.length === 1) {
    const { text } = links[0];
    return (
      <HStack style={isMobile ? mobileStyle.wrapper : style.wrapper}>
        <LatoText text={text} color="blue" weight="medium" size="small" />
      </HStack>
    );
  }

  if ((isMobile || isPad) && links.length > 2) {
    const { text: firstText, link: firstLink } = links[0];
    const { text: lastText, link: lastLink } = links[links.length - 1];
    return (
      <HStack style={isMobile ? mobileStyle.wrapper : style.wrapper}>
        <Link to={firstLink}>
          <LatoText
            text={firstText}
            color="black"
            weight="medium"
            size="small"
            customStyle={{ marginRight: "0.75rem", cursor: "pointer" }}
          />
        </Link>
        <ArrowRightIcon style={{ ...style.icon, marginRight: "0.75rem" }} />
        <LatoText
          text={"..."}
          color="black"
          weight="medium"
          size="small"
          customStyle={{ marginRight: "0.75rem" }}
        />
        <ArrowRightIcon style={{ ...style.icon, marginRight: "0.75rem" }} />
        <Link to={lastLink}>
          <LatoText
            text={lastText}
            color="blue"
            weight="normal"
            size="small"
            customStyle={{ cursor: "pointer" }}
          />
        </Link>
      </HStack>
    );
  }

  return (
    <HStack style={isMobile ? mobileStyle.wrapper : style.wrapper}>
      {links.map(({ text, link }, index) => {
        const isLast = links.length - 1 === index;
        if (isLast) {
          return (
            <LatoText
              key={text}
              text={text}
              color="blue"
              weight="medium"
              size="small"
            />
          );
        }
        return (
          <Link to={link} key={text}>
            <HStack style={{ marginRight: "0.75rem", alignItems: "center" }}>
              <LatoText
                text={text}
                color="black"
                weight="medium"
                size="small"
                customStyle={{ marginRight: "0.75rem", cursor: "pointer" }}
              />
              <ArrowRightIcon style={style.icon} />
            </HStack>
          </Link>
        );
      })}
    </HStack>
  );
}
