import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import React from "react";

import { VStack } from "../layouts";
import { useBlogContext, useMobileViewContext } from "../providers";

import type { Options } from "@contentful/rich-text-react-renderer";
import type { ReactNode } from "react";
import type { CSSProperties } from "react";

const mobileStyle: Record<string, CSSProperties> = {
  largeFrame: {
    width: "100%",
    padding: "1rem 1rem 2rem",
  },
};
const desktopStyle: Record<string, CSSProperties> = {
  largeFrame: {
    width: "100%",
    maxWidth: "800px",
    padding: "3rem 2rem",
    margin: "auto",
  },
};

type Props = {
  children: ReactNode;
};

type ImgProps = {
  src: string;
};

export function Blog() {
  const { isMobile } = useMobileViewContext();
  // const blog = blogs.filter((blog)=>blog.id===id)
  const blog = useBlogContext();
  const blogContent = blog.fields.content;

  const Paragraph = ({ children }: Props) => (
    <span
      style={{
        fontFamily: "Lato",
        fontSize: "1rem",
        lineHeight: "150%",
        paddingBottom: "0.5rem",
      }}
    >
      {children}
    </span>
  );
  const Heading1 = ({ children }: Props) => (
    <div
      style={{
        fontFamily: "Paytone One",
        fontSize: "2rem",
        lineHeight: "120%",
        paddingBottom: "1rem",
      }}
    >
      {children}
    </div>
  );
  const Heading2 = ({ children }: Props) => (
    <div
      style={{
        fontFamily: "Lato",
        fontSize: "1.75rem",
        fontWeight: "700",
        lineHeight: "120%",
        paddingTop: "1.5rem",
        paddingBottom: "0.5rem",
      }}
    >
      {children}
    </div>
  );
  const Heading3 = ({ children }: Props) => (
    <div
      style={{
        fontFamily: "Lato",
        fontSize: "1rem",
        fontWeight: "700",
        lineHeight: "150%",
        paddingTop: "1rem",
      }}
    >
      {children}
    </div>
  );
  const Bullet = ({ children }: Props) => (
    <span
      style={{
        fontFamily: "Lato",
        fontSize: "1rem",
        lineHeight: "150%",
        paddingBottom: "0.5rem",
      }}
    >
      {children}
    </span>
  );

  const Bold = ({ children }: Props) => (
    <span
      style={{
        fontFamily: "Lato",
        fontSize: "1rem",
        lineHeight: "150%",
        fontWeight: "700",
        paddingTop: "1.5rem",
      }}
    >
      {children}
    </span>
  );

  const Img = ({ src }: ImgProps) => (
    <VStack>
      <img src={src} alt="" width="100%" style={{ aspectRatio: "5/3" }} />
    </VStack>
  );
  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: unknown, children: ReactNode) => (
        <Paragraph>{children}</Paragraph>
      ),
      [BLOCKS.HEADING_1]: (node: unknown, children: ReactNode) => (
        <Heading1>{children}</Heading1>
      ),
      [BLOCKS.HEADING_2]: (node: unknown, children: ReactNode) => (
        <Heading2>{children}</Heading2>
      ),
      [BLOCKS.HEADING_3]: (node: unknown, children: ReactNode) => (
        <Heading3>{children}</Heading3>
      ),
      [BLOCKS.UL_LIST]: (node: unknown, children: ReactNode) => (
        <Bullet>{children}</Bullet>
      ),
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target.fields.image.fields) {
          const src = "https:" + node.data.target.fields.image.fields.file.url;
          return <Img src={src} />;
        }
      },
    },
  };

  return (
    <VStack style={isMobile ? mobileStyle.largeFrame : desktopStyle.largeFrame}>
      <div
        style={{
          textAlign: "left",
          fontFamily: "Lato",
          fontSize: "1rem",
        }}
      />
      {documentToReactComponents(blogContent, options)}
    </VStack>
  );
}
