import { Button, LatoText, PaytoneOneText } from "../../components";
import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { getColorStyle } from "../../utils";

export function SlidePromo2() {
  const { isMobile, isPad } = useIsMobile();

  return (
    <HStack
      style={{
        position: isMobile || isPad ? "relative" : "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
      }}
    >
      <HStack
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "1280px",
          alignItems: "center",
          padding: isMobile || isPad ? "unset" : "0 1rem",
          margin: "auto",
        }}
      >
        <VStack
          style={{
            width: isMobile || isPad ? "100%" : "40%",
            padding: "2rem 1.75rem",
            background: getColorStyle("white"),
            borderRadius: isMobile || isPad ? "unset" : "16px",
            gap: isMobile || isPad ? "1rem" : "1.5rem",
          }}
        >
          <PaytoneOneText
            text="A smile with every step"
            color="black"
            weight="normal"
            size={isMobile || isPad ? "xLarge" : "xxLarge"}
            customStyle={{ textAlign: "left" }}
          />
          <VStack>
            <LatoText
              text="Looking for comfortable, affordable, and fashionable shoes for your children?"
              color="black"
              weight="normal"
              size={isMobile || isPad ? "normal" : "normalMedium"}
              customStyle={{ textAlign: "left" }}
            />
            <LatoText
              text="You are in luck with Lucky Kids."
              color="black"
              weight="normal"
              size={isMobile || isPad ? "normal" : "normalMedium"}
              customStyle={{ textAlign: "left" }}
            />
          </VStack>
          <Button
            name="shop now"
            onClick={() => undefined}
            isDisabled={false}
          />
        </VStack>
      </HStack>
    </HStack>
  );
}
