import { useCallback, useState } from "react";

import { LatoText, PaytoneOneText } from "../components";
import { HStack, VStack } from "../layouts";
import { useMobileViewContext } from "../providers";
import { getColorStyle } from "../utils";

import type { CSSProperties } from "react";

const largeFrameStyle: CSSProperties = {
  width: "100%",
  padding: "3rem",
  maxWidth: "1280px",
  gap: "2rem",
};

const largeFrameMobileStyle: CSSProperties = {
  width: "100%",
  padding: "2rem",
  background: getColorStyle("eggShell"),
};

const locationStyle: CSSProperties = {
  cursor: "pointer",
  width: "31%",
};

const locationCardWrapperStyleM: CSSProperties = {
  width: "60rem",
  height: "100%",
  padding: "1rem 0",
  justifyContent: "space-between",
};

const locationCardWrapperStyleD: CSSProperties = {
  justifyContent: "space-between",
};

const HEADER_TEXT = [
  "At Lucky Kids, we make shoes kids want to wear and parents can afford.",
  "Our shoes make both children and parents smile.",
];

const CONTENT_TEXT1 =
  "Lucky Kids shoes have received numerous awards for their exceptional quality and groundbreaking technology. Our family company has 20+ years of success selling shoes to satisfied customers from more than 20 countries. Parents trust Lucky Kids when they want to start their child off on the right foot.";

const CONTENT_TEXT2 =
  "We combine fit, function, and fashion into our footwear. " +
  "Our stylish selection of shoes are always on-trend, whether you need something for everyday school life or for special occasions. Parents choose Lucky Kids for comfortable, affordable and fashionable shoes for their children of all ages.";

const LOCATION_CARD = [
  {
    image: "/images/about_us/address1.png",
    title: "Al Wahda Mall Lucky Kids",
    address1: "First Floor Shop No.E-106",
    address2: "Al Asalah St",
    address3: "Zone 1E19, Abu Dhabi",
    option: "1",
  },
  {
    image: "/images/about_us/address2.svg",
    title: "Deira City Centre Lucky Kids",
    address1: "Ground Floor Shop No.G-85",
    address2: "8th St",
    address3: "Port Saeed, Dubai",
    option: "2",
  },
  {
    image: "/images/about_us/address3.png",
    title: "Al Ghurair Centre Lucky Kids",
    address1: "Ground Floor Shop No. E-15",
    address2: "Al Rigga Rd",
    address3: "Deira, Dubai",
    option: "3",
  },
];

type Props = {
  image: string;
  title: string;
  address1: string;
  address2: string;
  address3: string;
};

function LocationCard({ image, title, address1, address2, address3 }: Props) {
  return (
    <VStack
      style={{
        padding: "1rem",
        border: `1px solid ${getColorStyle("border")}`,
        boxShadow: "0px 8px 15px rgba(171, 171, 171, 0.18)",
        borderRadius: "16px",
        overflow: "hidden",
      }}
    >
      <img src={image} style={{ width: "100%" }} />
      <VStack style={{ padding: "0.5rem 0 0.5rem 0" }}>
        <PaytoneOneText
          text={title}
          color="black"
          weight="normal"
          size="normal"
        />
      </VStack>
      <VStack style={{ padding: "0.5rem 0 0.5rem 0", gap: "0.5rem" }}>
        <LatoText text={address1} color="black" weight="normal" size="normal" />
        <LatoText text={address2} color="black" weight="normal" size="normal" />
        <LatoText text={address3} color="black" weight="normal" size="normal" />
      </VStack>
    </VStack>
  );
}

export function About() {
  const { isMobile, isPad } = useMobileViewContext();
  const [mapId, setMapId] = useState<string>("1");

  const handleClick = useCallback(
    (id: string) => {
      setMapId(id);
    },
    [setMapId]
  );

  return (
    <VStack style={{ width: "100%", alignItems: "center" }}>
      <img
        src="/images/about_us/about_header.svg"
        alt=""
        style={{ width: "100%" }}
      />
      <VStack
        style={isMobile || isPad ? largeFrameMobileStyle : largeFrameStyle}
      >
        <VStack style={{ alignItems: "center", paddingBottom: "1rem" }}>
          <PaytoneOneText
            text="A smile with every step"
            color="black"
            weight="normal"
            size={isMobile || isPad ? "medium" : "larger"}
            customStyle={{ textAlign: "left" }}
          />
        </VStack>
        <VStack>
          {HEADER_TEXT.map((text) => (
            <VStack style={{ margin: "auto" }} key={text}>
              <LatoText
                text={text}
                color="black"
                weight="normal"
                size="normal"
              />
            </VStack>
          ))}
        </VStack>
        {isMobile || isPad ? (
          <VStack style={{ padding: "1rem 0 1rem 0", gap: "1rem" }}>
            <img src="/images/about_us/content_img.svg" alt="" />
            <VStack
              style={{
                padding: isMobile || isPad ? "1rem" : "2rem",
                gap: "1rem",
              }}
            >
              <LatoText
                text={CONTENT_TEXT1}
                color="black"
                weight="normal"
                size="normal"
                customStyle={{ textAlign: "left" }}
              />
              <LatoText
                text={CONTENT_TEXT2}
                color="black"
                weight="normal"
                size="normal"
                customStyle={{ textAlign: "left" }}
              />
            </VStack>
          </VStack>
        ) : (
          <HStack style={{ padding: "0 0 2rem 0", gap: "3rem" }}>
            <img
              style={{ width: "50%" }}
              src="/images/about_us/content_img.svg"
              alt=""
            />
            <VStack style={{ padding: "0 0 0 1rem", gap: "1rem" }}>
              <LatoText
                text={CONTENT_TEXT1}
                color="black"
                weight="normal"
                size="normal"
                customStyle={{ textAlign: "left" }}
              />
              <LatoText
                text={CONTENT_TEXT2}
                color="black"
                weight="normal"
                size="normal"
                customStyle={{ textAlign: "left" }}
              />
            </VStack>
          </HStack>
        )}
        <VStack style={isMobile || isPad ? { gap: "1rem" } : { gap: "3rem" }}>
          <VStack style={{ paddingTop: "2rem", alignItems: "center" }}>
            <PaytoneOneText
              text="VISIT OUR STORE"
              color="black"
              weight="bold"
              size={isMobile || isPad ? "medium" : "large"}
            />
          </VStack>
          {mapId === "1" && (
            <VStack style={{ borderRadius: "1rem", overflow: "hidden" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3631.5486914380654!2d54.373045615341695!3d24.46643858424126!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e6624153b2c71%3A0x20bc208fa5211fc3!2s106%20Al%20Asalah%20St%20-%20Al%20Nahyan%20-%20Zone%201%20-%20Abu%20Dhabi%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sus!4v1678128859033!5m2!1sen!2sus"
                width="100%"
                height="450"
                style={{ border: "none" }}
                referrerPolicy="no-referrer-when-downgrade"
              />
            </VStack>
          )}
          {mapId === "2" && (
            <VStack style={{ borderRadius: "1rem", overflow: "hidden" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.4935327793382!2d55.32939241500413!3d25.253978135591762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5cdf4ece8e15%3A0x43c1491b5253f158!2zODUgOHRoIFN0IC0gUG9ydCBTYWVlZCAtIER1YmFpIC0g6Zi_5ouJ5Lyv6IGU5ZCI6YWL6ZW_5Zu9!5e0!3m2!1szh-CN!2sus!4v1677881427775!5m2!1sen!2sus"
                width="100%"
                height="450"
                style={{ border: "none" }}
                referrerPolicy="no-referrer-when-downgrade"
              />
            </VStack>
          )}
          {mapId === "3" && (
            <VStack style={{ borderRadius: "1rem", overflow: "hidden" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.1276672265894!2d55.316457515004366!3d25.266290435094106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5ccbeb0c042b%3A0x5c358eab8ce010b7!2zMTUgQWwgUmlnZ2EgUmQgLSBEZWlyYSAtIER1YmFpIC0g6Zi_5ouJ5Lyv6IGU5ZCI6YWL6ZW_5Zu9!5e0!3m2!1szh-CN!2sus!4v1677881465070!5m2!1sen!2sus"
                width="100%"
                height="450"
                style={{ border: "none" }}
                referrerPolicy="no-referrer-when-downgrade"
              />
            </VStack>
          )}

          <div
            style={
              isMobile || isPad
                ? {
                    width: "100%",
                    position: "relative",
                    overflow: "scroll",
                  }
                : { width: "100%" }
            }
          >
            <HStack
              style={
                isMobile || isPad
                  ? locationCardWrapperStyleM
                  : locationCardWrapperStyleD
              }
            >
              {LOCATION_CARD.map(
                ({ image, title, address1, address2, address3, option }) => (
                  <VStack
                    style={locationStyle}
                    onClick={() => handleClick(option)}
                    key={title}
                  >
                    <LocationCard
                      image={image}
                      title={title}
                      address1={address1}
                      address2={address2}
                      address3={address3}
                    />
                  </VStack>
                )
              )}
            </HStack>
          </div>
        </VStack>
      </VStack>
    </VStack>
  );
}
