import { LatoText, PaytoneOneText } from "../components";
import { VStack } from "../layouts";
import { useMobileViewContext } from "../providers";

import type { CSSProperties } from "react";

const largeFrameStyle: CSSProperties = {
  width: "100%",
  maxWidth: "1280px",
  padding: "3rem 1rem",
  margin: "auto",
};

const largeFrameMobileStyle: CSSProperties = {
  width: "100%",
  padding: "2rem",
};

type StringList = [string, string];
type ContentType = StringList[];

const content: ContentType = [
  ["What sizes of shoes do you offer for children?", "title"],
  [
    "We offer shoes that cover the age range from 6 months to 18 years old, basically as shown on our homepage: Babies, Toddlers, Kids, Teens. Currently, the smallest size that is available for shoes is EU 14 and the largest is EU 42.",
    "",
  ],
  [
    "Can I exchange or return shoes if they don't fit or if I don't like them?",
    "title",
  ],
  [
    "Absolutely, we understand the pain when you shop shoes online and find out they don’t fit, especially for child whose feet grow much faster than adult’s.",
    "",
  ],
  [
    "Our exchange and return policy for children's shoes is designed to provide the best possible shopping experience for our customers. We offer exchanges for children's shoes at our stores located in the Deira City Centre, Al Wahda Mall, and Al Ghurair Mall.",
    "",
  ],
  [
    "To make an exchange, please bring the shoes to any of our stores along with the original receipt or proof of purchase. If you do not have a receipt, we may be able to locate your purchase in our system using the credit card used or the email address provided at the time of purchase.",
    "",
  ],
  [
    "We will happily exchange the shoes for a different size or style within 14 days of purchase. After 14 days, we will be unable to process exchanges.",
    "",
  ],
  [
    "We ask that all exchanges and returns be in their original condition, with the tags still attached and the shoes unworn. We reserve the right to refuse an exchange or return if the shoes do not meet these conditions.",
    "",
  ],
  [
    "We hope this policy provides the flexibility and convenience you need to find the perfect pair of children's shoes. For further details please refer to the shipping, return & exchange policy page.",
    "",
  ],
  ["Thank you for shopping with us.", ""],
  ["How can I measure my child's foot to ensure a proper fit?", "title"],
  [
    "1. \tFind a vertical wall and place a piece of paper (can be A4 paper) at the foot of the wall for measurement.",
    "",
  ],
  [
    "2. \tHave the child stand against the wall with the largest foot on the paper.",
    "",
  ],
  [
    "3. \tTrace both feet and measure the length from heel to longest toe and the width at the widest part.",
    "",
  ],
  ["4. \tFind the size which fits you best on the size guide.", ""],
  ["Do you have shoes for children with wide or narrow feet?", "title"],
  [
    "Most of our shoes fit normal sized feet, but we do hear from customer feedbacks that for teens the shoes felt narrow-fit, and we are working with our manufacturer to fix it. So, when ordering our products for teens with wide feet, please do size up in case it doesn’t fit.",
    "",
  ],
  ["Are your shoes made with non-slip soles?", "title"],
  ["Yes.", ""],
  [
    "Can I find shoes for different occasions like formal events, sports, or everyday wear?",
    "title",
  ],
  [
    "Yes, although we’ve just launched, we are trying to fit in as many products and types to satisfy our customer. Check back in future and you shall see even more shoes available.",
    "",
  ],
  ["Do you have shoes with Velcro or laces?", "title"],
  [
    "We have shoes with both available for your child, and even shoes with both velcro and laces.",
    "",
  ],
  ["Can I find shoes with fun designs or characters?", "title"],
  [
    "Absolutely, we understand how important interesting designs are for kids and we are working hard with the manufacturers to ensure that each shoe is unique and attractive. We are also open to suggestions from customers if they want different designs through email: business@luckykidsking.com",
    "",
  ],
  ["Are your shoes made with materials that are easy to clean?", "title"],
  [
    "Yes, most can be cleaned with a simple wet wipe, and if there’s any stain that’s staying, you could try brushing or washing it.",
    "",
  ],
  ["How long will it take to receive my order?", "title"],
  [
    "Based on the contract that we signed with our logistics partner, Jeebly, we currently offer next day delivery on orders made from Monday to Saturday in UAE. This is to ensure our customers have the best online shopping experience and the shipping fee can be waived once the total order value gets to 300 or above.",
    "",
  ],
];

export function FAQ() {
  const { isMobile } = useMobileViewContext();

  return (
    <VStack style={isMobile ? largeFrameMobileStyle : largeFrameStyle}>
      <VStack style={{ paddingBottom: "3rem" }}>
        <PaytoneOneText
          text="FAQ "
          color="black"
          weight="normal"
          size={isMobile ? "medium" : "large"}
          customStyle={{ textAlign: "left" }}
        />
      </VStack>
      {content.map((c, index) => (
        <VStack
          style={
            c[1].length > 0
              ? { paddingBottom: "1rem", paddingTop: "1rem" }
              : { paddingBottom: "1rem" }
          }
          key={index}
        >
          <LatoText
            text={c[0]}
            color="black"
            weight={c[1].length > 0 ? "medium" : "normal"}
            size="normal"
            customStyle={{ textAlign: "left" }}
          />
        </VStack>
      ))}
    </VStack>
  );
}
