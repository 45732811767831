import { useCallback, useMemo } from "react";

import { LatoText } from "../components";
import { getColorStyle } from "../utils";

import type { FontSizeType, FontWeightType } from "../types";
import type { CSSProperties, Dispatch, SetStateAction } from "react";

import { HStack } from "./index";

type Props = {
  quantity: number;
  setQuantity:
    | Dispatch<SetStateAction<number>>
    | ((value: number) => Promise<void>);
  max: string | null;
  style: "desktop" | "mobile" | "dropdown";
};

const wrapperStyleMap: Record<string, CSSProperties> = {
  desktop: {
    padding: "0.5rem 1rem",
    border: `1px solid ${getColorStyle("border")}`,
    gap: "2rem",
  },
  mobile: {
    padding: "0.25rem 0.5rem",
    border: `1px solid ${getColorStyle("border")}`,
    gap: "2rem",
  },
  dropdown: {
    padding: "0.25rem 0.5rem",
    border: `1px solid ${getColorStyle("border")}`,
    gap: "2rem",
  },
};

const fontWeightStyleMap: Record<string, FontWeightType> = {
  desktop: "bold",
  mobile: "bold",
  dropdown: "medium",
};

const fontSizeStyleMap: Record<string, FontSizeType> = {
  desktop: "normalMedium",
  mobile: "normal",
  dropdown: "normal",
};

export function Quantity({ quantity, setQuantity, max, style }: Props) {
  const isMinusDisabled = useMemo(
    () => max === null || quantity <= 1,
    [max, quantity]
  );
  const isPlusDisabled = useMemo(
    () => max === null || quantity === +max,
    [max, quantity]
  );

  const handleMinusClick = useCallback(() => {
    if (isMinusDisabled) return;
    setQuantity(quantity - 1);
  }, [isMinusDisabled, quantity, setQuantity]);

  const handlePlusClick = useCallback(() => {
    if (isPlusDisabled) return;
    setQuantity(quantity + 1);
  }, [isPlusDisabled, quantity, setQuantity]);

  return (
    <HStack style={wrapperStyleMap[style]}>
      <LatoText
        text="—"
        color={isMinusDisabled ? "disabled" : "black"}
        weight={fontWeightStyleMap[style]}
        size={fontSizeStyleMap[style]}
        onClick={handleMinusClick}
        customStyle={{
          cursor: isMinusDisabled ? "" : "pointer",
          userSelect: "none",
        }}
        title={isMinusDisabled ? "at least 1" : ""}
      />
      <LatoText
        text={quantity + ""}
        color={max === null ? "disabled" : "black"}
        weight={fontWeightStyleMap[style]}
        size={fontSizeStyleMap[style]}
      />
      <LatoText
        text="+"
        color={isPlusDisabled ? "disabled" : "black"}
        weight={fontWeightStyleMap[style]}
        size={fontSizeStyleMap[style]}
        onClick={handlePlusClick}
        customStyle={{
          cursor: isPlusDisabled ? "" : "pointer",
          userSelect: "none",
        }}
        title={isPlusDisabled ? "no more inventory" : ""}
      />
    </HStack>
  );
}
