import Cookies from "js-cookie";
import { useCallback, useMemo, useState } from "react";
import * as React from "react";
import TagManager from "react-gtm-module";
import { useNavigate, useParams } from "react-router-dom";

import { signUp } from "../../api/session";
import ErrorSign from "../../assets/icons/common/warning-sign.svg";
import { TextInput, Button, PaytoneOneText, LatoText } from "../../components";

import type { ChangeEvent, CSSProperties } from "react";

import { useIsMobile } from "../../hooks";
import { HStack, VStack } from "../../layouts";
import { useLoadingContext, useUserContext } from "../../providers";
import {
  validateEmail,
  validatePassword,
  validateFirstName,
  validateLastName,
} from "../../utils";

type FormState = {
  firstName: {
    value: string;
    error: string | undefined;
  };
  lastName: {
    value: string;
    error: string | undefined;
  };
  email: {
    value: string;
    error: string | undefined;
  };
  password: {
    value: string;
    error: string | undefined;
  };
  passwordRepeat: {
    value: string;
    error: string | undefined;
  };
};

const validator: Record<
  keyof FormState,
  (value: string) => string | undefined
> = {
  firstName: validateFirstName,
  lastName: validateLastName,
  email: validateEmail,
  password: validatePassword,
  passwordRepeat: validatePassword,
};

const largeFrameStyle: CSSProperties = {
  width: "100%",
  padding: "5rem 0rem 0rem",
};

const largeFrameMobileStyle: CSSProperties = {
  width: "100%",
};

const frameStyle: CSSProperties = {
  margin: "auto",
  padding: "2rem",
  width: "35rem",
  gap: "4rem",
};

const frameMobileStyle: CSSProperties = {
  margin: "auto",
  padding: "3rem 1rem 0 1rem",
  width: "100%",
  gap: "2rem",
};

const mainFrameStyle: CSSProperties = {
  gap: "1rem",
  width: "100%",
};

export function Signup() {
  const navigate = useNavigate();
  const { fail } = useParams();
  const { setUser } = useUserContext();
  const { isMobile, isPad } = useIsMobile();
  const { setLoading } = useLoadingContext();

  const [form, setForm] = useState<FormState>({
    firstName: {
      value: "",
      error: validator.firstName(""),
    },
    lastName: {
      value: "",
      error: validator.lastName(""),
    },
    email: {
      value: "",
      error: validator.email(""),
    },
    password: {
      value: "",
      error: validator.password(""),
    },
    passwordRepeat: {
      value: "",
      error: validator.password(""),
    },
  });

  const isDisabled = useMemo(
    () =>
      Boolean(
        form.email.error ||
          form.password.error ||
          form.firstName.error ||
          form.lastName.error ||
          form.passwordRepeat.error
      ),
    [
      form.email.error,
      form.password.error,
      form.firstName.error,
      form.lastName.error,
      form.passwordRepeat.error,
    ]
  );

  const handleInputOnChange = useCallback(
    (key: keyof FormState) =>
      function (e: ChangeEvent<HTMLInputElement>) {
        let errorMessage = validator[key](e.target.value);
        if (
          key === "passwordRepeat" &&
          e.target.value !== form.password.value
        ) {
          errorMessage = "Password does not match";
        }
        setForm({
          ...form,
          [key]: {
            value: e.target.value,
            error: errorMessage,
          },
        });
      },
    [form]
  );

  const handleButtonClick = useCallback(async () => {
    setLoading(true);
    const response = await signUp({
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      email: form.email.value,
      password: form.password.value,
      acceptsMarketing: true,
    });
    if (typeof response === "string") {
      // FIXME: handle error
      navigate("/signup/fail");
    } else {
      // Google Analytics
      TagManager.dataLayer({
        dataLayer: {
          event: "sign_up",
          firstName: form.firstName.value,
          lastName: form.lastName.value,
          email: form.email.value,
        },
      });

      // FB tracking
      fbq("track", "sign_up", {
        firstName: form.firstName.value,
        lastName: form.lastName.value,
        email: form.email.value,
      });

      const { token, ...user } = response;
      setUser(user);
      Cookies.set("session", token, { expires: 30 });
      navigate("/account");
    }
    setLoading(false);
  }, [
    form.email.value,
    form.firstName.value,
    form.lastName.value,
    form.password.value,
    navigate,
    setLoading,
    setUser,
  ]);

  return (
    <VStack style={isMobile || isPad ? largeFrameMobileStyle : largeFrameStyle}>
      <VStack style={isMobile || isPad ? frameMobileStyle : frameStyle}>
        <PaytoneOneText
          text="Create account"
          color="black"
          weight="normal"
          size={isMobile || isPad ? "medium" : "large"}
        />
        <VStack style={mainFrameStyle}>
          {fail === "fail" && (
            <HStack
              style={{
                gap: "0.5rem",
                justifyContent: "center",
              }}
            >
              <img src={ErrorSign} alt="" />
              <LatoText
                text="Signup error"
                color="black"
                weight="normal"
                size="normal"
                customStyle={{ textAlign: "left" }}
              />
            </HStack>
          )}
          <TextInput
            type="text"
            label="FIRST NAME"
            placeholder="Input text"
            isRequired={true}
            value={form.firstName.value}
            errorMessage={form.firstName.error}
            onChange={handleInputOnChange("firstName")}
          />
          <TextInput
            type="text"
            label="LAST NAME"
            placeholder="Input text"
            isRequired={true}
            value={form.lastName.value}
            errorMessage={form.lastName.error}
            onChange={handleInputOnChange("lastName")}
          />
          <TextInput
            type="text"
            label="EMAIL"
            placeholder="Input text"
            isRequired={true}
            value={form.email.value}
            errorMessage={form.email.error}
            onChange={handleInputOnChange("email")}
          />
          <TextInput
            type="password"
            label="PASSWORD"
            placeholder="At least 1 letter and number, 8-16 length, no symbol"
            isRequired={true}
            value={form.password.value}
            errorMessage={form.password.error}
            onChange={handleInputOnChange("password")}
          />
          <TextInput
            type="password"
            label="REPEAT PASSWORD"
            placeholder="repeat your password"
            isRequired={true}
            value={form.passwordRepeat.value}
            errorMessage={form.passwordRepeat.error}
            onChange={handleInputOnChange("passwordRepeat")}
          />
        </VStack>
        <VStack style={{ gap: "1rem" }}>
          <Button
            name="create account"
            onClick={handleButtonClick}
            isDisabled={isDisabled}
          />
        </VStack>
      </VStack>
    </VStack>
  );
}
